// @ts-strict-ignore
import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { BasicSearchValue } from 'analytics/events/search-events.shared';
import {
  SearchHeaderUsageMetric,
  SearchHeaderUsageValue
} from 'analytics/events/search-header-usage';
import { trackSearchUsageAnalyticsEvent } from 'analytics/events/search-usage';

import { History } from 'history';
import { observer } from 'mobx-react';

import { RouteComponentProps } from 'react-router-dom';

import { createTicketsTestSelectors } from 'tests/models/pages/create-ticket/create-tickets-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher, { SearchedPatient } from 'fetchers/PatientsFetcher';

import { useQuery } from 'hooks/useQuery';

import { HEADER_HEIGHT } from 'containers/Layout/Layout.constants';

import DoctorDetails from 'views/Dashboard/DoctorDetails';
import ConnectTicketsToCallEditModal from 'views/Modals/ConnectTicketsToCallEditModal';
import { PatientDetailsCard } from 'views/Patient/PatientDetails/PatientDetailsCard';
import { usePatientTicketOverview } from 'views/Patient/PatientMain/PatientTicketOverview';

import { PatientContextProvider } from 'components/Patient/PatientContextProvider';
import KeyboardShortcutsInfoPane from 'components/Ticket/TicketForms/KeyboardShortcutsInfoPane';
import { OperatorCreateTicketForm } from 'components/Ticket/TicketForms/OperatorCreateTicketsForm';
import TicketOverviewProvider, {
  ActionCallbacks
} from 'components/Ticket/TicketsContainers/TicketOverviewProvider';
import TicketsOverview from 'components/Ticket/TicketsContainers/TicketsOverview';
import { AdvancedSearchBar } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar';
import {
  AdvancedSearchBarActions,
  AdvancedSearchFormFields
} from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.types';
import { getSearchBy } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.utils';
import { SelectedSearchedPatientBox } from 'components/UIkit/atoms/AdvancedSearchBar/SelectedSearchedPatientBox';

import './CreateTickets.scss';

interface ICreateTicketsPageProps {
  history: History;
}

const CreateTickets = observer(({ history }: ICreateTicketsPageProps) => {
  const { patientPageStore, userStore, ticketsStore, callsStore } = useStores();
  const [selectedPatientTime, setSelectedPatientTime] = useState<Date>();
  const [partialPatient, setPartialPatient] = useState(null);
  const queryParams = useQuery<{ patientId: boolean }>();
  const { patient: patientFromStore, overduePatients, ticketsCluster } = usePatientTicketOverview();
  const patient = patientFromStore || partialPatient;

  const loadCreateTicketPatientData = useCallback(
    (patientId: number) => {
      return Promise.all([
        ticketsStore.searchTicketsForCreate(patientId),
        patientPageStore.loadSinglePatientFromServer(patientId)
      ]);
    },
    [patientPageStore, ticketsStore]
  );

  const onSelectedPatient = useCallback(
    async (selectedPatient: SearchedPatient, options?: { isKeyboardSource?: boolean }) => {
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Select,
        value: 'search result',
        source: options?.isKeyboardSource ? 'keyboard' : null
      });
      setSelectedPatientTime(new Date());
      // The retrieved patient might not have an id because he might not
      // be a patient on our system yet (EMR)
      if (selectedPatient.patientId) {
        // getting additional data from server
        // in order to display patients' tickets
        await loadCreateTicketPatientData(selectedPatient.patientId);
      } else if (selectedPatient.emrPatientId) {
        const emrPatient = await PatientsFetcher.fetchEmrPatient(selectedPatient.emrPatientId);
        setPartialPatient(emrPatient);
      } else {
        setPartialPatient(selectedPatient);
      }
    },
    [loadCreateTicketPatientData]
  );

  useEffect(
    function loadPatientFromUrl() {
      const loadPatientIfParamsExist = async () => {
        const queryParamsPatientId = queryParams.get('patientId');
        if (queryParamsPatientId) {
          history.replace('/create-tickets');
          await loadCreateTicketPatientData(Number(queryParamsPatientId));
        }
      };

      loadPatientIfParamsExist();
    },
    [history, loadCreateTicketPatientData, onSelectedPatient, patientPageStore, queryParams]
  );

  const resetSelectedPatient = () => {
    patientPageStore.clearData();
    setPartialPatient(null);
  };

  const handleCreateTicketsSuccess = () => resetSelectedPatient();

  const updateActionCallback = useCallback(() => {
    if (patient) {
      patientPageStore.loadSinglePatientFromServer(patient.id);
    }
  }, [patientPageStore, patient]);

  const onTicketActionCallback = useCallback(() => {
    if (patient) {
      ticketsStore.searchTicketsForCreate(patient.id);
    }
  }, [patient, ticketsStore]);

  const createTicketsSync = useCallback(() => {
    onTicketActionCallback();
    updateActionCallback();
  }, [onTicketActionCallback, updateActionCallback]);

  const handleResolve = useCallback(
    (_ticketIds: number[], navigationPath?: string) => {
      if (navigationPath) {
        history.replace(navigationPath);
        return;
      }
      createTicketsSync();
    },
    [createTicketsSync, history]
  );

  const actionCallbacks: ActionCallbacks = {
    onAssign: onTicketActionCallback,
    onEditTicket: createTicketsSync,
    onResolve: handleResolve,
    onDeleteTicket: onTicketActionCallback,
    onRequestReport: updateActionCallback,
    onSnooze: updateActionCallback
  };

  const advancedSearchBarActions: AdvancedSearchBarActions = {
    onPatientClick: onSelectedPatient,
    onClearSearch: () => trackSearchUsageAnalyticsEvent({ action: AnalyticEventAction.Clear }),
    onSearch: (currentSearchTerm: string, options?: { isKeyboardSource?: boolean }) => {
      const searchBy = getSearchBy(currentSearchTerm);

      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Search,
        value: `searched by ${searchBy}` as BasicSearchValue,
        source: options?.isKeyboardSource ? 'keyboard' : null,
        type: 'regular search'
      });
    },
    onAdvancedSearch: (metrics: Partial<AdvancedSearchFormFields>) => {
      const formattedFormValuesForAnalytics = Object.keys(metrics).map((metric) =>
        metric.toUpperCase()
      );
      const searchHeaderUsageAnalyticsEventValue: SearchHeaderUsageValue = `searched by ${
        formattedFormValuesForAnalytics.join(' and ') as SearchHeaderUsageMetric
      }`;

      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Search,
        value: searchHeaderUsageAnalyticsEventValue,
        source: null,
        type: 'advanced search'
      });
    },
    onCancelAdvancedSearch: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.Cancel
      }),
    onClearAllAdvancedSearch: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.ClearAll
      }),
    onToggleAdvancedSearchMenu: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.AdvancedSearch
      }),
    onOpenAdvancedSearch: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.RefineWithAdvancedSearch
      }),
    onPasteFromClipboard: () =>
      trackSearchUsageAnalyticsEvent({
        action: AnalyticEventAction.PasteFromClipboard,
        source: null
      })
  };

  return (
    <div
      className="animated fadeIn limited-width dashboard-view create-tickets-page"
      data-test-hook={createTicketsTestSelectors.page}
    >
      <Box mb={40}>
        <DoctorDetails doctor={userStore.currentDoctor} />
      </Box>

      <Box mb={20}>
        {Boolean(patient) ? (
          <SelectedSearchedPatientBox
            clearSearch={resetSelectedPatient}
            variant="light"
            patient={patient}
          />
        ) : (
          <AdvancedSearchBar
            hasSameWidthModifier
            actions={advancedSearchBarActions}
            searchBarBoxProps={{
              variant: 'light',
              hasPasteMrnOption: true,
              testHooks: {
                input: createTicketsTestSelectors.searchBar.input,
                searchButton: createTicketsTestSelectors.searchBar.searchButton
              }
            }}
            searchBarMenusProps={{
              advancedMenu: { numberOfRows: 1 },
              offset: [0, 4],
              variant: 'rounded',
              patientsMenu: { maxHeight: `calc(100vh - 184px - ${HEADER_HEIGHT * 2}px)` }
            }}
          />
        )}
      </Box>

      {patient && <PatientDetailsCard patient={patient} isEditable={false} />}
      {patient && (
        <TicketOverviewProvider
          actionCallbacks={actionCallbacks}
          getPatientSymptomOperatorTickets={ticketsStore.getPatientSymptomOperatorTickets}
        >
          {callsStore.currentEditedCall && (
            <ConnectTicketsToCallEditModal onSave={createTicketsSync} />
          )}

          <div className="tickets-overview">
            <TicketsOverview
              ticketsCluster={ticketsCluster}
              overduePatients={overduePatients}
              emptyState={null}
              showTasks={false}
              showCallbackRequests={false}
            />
          </div>
        </TicketOverviewProvider>
      )}
      {patient && (
        <div className="form-wrapper">
          <KeyboardShortcutsInfoPane />
          <OperatorCreateTicketForm
            history={history}
            patient={patient}
            onSubmitSuccess={handleCreateTicketsSuccess}
            createFlowStartTime={selectedPatientTime}
          />
        </div>
      )}
    </div>
  );
});

export const CreateTicketsPage = (props: RouteComponentProps) => (
  <PatientContextProvider>
    <CreateTickets {...props} />
  </PatientContextProvider>
);

export default CreateTicketsPage;
