import { FC } from 'react';

import { Box } from '@mui/material';

import { AnalyticEventAction } from 'analytics';

import { trackHeaderMenuAnalyticsEvent } from 'analytics/events/header-menu';
import { BasicSearchValue } from 'analytics/events/search-events.shared';
import {
  SearchHeaderUsageMetric,
  SearchHeaderUsageValue,
  trackSearchHeaderUsageAnalyticsEvent
} from 'analytics/events/search-header-usage';

import classNames from 'classnames';

import { observer } from 'mobx-react';

import { Link, useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { SearchedPatient } from 'fetchers/PatientsFetcher';

import { sortParamsToUrlString } from 'utils/urlUtils';

import { defaultCmSorting } from 'constants/careManagement.const';

import { FEATURES } from 'constants/features';

import { ReactComponent as CanopyLogo } from 'img/canopy-logo-blue.svg';

import { PATIENT_TABS } from 'views/Patient/PatientMain/PatientMainView.constants';

import { ContactSupport } from 'components/Header/ContactSupport';

import { AccountDropdown, EnrollmentDropdown } from 'components/Header/HeaderDropdown';
import { NotificationPanel } from 'components/NotificationCenter/NotificationPanel';
import { AdvancedSearchBar } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar';
import {
  AdvancedSearchBarActions,
  AdvancedSearchFormFields
} from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.types';
import { getSearchBy } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.utils';

import { HEADER_TAB, useActiveTab } from './Header.shared';

import './Header.scss';

const defaultCmUrlParams = sortParamsToUrlString(defaultCmSorting.sortBy, defaultCmSorting.sortAsc);

const Header: FC<{ hideActions?: boolean }> = ({ hideActions = false }) => {
  const { userStore, settingsStore, patientPageStore } = useStores();
  const history = useHistory();
  const activeTab = useActiveTab();
  const showCreateTicketTab = settingsStore.hasFeature(FEATURES.CREATE_TICKET);

  const handleSearchedPatientClicked = async (
    patient: SearchedPatient,
    options?: { isRecentPatient?: boolean; isKeyboardSource?: boolean; isNewPatient?: boolean }
  ) => {
    trackSearchHeaderUsageAnalyticsEvent({
      action: AnalyticEventAction.Select,
      value: options?.isRecentPatient ? 'recently viewed' : 'search result',
      source: options?.isKeyboardSource ? 'keyboard' : null
    });

    const patientId =
      patient.patientId ||
      (await patientPageStore.createPatientFromEMR(patient.emrPatientId as string));
    patient.patientId = patientId;

    const navTo = `/patient/${patientId}?tab=${PATIENT_TABS.OPEN_TICKETS}`;

    if (!history.location.pathname.includes(navTo)) {
      history.push(navTo);
    }
  };

  const advancedSearchBarActions: AdvancedSearchBarActions = {
    onPatientClick: handleSearchedPatientClicked,
    onClearSearch: () =>
      trackSearchHeaderUsageAnalyticsEvent({ action: AnalyticEventAction.Clear }),
    onSearch: (currentSearchTerm: string, options?: { isKeyboardSource?: boolean }) => {
      const searchBy = getSearchBy(currentSearchTerm);

      trackSearchHeaderUsageAnalyticsEvent({
        action: AnalyticEventAction.Search,
        value: `searched by ${searchBy}` as BasicSearchValue,
        source: options?.isKeyboardSource ? 'keyboard' : null,
        type: 'regular search'
      });
    },
    onAdvancedSearch: (metrics: Partial<AdvancedSearchFormFields>) => {
      const formattedFormValuesForAnalytics = Object.keys(metrics).map((metric) =>
        metric.toUpperCase()
      );
      const searchHeaderUsageAnalyticsEventValue: SearchHeaderUsageValue = `searched by ${
        formattedFormValuesForAnalytics.join(' and ') as SearchHeaderUsageMetric
      }`;

      trackSearchHeaderUsageAnalyticsEvent({
        action: AnalyticEventAction.Search,
        value: searchHeaderUsageAnalyticsEventValue,
        source: null,
        type: 'advanced search'
      });
    },
    onCancelAdvancedSearch: () =>
      trackSearchHeaderUsageAnalyticsEvent({
        action: AnalyticEventAction.Cancel
      }),
    onClearAllAdvancedSearch: () =>
      trackSearchHeaderUsageAnalyticsEvent({
        action: AnalyticEventAction.ClearAll
      }),
    onToggleAdvancedSearchMenu: () =>
      trackSearchHeaderUsageAnalyticsEvent({
        action: AnalyticEventAction.AdvancedSearch
      }),
    onOpenAdvancedSearch: () =>
      trackSearchHeaderUsageAnalyticsEvent({
        action: AnalyticEventAction.RefineWithAdvancedSearch
      })
  };

  return (
    <header className="header">
      {!hideActions && (
        <>
          <div className="d-flex flex-row align-items-center w-100">
            <Link to="/work-queue">
              <CanopyLogo width={100} />
            </Link>

            <div className="header-search-wrapper">
              <AdvancedSearchBar
                actions={advancedSearchBarActions}
                hasRecentPatientsMenu
                searchBarBoxProps={{ advancedBox: { maxWidth: '320px' } }}
              />
            </div>

            {settingsStore.hasFeature(FEATURES.WORK_QUEUE_PAGE) && (
              <div
                className={classNames('menu-item m-0', {
                  active: activeTab === HEADER_TAB.REPORTS_TAB
                })}
              >
                <Link
                  to="/work-queue"
                  onClick={() =>
                    trackHeaderMenuAnalyticsEvent({
                      action: AnalyticEventAction.Select,
                      value: 'work queue'
                    })
                  }
                >
                  Work Queue
                </Link>
              </div>
            )}

            {showCreateTicketTab && (
              <div
                className={classNames('menu-item', {
                  active: activeTab === HEADER_TAB.CREATE_TICKETS_TAB
                })}
              >
                <Link
                  to="/create-tickets"
                  onClick={() =>
                    trackHeaderMenuAnalyticsEvent({
                      action: AnalyticEventAction.Select,
                      value: 'create ticket'
                    })
                  }
                >
                  Create Ticket
                </Link>
              </div>
            )}

            {settingsStore.hasFeature(FEATURES.CARE_MANAGEMENT) && (
              <div
                className={classNames('menu-item', {
                  active: activeTab === HEADER_TAB.CARE_MANAGEMENT_TAB
                })}
              >
                <Link
                  to={`/care-management/all?${defaultCmUrlParams}`}
                  onClick={() =>
                    trackHeaderMenuAnalyticsEvent({
                      action: AnalyticEventAction.Select,
                      value: 'care management'
                    })
                  }
                >
                  Care Management
                </Link>
              </div>
            )}

            {settingsStore.hasFeature(FEATURES.PATIENT_ENROLLMENT_TAB) && (
              <div className="menu-item">
                <EnrollmentDropdown />
              </div>
            )}

            {userStore.currentDoctor && (
              <Box display="flex" ml="auto" alignItems="center">
                {settingsStore.hasFeature(FEATURES.NOTIFICATIONS) && <NotificationPanel />}
                <ContactSupport />
                <div className="menu-item account-item">
                  <AccountDropdown />
                </div>
              </Box>
            )}
          </div>
        </>
      )}
    </header>
  );
};

export default observer(Header);
