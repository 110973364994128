import { CSSObjectWithLabel, StylesConfig } from 'react-select';

import { theme } from 'components/UIkit/theme';
import { extraThemeOptions } from 'components/UIkit/theme/extraThemeOptions';

const { palette, borderRadius } = theme;
const selectLineHeight = 1.88;

export const reactSelectStyle: StylesConfig<any, any> = {
  menuPortal: (provided, props) =>
    ({
      ...provided,
      zIndex: 1300
    } as any),
  indicatorsContainer: (provided, props) =>
    ({
      ...provided,
      padding: theme.spacing(8)
    } as CSSObjectWithLabel),
  control: (provided, state) => {
    const { isNarrow, label, menuIsOpen, isFocused, variant } = state.selectProps;
    const baseStyles = {
      ...provided,
      transition: 'all 0.3s ease-in',
      borderRadius: `${label ? 0 : borderRadius.large} ${borderRadius.large} ${
        borderRadius.large
      } ${borderRadius.large}`,
      boxShadow: 'none'
    };

    const primaryVariantBorderColor =
      menuIsOpen || isFocused ? theme.palette.primary.main : theme.palette.natural.border;

    const primaryVariantStyles = {
      borderColor: primaryVariantBorderColor,
      backgroundColor: theme.palette.natural.white,
      minHeight: isNarrow ? 0 : 45,
      ':hover': { borderColor: primaryVariantBorderColor }
    };

    const secondaryVariantStyles = {
      borderColor:
        menuIsOpen || isFocused ? theme.palette.secondary.main : theme.palette.natural.border,
      backgroundColor:
        menuIsOpen || isFocused ? theme.palette.natural.white : theme.palette.natural.contrast,
      minHeight: isNarrow ? 0 : 30,
      ':hover': {
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.natural.white
      }
    };

    return {
      ...baseStyles,
      ...(variant === 'secondary' ? secondaryVariantStyles : primaryVariantStyles)
    } as CSSObjectWithLabel;
  },
  input: (provided, state) => {
    const baseStyles = { ...provided };

    const secondaryVariantStyles = {
      padding: 0,
      margin: 0,
      fontWeight: extraThemeOptions.fontWeights.normal,
      fontSize: extraThemeOptions.fontSizes.normal,
      lineHeight: '16px'
    };

    return {
      ...baseStyles,
      ...(state.selectProps.variant === 'secondary' && secondaryVariantStyles)
    } as CSSObjectWithLabel;
  },
  dropdownIndicator: (provided, props) => {
    const baseStyles = {
      padding: theme.spacing(4),
      display: 'flex'
    };

    const secondaryVariantStyles = {
      color: theme.palette.natural.black,
      opacity: 0.5
    };

    return {
      ...baseStyles,
      ...(props.selectProps.variant === 'secondary' && secondaryVariantStyles)
    } as CSSObjectWithLabel;
  },
  clearIndicator: (provided, props) => {
    const baseStyles = {
      cursor: 'pointer',
      padding: theme.spacing(4),
      display: 'flex'
    };

    const secondaryVariantStyles = {
      color: theme.palette.natural.black,
      opacity: 0.5
    };

    return {
      ...baseStyles,
      ...(props.selectProps.variant === 'secondary' && secondaryVariantStyles)
    } as CSSObjectWithLabel;
  },
  menu: (provided) =>
    ({
      ...provided,
      zIndex: 2
    } as CSSObjectWithLabel),
  placeholder: (provided, state) => {
    const baseStyles = { ...provided };

    const secondaryVariantStyles = {
      fontSize: extraThemeOptions.fontSizes.normal
    };

    return {
      ...baseStyles,
      ...(state.selectProps.variant === 'secondary' && secondaryVariantStyles),
      color: palette.text.disabled
    } as CSSObjectWithLabel;
  },
  valueContainer: (provided) =>
    ({
      ...provided,
      textAlign: 'left',
      lineHeight: selectLineHeight
    } as CSSObjectWithLabel),
  // select with groups
  groupHeading: (provided) =>
    ({
      ...provided,
      fontSize: '1rem',
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      margin: 0
    } as CSSObjectWithLabel),
  // multi value
  multiValue: (provided, props) => {
    const baseStyles = {
      ...provided,
      fontSize: '14px',
      borderRadius: borderRadius.large,
      margin: theme.spacing(2),
      display: 'flex',
      paddingRight: 0
    };

    const primaryVariantStyles = {
      backgroundColor: theme.palette.primary.contrastText,
      height: '28px'
    };

    const secondaryVariantStyles = {
      backgroundColor: theme.palette.secondary.light,
      height: '22px'
    };

    return {
      ...baseStyles,
      ...(props.selectProps.variant === 'secondary' ? secondaryVariantStyles : primaryVariantStyles)
    } as CSSObjectWithLabel;
  },
  multiValueRemove: (provided, props) => {
    const baseStyles = {
      ...provided,
      paddingRight: theme.spacing(8),
      paddingLeft: theme.spacing(4),
      marginLeft: theme.spacing(4),
      borderRadius: `0 ${borderRadius.large} ${borderRadius.large} 0`
    };

    const primaryVariantStyles = {
      height: '28px',
      color: props.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,

      ':hover': {
        color: theme.palette.natural.white,
        backgroundColor: theme.palette.primary.main
      }
    };

    const secondaryVariantStyles = {
      height: '22px',
      color: props.isDisabled ? theme.palette.text.disabled : theme.palette.secondary.dark,

      ':hover': {
        color: theme.palette.natural.white,
        backgroundColor: theme.palette.secondary.main
      }
    };

    return {
      ...baseStyles,
      ...(props.selectProps.variant === 'secondary' ? secondaryVariantStyles : primaryVariantStyles)
    } as CSSObjectWithLabel;
  },
  multiValueLabel: (provided, props) => {
    const baseStyles = {
      ...provided,
      padding: theme.spacing(3, 0)
    };

    const primaryVariantStyles = {
      color: theme.palette.natural.black,
      ...(theme.typography as any)['form-text']
    };

    const secondaryVariantStyles = {
      color: theme.palette.secondary.dark,
      ...theme.typography.body2
    };

    return {
      ...baseStyles,
      ...(props.selectProps.variant === 'secondary' ? secondaryVariantStyles : primaryVariantStyles)
    } as CSSObjectWithLabel;
  },
  singleValue: (provided, props) => {
    const baseStyles = {
      ...provided
    };

    const secondaryVariantStyles = {
      ...theme.typography.body2
    };
    return {
      ...baseStyles,
      ...(props.selectProps.variant === 'secondary' ? secondaryVariantStyles : {})
    } as CSSObjectWithLabel;
  }
};
