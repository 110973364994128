import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Click | AnalyticEventAction.Select;

type Value =
  | 'work queue'
  | 'care management'
  | 'create ticket'
  | 'patient list'
  | 'patient'
  | 'invite patient'
  | 'awaiting activation'
  | 'triage rules'
  | 'practice analytics'
  | 'practice manager'
  | 'pathway builder'
  | 'Patient Enrollment'
  | 'Logout'
  | 'My Account'
  | 'Account Dropdown'
  | 'patient registration';

interface Properties {
  action: Action;
  value: Value;
}

export const trackHeaderMenuAnalyticsEvent = ({ action, value }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.HeaderMenu,
    properties: { action, value }
  });
};
