import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { ErrorName } from 'errors';

import { FormProvider, useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';

import { patientRegistrationPageSelectors } from 'tests/models/pages/patient-registration/patient-registration-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher from 'fetchers/PatientsFetcher';

import { defaultMinDateInput, parseDateForInputField } from 'utils/DateUtils';

import { isValidName } from 'utils/ValidationUtils';

import { usePostPhoneInUseError } from 'hooks/usePhoneInUseError';

import { PATIENT_TABS } from 'views/Patient/PatientMain/PatientMainView.constants';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import StyledPhoneInput, { COUNTRY_CODES } from 'views/Widgets/StyledPhoneInput';

import FormDateField from 'components/Forms/FormDateField';
import { FilledButton } from 'components/UIkit/atoms/Button';
import { Text } from 'components/UIkit/atoms/Text';

interface FormValues {
  firstName: string;
  lastName: string;
  dob: string;
  phone: string;
  countryCode: COUNTRY_CODES.USA | COUNTRY_CODES.ISRAEL;
}

export const PatientRegistrationPage: FC = () => {
  const { settingsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const handlePhoneInUseError = usePostPhoneInUseError();
  const isTestInstitution = settingsStore.institutionSettings.isTest;
  const registrationForm = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      dob: '',
      phone: '',
      countryCode: COUNTRY_CODES.USA
    }
  });

  const submit = async (formValues: FormValues) => {
    trackActionButtonAnalyticsEvent({ action: AnalyticEventAction.Submit });

    try {
      setIsLoading(true);
      const { patientId } = await PatientsFetcher.registerPatient({
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        dateOfBirth: formValues.dob,
        phoneNumber: formValues.countryCode + formValues.phone
      });
      history.push(`/patient/${patientId}?tab=${PATIENT_TABS.OPEN_TICKETS}`);
    } catch (error: any) {
      if (error.name === ErrorName.PhoneInUse) {
        handlePhoneInUseError(error);
        return;
      }

      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledContainer data-test-hook={patientRegistrationPageSelectors.container}>
      <Text variant="h1" pt={8} mb={44} display="inline-block">
        Patient Registration
      </Text>

      <FormProvider {...registrationForm}>
        <StyledFormContainer>
          <StyledFlexItem>
            <RHFStyledInput
              name="firstName"
              register={registrationForm.register}
              label="First Name"
              isRequired
              error={Boolean(registrationForm.formState.errors.firstName)}
              validate={isValidName}
              testHook={patientRegistrationPageSelectors.firstNameInput}
            />
          </StyledFlexItem>

          <StyledFlexItem flexBasis="50%">
            <RHFStyledInput
              name="lastName"
              register={registrationForm.register}
              label="Last Name"
              isRequired
              error={Boolean(registrationForm.formState.errors.lastName)}
              validate={isValidName}
              testHook={patientRegistrationPageSelectors.lastNameInput}
            />
          </StyledFlexItem>

          <StyledFlexItem flexBasis="50%">
            <FormDateField
              name="dob"
              label="Date of Birth"
              isRequired
              error={registrationForm.formState.errors.dob}
              max={parseDateForInputField(new Date())}
              min={defaultMinDateInput}
              testHook={patientRegistrationPageSelectors.dateOfBirthInput}
            />
          </StyledFlexItem>

          <StyledFlexItem flexBasis="50%">
            <StyledPhoneInput
              label="Phone Number"
              withCountryFlags={isTestInstitution}
              error={Boolean(registrationForm.formState.errors.phone)}
              showPlaceholderOnError
              testHook={patientRegistrationPageSelectors.phoneNumberInput}
            />
          </StyledFlexItem>
        </StyledFormContainer>
      </FormProvider>

      <Box alignSelf="flex-end">
        <FilledButton
          onClick={registrationForm.handleSubmit(submit)}
          disabled={!registrationForm.formState.isValid || isLoading}
          testHook={patientRegistrationPageSelectors.submitButton}
        >
          Submit
        </FilledButton>
      </Box>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    max-width: 900px;
    padding: ${theme.spacing(40, 16, 16)};
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  `
);

const StyledFormContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(20)};
    margin-bottom: ${theme.spacing(56)};
  `
);

const StyledFlexItem = styled(Box)(
  ({ theme }) => css`
    flex-basis: calc(50% - ${theme.spacing(20)});
  `
);
