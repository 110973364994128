import parsePhoneNumberFromString from 'libphonenumber-js/max';

import { removeSpacesFromString } from 'utils/StringUtils';

export const isStrongPassword = (str: string) =>
  !!str.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()+=])(?=.{8,})/);

export const isEmail = (str: string) =>
  !!str.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const isAllDigits = (str: string) => !!str.match(/^[\d]+$/);

export const isAllDigitsWithSpace = (str: string) => !!str.match(/^[\d\s]+$/);

export const isValidPhoneNumber = (phoneWithCountryCode: string) => {
  if (!phoneWithCountryCode) {
    return;
  }

  if (isAllDigits(removeSpacesFromString(phoneWithCountryCode).replace(/\+/g, ''))) {
    const phoneNumber = parsePhoneNumberFromString(phoneWithCountryCode);
    if (phoneNumber) {
      return phoneNumber.isValid();
    }
  }

  return false;
};

export const isValidLocalePhoneNumber = (phone: string): boolean => {
  if (!phone) {
    return false;
  }
  // remove all non-numeric characters
  const strippedPhone = removeSpacesFromString(phone).replace(/[+()-]/g, '');
  // see API: libphonenumber https://github.com/catamphetamine/libphonenumber-js?tab=readme-ov-file#api
  const defaultCountry = strippedPhone.startsWith('0') ? 'IL' : 'US';

  if (isAllDigits(strippedPhone)) {
    const phoneNumber = parsePhoneNumberFromString(strippedPhone, defaultCountry);
    if (phoneNumber) {
      return phoneNumber.isValid();
    }
  }

  return false;
};

export const isEnglish = (string: string) => {
  const english = /^[ A-Za-z0-9_@./#&+-]*$/;
  return english.test(string);
};

export const isAlphaNumeric = (str: string) => !!str.match(/^[\w-\s]+$/);

export const isValidName = (str: string) =>
  Boolean(str.match(/^[a-zA-Z\s-,.']+$/)) && Boolean(str.trim());

export const isValidMrnNumber = (number: string) =>
  Boolean(number && number.length > 3 && number.length < 13);
