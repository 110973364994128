// @ts-strict-ignore
import { isNil, orderBy } from 'lodash/fp';

import moment from 'moment';

import { useStores } from 'mobx/hooks/useStores';

import { defaultTasksDateSort, rootStore } from 'mobx/stores';

import Call from 'models/Call';
import { SymptomTicketUrgency, OperatorTicketUrgency } from 'models/OperatorTicket';
import Patient from 'models/Patient';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';
import { TicketSortOrder } from 'models/Settings';
import Ticket, { TicketClass } from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

import { usePatientModel } from 'components/Patient/usePatientModel';

const TICKET_URGENCY_ORDER = {
  SYMPTOM: 0,
  OP_STANDARD: 1,
  OP_HIGH: 2,
  SYMPTOM_NURSE_REVIEW: 3,
  SYMPTOM_ATTENTION_TODAY: 4,
  SYMPTOM_IMMEDIATE_ATTENTION: 5
};

const ticketUrgencyToSeverityMap = {
  [`${TicketTypeKind.symptom}_${SymptomTicketUrgency.NurseReview}`]:
    TICKET_URGENCY_ORDER.SYMPTOM_NURSE_REVIEW,
  [`${TicketTypeKind.symptom}_${SymptomTicketUrgency.AttentionToday}`]:
    TICKET_URGENCY_ORDER.SYMPTOM_ATTENTION_TODAY,
  [`${TicketTypeKind.symptom}_${SymptomTicketUrgency.ImmediateAttention}`]:
    TICKET_URGENCY_ORDER.SYMPTOM_IMMEDIATE_ATTENTION,
  [`${TicketTypeKind.other}_${OperatorTicketUrgency.Standard}`]: TICKET_URGENCY_ORDER.OP_STANDARD,
  [`${TicketTypeKind.other}_${OperatorTicketUrgency.High}`]: TICKET_URGENCY_ORDER.OP_HIGH,
  [SymptomTicketUrgency.NurseReview]: TICKET_URGENCY_ORDER.SYMPTOM_NURSE_REVIEW,
  [SymptomTicketUrgency.AttentionToday]: TICKET_URGENCY_ORDER.SYMPTOM_ATTENTION_TODAY,
  [SymptomTicketUrgency.ImmediateAttention]: TICKET_URGENCY_ORDER.SYMPTOM_IMMEDIATE_ATTENTION
};

export interface TicketsCluster {
  assignedTickets: ExtendedTicket[];
  urgentSymptomsTickets: ExtendedTicket[];
  otherSymptomTickets: ExtendedTicket[];
  operatorTickets: Ticket[];
  callbackRequestTickets: Ticket[];
  tasks: Ticket[];
}

export interface PatientTicketClusters {
  overduePatients: Patient[];
}

export interface AllTicketsCluster extends PatientTicketClusters {
  ticketsCluster: TicketsCluster;
}

export interface TicketsConnectCluster {
  connectedTickets: ExtendedTicket[];
  openTickets: ExtendedTicket[];
}

export interface ExtendedTicket {
  ticket: Ticket;
  reports?: QuestionnaireAnswer[];
}

type ReportSortFn = (
  patient: Patient
) => (reportA: QuestionnaireAnswer, reportB: QuestionnaireAnswer) => number;

export interface ClusterTicketsParams {
  tickets: Ticket[];
  tasks: Ticket[];
  reportsSortFn?: ReportSortFn;
}

/**
 allows normalizing the sort between symptom operator tickets and symptom report tickets
 to normalized severity number
 @returns object - contains the normalized severity and the date to compare by
  if the severities are the same
 * @param extendedTicket
 * @param severityMap
 */
const calculateSymptomSeverity = (
  extendedTicket: ExtendedTicket,
  severityMap: { [key: string]: number }
) => {
  let severity;
  if (extendedTicket.ticket.isOperatorTicket) {
    // We want to use urgency same way as we use alert severity in order to sort
    // mixed operator tickets and report tickets
    severity =
      severityMap[
        `${extendedTicket.ticket.operatorTicket.kind}_${extendedTicket.ticket.operatorTicket.urgency}`
      ];

    return { severity, compareDate: extendedTicket.ticket.operatorTicket.createdAt };
  }

  const [firstReport] = extendedTicket.reports;
  const severityLevel = firstReport?.urgency;

  severity = severityMap[severityLevel] || 0;

  return { severity, compareDate: firstReport?.createdAt };
};

const ticketsCompareFunc = (
  extendedTicketA: ExtendedTicket,
  extendedTicketB: ExtendedTicket
): number => {
  if (
    extendedTicketA.ticket.class === TicketClass.patient &&
    extendedTicketB.ticket.class === TicketClass.patient
  ) {
    if (extendedTicketA.reports?.length === 0 && extendedTicketB.reports?.length === 0) {
      return 0;
    }
    if (extendedTicketA.reports?.length === 0) {
      return 1;
    }
    if (extendedTicketB.reports?.length === 0) {
      return -1;
    }
  }
  if (extendedTicketA.ticket.isTask !== extendedTicketB.ticket.isTask) {
    return extendedTicketA.ticket.isTask ? 1 : -1;
  }
  if (extendedTicketA.ticket.isTask) {
    return defaultTasksDateSort(
      extendedTicketA.ticket.taskTicket.dueDate,
      extendedTicketB.ticket.taskTicket.dueDate
    );
  }

  const { severity: severityA, compareDate: compareDateA } = calculateSymptomSeverity(
    extendedTicketA,
    ticketUrgencyToSeverityMap
  );
  const { severity: severityB, compareDate: compareDateB } = calculateSymptomSeverity(
    extendedTicketB,
    ticketUrgencyToSeverityMap
  );

  if (severityA !== severityB) {
    return severityA > severityB ? -1 : 1;
  }

  if (
    extendedTicketA.ticket.lastIncomingMessageDate !==
    extendedTicketB.ticket.lastIncomingMessageDate
  ) {
    return compareTicketLastIncomingMessageDates(extendedTicketA.ticket, extendedTicketB.ticket);
  }

  if (compareDateA !== compareDateB) {
    return compareTicketDates(compareDateA, compareDateB);
  }

  return 0;
};

const compareTicketDates = (dateA: string | Date, dateB: string | Date): number => {
  const { settingsStore } = rootStore.stores;
  return moment(dateA).isBefore(moment(dateB)) ===
    (settingsStore.institutionSettings.ticketSortOrder !== TicketSortOrder.OldestFirst)
    ? 1
    : -1;
};

const compareTicketLastIncomingMessageDates = (ticketA: Ticket, ticketB: Ticket): number => {
  return moment(ticketA.lastIncomingMessageDate).isBefore(ticketB.lastIncomingMessageDate) ? 1 : -1;
};

const operatorTicketsCompareFunc = (ticketA: Ticket, ticketB: Ticket): number => {
  if (ticketA.operatorTicket.urgency === ticketB.operatorTicket.urgency) {
    if (!ticketA.lastIncomingMessageDate && !ticketB.lastIncomingMessageDate) {
      return compareTicketDates(ticketA.createdAt, ticketB.createdAt);
    }

    return compareTicketLastIncomingMessageDates(ticketA, ticketB);
  }

  return ticketB.operatorTicket.urgency - ticketA.operatorTicket.urgency;
};

const callbackRequestTicketsCompareFunc = (ticketA: Ticket, ticketB: Ticket): number => {
  return compareTicketDates(ticketA.createdAt, ticketB.createdAt);
};

const handleOperatorCluster = (
  ticket: Ticket,
  operatorTickets: Ticket[],
  urgentSymptomsTickets: ExtendedTicket[]
) => {
  ticket.operatorTicket.kind === TicketTypeKind.other
    ? operatorTickets.push(ticket)
    : urgentSymptomsTickets.push({ ticket });
  return;
};

const handlePatientCluster = (
  ticket: Ticket,
  patient: Patient,
  urgentSymptomsTickets: ExtendedTicket[],
  otherSymptomTickets: ExtendedTicket[]
) => {
  const reports = patient.reportsSortedBySeverity;

  if (reports.length === 0) {
    return;
  }

  if (
    reports.some((report) => {
      return !isNil(report.urgency) && report.urgency >= SymptomTicketUrgency.NurseReview;
    })
  ) {
    urgentSymptomsTickets.push({ ticket, reports });
  } else if (patient.isLastReportUnAcknowledged) {
    otherSymptomTickets.push({ ticket, reports });
  }
};

export const useClusterTickets = ({ tickets, tasks }: ClusterTicketsParams): TicketsCluster => {
  const {
    userStore: { currentDoctor }
  } = useStores();
  const patient = usePatientModel();
  const assignedTickets: ExtendedTicket[] = [];
  const urgentSymptomsTickets: ExtendedTicket[] = [];
  const otherSymptomTickets: ExtendedTicket[] = [];
  const operatorTickets: Ticket[] = [];
  const tasksResults: Ticket[] = [];
  const callbackRequestTickets: Ticket[] = [];

  tickets.forEach((ticket) => {
    const isAssigned = ticket.isAssignedToDoctor(currentDoctor.id);
    if (isAssigned) {
      if (ticket.class !== TicketClass.patient) {
        assignedTickets.push({ ticket, reports: [] });
      } else {
        const reports = patient.reportsSortedBySeverity;

        if (reports.length > 0) {
          assignedTickets.push({ ticket, reports });
        }
      }

      return;
    }

    if (ticket.isOperatorTicket) {
      return handleOperatorCluster(ticket, operatorTickets, urgentSymptomsTickets);
    }

    if (ticket.isCallbackRequestTicket) {
      callbackRequestTickets.push(ticket);
      return;
    }

    return handlePatientCluster(ticket, patient, urgentSymptomsTickets, otherSymptomTickets);
  });

  tasks.forEach((task: Ticket) => {
    const isAssigned = task.isAssignedToDoctor(currentDoctor.id);
    if (isAssigned) {
      assignedTickets.push({ ticket: task, reports: [] });
    } else {
      tasksResults.push(task);
    }
  });

  assignedTickets.sort(ticketsCompareFunc);
  urgentSymptomsTickets.sort(ticketsCompareFunc);
  otherSymptomTickets.sort(ticketsCompareFunc);
  operatorTickets.sort(operatorTicketsCompareFunc);
  callbackRequestTickets.sort(callbackRequestTicketsCompareFunc);

  return {
    assignedTickets,
    urgentSymptomsTickets,
    otherSymptomTickets,
    operatorTickets,
    tasks: tasksResults,
    callbackRequestTickets
  };
};

export const clusterTicketsForCallConnect = (
  tickets: Ticket[],
  patient: Patient,
  forCall: Partial<Call> | Call
): TicketsConnectCluster => {
  const openTickets: ExtendedTicket[] = [];
  const connectedTickets: ExtendedTicket[] = [];
  const reports = patient.reportsSortedBySeverity;
  tickets.forEach((ticket: Ticket) => {
    const extendedTicket = ticket.isOperatorTicket ? { ticket, reports: [] } : { ticket, reports };
    if (forCall.ticketIds && forCall.ticketIds.has(ticket.id)) {
      connectedTickets.push(extendedTicket);
    } else {
      if (ticket.isActive) {
        openTickets.push(extendedTicket);
      }
    }
  });

  return {
    connectedTickets,
    openTickets: orderBy([getSortBySeverityForCallTicketsConnect], ['desc'], openTickets)
  };
};

/*
 * This configuration defines the sorting order according to this:
 * High Urgency, then Standard Urgency Symptom Report via Operator/App/Report Line
 * High Urgency Operator Ticket
 * Standard Urgency Operator Ticket
 * Mild Symptom Report
 * */
const CONNECT_TICKETS_URGENCY_ORDER = {
  SYMPTOM: 0,
  OP_STANDARD: 0,
  OP_HIGH: 1,
  SYMPTOM_NURSE_REVIEW: 2,
  SYMPTOM_ATTENTION_TODAY: 3,
  SYMPTOM_IMMEDIATE_ATTENTION: 4
};

const connectTicketsToCallSeverityMap = {
  [`${TicketTypeKind.symptom}_${SymptomTicketUrgency.NurseReview}`]:
    CONNECT_TICKETS_URGENCY_ORDER.SYMPTOM_NURSE_REVIEW,
  [`${TicketTypeKind.symptom}_${SymptomTicketUrgency.AttentionToday}`]:
    CONNECT_TICKETS_URGENCY_ORDER.SYMPTOM_ATTENTION_TODAY,
  [`${TicketTypeKind.symptom}_${SymptomTicketUrgency.ImmediateAttention}`]:
    CONNECT_TICKETS_URGENCY_ORDER.SYMPTOM_IMMEDIATE_ATTENTION,
  [`${TicketTypeKind.other}_${OperatorTicketUrgency.Standard}`]:
    CONNECT_TICKETS_URGENCY_ORDER.OP_STANDARD,
  [`${TicketTypeKind.other}_${OperatorTicketUrgency.High}`]: CONNECT_TICKETS_URGENCY_ORDER.OP_HIGH,
  [SymptomTicketUrgency.NurseReview]: CONNECT_TICKETS_URGENCY_ORDER.SYMPTOM_NURSE_REVIEW,
  [SymptomTicketUrgency.AttentionToday]: CONNECT_TICKETS_URGENCY_ORDER.SYMPTOM_ATTENTION_TODAY,
  [SymptomTicketUrgency.ImmediateAttention]:
    CONNECT_TICKETS_URGENCY_ORDER.SYMPTOM_IMMEDIATE_ATTENTION
};

const getSortBySeverityForCallTicketsConnect = (ticket: ExtendedTicket) =>
  calculateSymptomSeverity(ticket, connectTicketsToCallSeverityMap).severity;
