import { FC, MouseEvent } from 'react';

import { Box, css, styled } from '@mui/material';

import { formatDate } from 'utils/DateUtils';

import { SearchCircle } from 'views/Widgets/icons/SearchCircle';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';
import { SEARCH_BUTTONS_WIDTH_WITH_MARGIN } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.constants';
import { AdvancedSearchFormFields } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.types';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  openAdvancedSearch: () => void;
  isLoading: boolean;
  advancedSearchMetrics: Partial<AdvancedSearchFormFields>;
  clearSearch: (event: MouseEvent<HTMLButtonElement>) => void;
  onGoClicked: (values: Partial<AdvancedSearchFormFields>) => void;
  variant: 'dark' | 'light';
  maxWidth: string;
}

export const AdvancedSearchBox: FC<Props> = ({
  openAdvancedSearch,
  clearSearch,
  isLoading,
  advancedSearchMetrics,
  onGoClicked,
  variant,
  maxWidth = ''
}) => (
  <StyledAdvancedControl variant={variant} maxWidth={maxWidth}>
    <Text
      isEllipsis
      maxWidth={`calc(100% - ${SEARCH_BUTTONS_WIDTH_WITH_MARGIN}px)`}
      component="div"
      onClick={openAdvancedSearch}
    >
      {advancedSearchMetrics.name && (
        <Text color="disabled" variant="form-text" mr={12}>
          Name: <Text variant="form-text">{advancedSearchMetrics.name}</Text>
        </Text>
      )}

      {advancedSearchMetrics.dob && (
        <Text color="disabled" variant="form-text" mr={12}>
          DOB:{' '}
          <Text variant="form-text">{formatDate(advancedSearchMetrics.dob, 'MM/DD/yyyy')}</Text>
        </Text>
      )}

      {advancedSearchMetrics.mrn && (
        <Text color="disabled" variant="form-text" mr={12}>
          MRN: <Text variant="form-text">{advancedSearchMetrics?.mrn}</Text>
        </Text>
      )}

      {advancedSearchMetrics.phone && (
        <Text color="disabled" variant="form-text" mr={12}>
          PH: <Text variant="form-text">{advancedSearchMetrics?.phone}</Text>
        </Text>
      )}
    </Text>

    <div className="search-buttons p-2 mr-1" onClick={(event) => event.stopPropagation()}>
      <Box mr={8}>
        <IconButton onClick={clearSearch}>
          <Icon.XThin />
        </IconButton>
      </Box>

      <IconButton onClick={() => onGoClicked(advancedSearchMetrics)}>
        {isLoading && (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}

        <SearchCircle />
      </IconButton>
    </div>
  </StyledAdvancedControl>
);

const StyledAdvancedControl = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'maxWidth'
})<{
  variant: 'dark' | 'light';
  maxWidth: string;
}>(({ theme, variant, maxWidth }) => {
  const isDarkVariant = variant === 'dark';

  return css`
    //same styles as .header-search-box .styled-input .input-area input
    width: 100%;
    min-width: 320px;
    max-width: ${maxWidth || 'auto'};
    background-color: ${isDarkVariant
      ? theme.palette.natural.contrastDark
      : theme.palette.natural.white};

    border-radius: 23px;
    font-size: 13.5px;
    border: 1px solid ${isDarkVariant ? 'transparent' : theme.palette.natural.border};
    height: 45px;
    padding: ${theme.spacing(16)};
    position: relative;
    display: flex;
    align-items: center;

    * {
      cursor: pointer;
    }
  `;
});
