import { ChangeEvent, FC, FocusEventHandler, Ref } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import StyledInput from 'views/Widgets/StyledInput';

import Icon from 'components/Icons/Icon';
import { extraThemeOptions } from 'components/UIkit/theme/extraThemeOptions';

interface Props extends Testable {
  searchValue: string;
  label: string;
  disabled?: boolean;
  onSearchChanged: (searchTerm: string) => void;
  className?: string;
  onFocus?: FocusEventHandler<any>;
  inputRef?: Ref<HTMLInputElement | HTMLTextAreaElement>;
  variant?: 'primary' | 'secondary';
}

const SearchBar: FC<Props> = ({
  searchValue,
  label,
  disabled,
  onSearchChanged,
  className,
  onFocus,
  inputRef,
  testHook,
  variant = 'primary'
}) => (
  <StyledContainer className={className} variant={variant}>
    <StyledInput
      testHook={testHook}
      label={label}
      type="text"
      disabled={disabled}
      value={searchValue || ''}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChanged(e.target.value)}
      onFocus={onFocus}
      inputRef={inputRef}
    >
      <StyledSearchIconContainer variant={variant}>
        <Icon.Search />
      </StyledSearchIconContainer>
    </StyledInput>
  </StyledContainer>
);

const StyledContainer = styled(Box)<{ variant: 'primary' | 'secondary' }>(
  ({ variant, theme }) => css`
    .styled-input {
      .input-area {
        position: relative;

        input {
          height: 45px;
          display: flex;
          align-items: center;
          padding: 0 0 0 38px;

          ${variant === 'secondary' &&
          css`
            transition: all 0.3s ease-in;
            position: relative;
            height: 34px;
            background-color: ${theme.palette.natural.contrast};
            font-weight: ${extraThemeOptions.fontWeights.normal};
            font-size: ${extraThemeOptions.fontSizes.normal};
            line-height: 16px;

            &:not(:disabled) {
              background-color: ${theme.palette.natural.contrast};
            }
          `}
        }
      }

      ${variant === 'secondary' &&
      css`
        .input-header {
          width: fit-content;
        }

        &.disabled .input-area input {
          background-color: ${theme.palette.natural.inactiveBackground};
        }

        .input-header {
          font-weight: ${extraThemeOptions.fontWeights.medium};
          font-size: ${extraThemeOptions.fontSizes.small};
          line-height: 14px;

            span {
              transition: all 0.3s ease-in;
            }
          }

          &:has(input:hover, input:focus, input:active, input:not(:disabled):focus, .input-header:hover, .input-header:focus, .input-header:active) {
            .input-header span {
              color: ${theme.palette.secondary.dark};
              
            }
            .input-area input {
              background-color: ${theme.palette.natural.white};
              border-color: ${theme.palette.secondary.main};

              + div svg {
                transition: all 0.3s ease-in;
                color: ${theme.palette.secondary.main};
              }
            }
            .input-header span {
              color: ${theme.palette.secondary.dark};
            }
          }
        }
      `}
    }
  `
);

const StyledSearchIconContainer = styled(Box)<{ variant: 'primary' | 'secondary' }>(
  ({ theme, variant }) => css`
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    height: fit-content;

    svg {
      transition: all 0.3s ease-in;
      color: ${theme.palette.text.secondary};
    }
  `
);

export default SearchBar;
