import Patient from 'models/Patient';

export interface OralReportSummaryProps<OralReportType> {
  oral: OralReportType;
  patient: Patient;
  reportId: number;
  isCallLoggerVersion?: boolean;
}

export interface OralReportCycleProps<OralReportType> {
  oral: OralReportType;
  patient: Patient;
  reportId: number;
}

export const noInfoProvidedText = 'No Info Provided';
