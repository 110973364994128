import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Show | AnalyticEventAction.Hide;
type Value = 'task' | 'ticket';

interface Properties {
  action: Action;
  value: Value;
  page_number: number;
  type: 'long ticket description' | null;
  tab?: WorkQueueTabName;
  with_message: boolean;
}

export const trackShowMoreAnalyticsEvent = ({
  action,
  value,
  page_number,
  type,
  with_message,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.ShowDetails,
    properties: { action, value, page_number, type, with_message, ...optionalProps }
  });
};
