import { FC, useState } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher from 'fetchers/PatientsFetcher';

import { ResolveTicketsData, useTicketResolve } from 'hooks/useTicketResolve';

import { usePatientModel } from 'components/Patient/usePatientModel';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
  resolveTicketsData: ResolveTicketsData;
}

export const HidePatientDialog: FC<Props> = ({ isOpen, closeDialog, resolveTicketsData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const ticketResolve = useTicketResolve();
  const patient = usePatientModel(resolveTicketsData?.patientId);
  const { careTimerStore } = useStores();

  async function hidePatient() {
    try {
      setIsLoading(true);
      closeDialog();
      await ticketResolve(
        {
          ticketIds: resolveTicketsData.ticketIds,
          comment: resolveTicketsData.comment,
          allowResolveLocalPatientTicket: true
        },
        '/work-queue'
      );
      await careTimerStore.endSession();
      await PatientsFetcher.hidePatient(patient!.id);
    } finally {
      closeDialog();
      setIsLoading(false);
    }
  }

  return (
    <MessageDialog
      id={Dialogs.HidePatient}
      isOpen={isOpen}
      handleClose={closeDialog}
      title={`${
        patient?.fullName || 'Patient'
      } Will Be Hidden from Canopy if Match is Not Found in Practice Management System`}
      primaryActionProps={{ text: 'Hide From Canopy', onClick: hidePatient, disabled: isLoading }}
      secondaryActionProps={{ text: 'Cancel', onClick: closeDialog }}
    >
      To keep this patient visible, ensure a matching patient exists in your practice management
      system, then resolve this ticket again.
      <br />
      <br />
      Matches are determined by name and date of birth.
      <br />
      <br />
      Note: If multiple matches exist, Canopy will not be able to identify the correct patient.
    </MessageDialog>
  );
};
