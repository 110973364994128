// @ts-strict-ignore
import { FunctionComponent, ReactNode } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getRegimenText } from 'utils/OralOncoUtils';

import Patient from 'models/Patient';

import QuestionnaireAnswer, { ReportType } from 'models/QuestionnaireAnswer';

import Ticket from 'models/Ticket';

import { useIsFever } from 'hooks/useFever';

import DistressCausesReport from 'components/Ticket/TicketRow/DistressCausesReport';
import { DrugSpecificReportSummary } from 'components/Ticket/TicketRow/DrugSpecificReportSummary';
import OralReportSummary from 'components/Ticket/TicketRow/OralReport/OralReportSummary';
import { useTicketProviderLocation } from 'components/Ticket/TicketRow/shared';

import { Text } from 'components/UIkit/atoms/Text';

import CovidReport from './CovidReport';
import { TemperatureOrFeverCauseReport } from './TemperatureOrFeverCauseReport';

interface Props {
  ticket: Ticket;
  report: QuestionnaireAnswer;
  patient: Patient;
  children?: ReactNode;
  isCallLoggerVersion?: boolean;
}

const SymptomReportSummary: FunctionComponent<Props> = ({
  ticket,
  report,
  patient,
  children,
  isCallLoggerVersion
}) => {
  const { constantsStore, alertsStore } = useStores();

  const firstReport = report.patientReportIndex === 0;
  const secondReport = report.patientReportIndex === 1;
  const providerLocationText = useTicketProviderLocation(ticket);

  const getCallbackText = () => {
    let callbackText = report.answer.callbackRequest.value ? 'Yes' : 'No';
    if (!report.answer.callbackRequest.value) {
      if (report.answer.callbackRequest.customMessage) {
        callbackText += ` - ${report.answer.callbackRequest.customMessage}`;
      } else if (report.callbackDenyReasonId) {
        callbackText += ` - ${
          constantsStore.getDenyCallbackReasonById(report.callbackDenyReasonId).text
        }`;
      }
    }
    return callbackText;
  };

  const getTitle = () => {
    if (report.type === ReportType.Oral) {
      const { regimen, isCustom } = report.answer.oral;
      return `Oral Oncolytics Assessment (${getRegimenText(regimen, isCustom)})`;
    }

    if (report.type === ReportType.DrugSpecific) {
      const drugId = patient.mainScheduledProtocol?.info?.drugId;
      const drug = constantsStore.getSpecificDrugById(drugId);
      const drugName = drug?.name;

      if (drugName) {
        return `Symptom Assessment (${drugName})`;
      }
    }

    return `Symptom Assessment`;
  };

  const { temperatureCause, isFever } = useIsFever(report);
  const txTags = alertsStore.getRelevantTxAlertsForSymptom(temperatureCause, patient);

  return (
    <>
      {firstReport && <div className="highlighted-title">First report submission</div>}

      {secondReport && <div className="highlighted-title">Second report submission</div>}

      <Text variant={isCallLoggerVersion ? 'body1' : 'h4'}>{getTitle()}</Text>

      {providerLocationText && (
        <Text variant={isCallLoggerVersion ? 'body2' : 'form-text'} color="secondary">
          {' '}
          — {providerLocationText}
        </Text>
      )}

      <TemperatureOrFeverCauseReport
        cause={temperatureCause}
        isFever={isFever}
        txTags={txTags}
        isCallLoggerVersion={isCallLoggerVersion}
      />

      {report.answer.distressLevel >= 0 && (
        <div>
          <p className="mb-0">
            <Text
              variant={isCallLoggerVersion ? 'body2' : 'body1'}
              color={isCallLoggerVersion ? 'secondary' : 'primary'}
            >
              Distress:&nbsp;
            </Text>
            {report.answer.distressLevel}
          </p>
        </div>
      )}

      {report.type === ReportType.Oral && (
        <OralReportSummary
          report={report}
          patient={patient}
          isCallLoggerVersion={isCallLoggerVersion}
        />
      )}

      {report.type === ReportType.Distress && (
        <DistressCausesReport
          report={report}
          patient={patient}
          isCallLoggerVersion={isCallLoggerVersion}
        />
      )}

      {report.type === ReportType.DrugSpecific && (
        <DrugSpecificReportSummary
          report={report}
          patient={patient}
          isCallLoggerVersion={isCallLoggerVersion}
        />
      )}

      {report.answer.callbackRequest?.isAnswered && (
        <div>
          <Text
            variant={isCallLoggerVersion ? 'body2' : 'body1'}
            color={isCallLoggerVersion ? 'secondary' : 'primary'}
          >
            Callback Preference:{' '}
          </Text>
          {getCallbackText()}
        </div>
      )}

      {report.type === ReportType.COVID && <CovidReport report={report} patient={patient} />}

      {children}
    </>
  );
};

export default observer(SymptomReportSummary);
