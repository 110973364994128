// @ts-strict-ignore
import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { Dialogs } from 'analytics/events/dialog';

import { ErrorName } from 'errors';
import { FieldError, useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { CreateUpdateTaskRoleParams } from 'fetchers/TaskRolesFetcher';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormErrors } from 'components/Forms';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

// TODO: check length
const MAX_ROLE_NAME_LENGTH = 255;

export interface EditCreateRoleFields {
  name: string;
}

interface Props {
  roleId?: number;
  onClose: () => void;
  onDelete: (id: number) => void;
  isOpen: boolean;
  resetDataAfterClose: () => void;
}

const EditCreateRoleModal: FC<Props> = ({
  roleId,
  onClose,
  onDelete,
  isOpen,
  resetDataAfterClose
}) => {
  const { constantsStore, episodeManagementStore } = useStores();
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const isCreate = !roleId;
  const roleName =
    roleId && !isCreate ? constantsStore.getRoleByIdWithAllAndNoRoles(roleId)?.name : null;
  const methods = useForm<EditCreateRoleFields>();

  const { handleSubmit, register, formState } = methods;
  const { errors } = formState;

  const setDuplicateRoleError = () => {
    methods.setError('name', {
      type: 'server',
      message: 'This role already exists'
    });
  };
  const onSaveRole = async (id: number, body: CreateUpdateTaskRoleParams) => {
    try {
      await episodeManagementStore.updateTaskRole(id, body);
      onClose();
    } catch (e) {
      if (e.name === ErrorName.DuplicateRoleName) {
        setDuplicateRoleError();
        return;
      }
      throw e;
    }
  };
  const onCreateRole = async (body: CreateUpdateTaskRoleParams) => {
    try {
      await episodeManagementStore.createTaskRole(body);
      onClose();
    } catch (e) {
      if (e.name === ErrorName.DuplicateRoleName) {
        setDuplicateRoleError();
        return;
      }
      throw e;
    }
  };

  const onSaveClicked = (values: EditCreateRoleFields) => {
    !isCreate ? onSaveRole(roleId, values) : onCreateRole(values);
  };

  return (
    <>
      {isDeleteWarningOpen && (
        <MessageDialog
          id={Dialogs.DeleteRole}
          isOpen
          title="Delete this role?"
          handleClose={() => setIsDeleteWarningOpen(false)}
          primaryActionProps={{
            text: 'Delete',
            onClick: () => {
              onDelete(roleId);
              setIsDeleteWarningOpen(false);
            }
          }}
          secondaryActionProps={{ text: 'Cancel', onClick: () => setIsDeleteWarningOpen(false) }}
        >
          "{roleName}" will be deleted. It will remain visible among the Task Role filter options if
          there are any unresolved tasks currently assigned to it.
        </MessageDialog>
      )}

      <FormModal
        defaultValues={{ name: roleName }}
        methods={methods}
        isOpen={isOpen}
        title={isCreate ? 'Add Role' : 'Edit Role'}
        confirmActions={[{ onClick: handleSubmit(onSaveClicked), text: 'Save', disabled: false }]}
        closeAction={{ onClick: onClose, disabled: false }}
        secondaryAction={{
          isVisible: !isCreate,
          type: 'button',
          onClick: () => setIsDeleteWarningOpen(true),
          disabled: false,
          text: 'Delete'
        }}
        resetDataAfterClose={resetDataAfterClose}
      >
        <Box mb={40}>
          <RHFStyledInput
            name="name"
            error={Boolean(errors.name)}
            register={register}
            maxLength={MAX_ROLE_NAME_LENGTH}
            isRequired
            label="Role"
          />
        </Box>
        <FormErrors errors={errors as Record<string, FieldError>} />
      </FormModal>
    </>
  );
};

export default EditCreateRoleModal;
