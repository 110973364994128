import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { SearchedClinician } from 'fetchers/CliniciansFetcher';

import { ISelectOption } from './Select.shared';

export const sortOptionsByLocale = (a: { label: string }, b: { label: string }) =>
  a.label.localeCompare(b.label);

export const useSelectRHFRegister = (
  register: ReturnType<typeof useForm>['register'],
  validate: (value: any) => boolean | string,
  isRequired: boolean | string,
  name: string
) => {
  useEffect(
    function registerField() {
      register(name, {
        validate: validate,
        required: isRequired
      });
    },
    [isRequired, validate, name, register]
  );
};

export function findOption(value: any, options: ISelectOption<any>[]): ISelectOption<any> {
  if (typeof value === 'object') {
    const idType = typeof value.id;
    if (idType !== 'number' && idType !== 'string') {
      console.warn(`[findOption] Possible issue finding option by id (type: ${idType})`);
    }
    return options.find((option) => option.value.id === value.id);
  }
  return options.find((option) => option.value === value);
}

export function getAssigneesOptionsForFilterSelect(clinicians: SearchedClinician[]) {
  return clinicians.map((clinician) => ({
    value: clinician.id,
    label: `${clinician.firstName} ${clinician.lastName}`
  }));
}

// reflecting react-select action meta
export enum SelectActionMetaName {
  Select = 'select-option',
  Remove = 'remove-value',
  Create = 'create-option',
  Clear = 'clear',
  Pop = 'pop-value',
  Deselect = 'deselect-option'
}
