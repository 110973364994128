import { FC, useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { trackMultiSelectionInputUsageAnalyticsEvent } from 'analytics/events/input-usage';

import { observer } from 'mobx-react';
import { components, GroupBase, MultiValueGenericProps } from 'react-select';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { CommunicationType } from 'models/Call';

import {
  CommunicationTypeToLabelMap,
  ContactGroupOption,
  ContactOption,
  ContactType
} from 'models/ContactOptions';

import Patient from 'models/Patient';

import { SimpleTooltip } from 'components/Tooltip';
import { FormMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/Autocomplete';
import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';

const ContactMultiValueLabel: FC<
  MultiValueGenericProps<ContactOption, boolean, GroupBase<ContactOption>>
> = (props) => {
  const { data, selectProps } = props;

  const parentOption = (selectProps.options as ContactGroupOption[]).find((option) =>
    option.options?.some((child) => child.value === data.value)
  );

  const type = CommunicationTypeToLabelMap[data.communicationType as CommunicationType];

  const getFirstName = () => {
    const fullName = parentOption?.label.split(' (')[0];
    return fullName?.split(' ')[0];
  };

  const firstName = getFirstName();

  return (
    <SimpleTooltip
      title={
        <Box p={12}>
          {parentOption?.label}
          <br />
          {data.label}
        </Box>
      }
      placement="top"
    >
      <div>
        <components.MultiValueLabel {...props}>
          {firstName} ({type})
        </components.MultiValueLabel>
      </div>
    </SimpleTooltip>
  );
};

interface ContactsMultiAutocompleteProps {
  patient: Patient;
  name: string;
  openPatientContactModal: (contactId?: number) => void;
  openPatientModal: () => void;
  isDisabled?: boolean;
}

export const FormContactsMultiAutocomplete: FC<ContactsMultiAutocompleteProps> = observer(
  ({ patient, name, openPatientContactModal, openPatientModal, isDisabled }) => {
    const sortedContacts = useMemo(() => {
      const contacts: ContactGroupOption[] = [];
      const patientOptions: ContactOption[] = [];

      if (patient.phone) {
        patientOptions.push({
          value: `${patient.id}_${CommunicationType.Sms}`,
          label: getFormattedPhoneNumber(patient.phone),
          communicationType: CommunicationType.Sms,
          actualValue: patient.phone
        });
      }

      if (patient.email) {
        patientOptions.push({
          value: `${patient.id}_${CommunicationType.Email}`,
          label: patient.email,
          communicationType: CommunicationType.Email,
          actualValue: patient.email
        });
      }

      if (patientOptions.length > 0) {
        contacts.push({
          value: patient.id,
          label: patient.fullName || 'Patient',
          options: patientOptions,
          contactType: ContactType.Patient,
          isEditable: true
        });
      }

      if (patient?.contacts?.length) {
        patient.contacts
          .filter((contact) => !contact.deletedAt)
          .forEach((contact) => {
            const contactOptions: ContactOption[] = [];

            if (contact.phoneNumber) {
              contactOptions.push({
                value: `${contact.id}_${CommunicationType.Sms}`,
                label: getFormattedPhoneNumber(contact.phoneNumber),
                communicationType: CommunicationType.Sms,
                contactId: contact.id,
                actualValue: contact.phoneNumber
              });
            }

            if (contact.email) {
              contactOptions.push({
                value: `${contact.id}_${CommunicationType.Email}`,
                label: contact.email,
                communicationType: CommunicationType.Email,
                contactId: contact.id,
                actualValue: contact.email
              });
            }

            if (contactOptions.length > 0) {
              const relationshipText = contact.relationship ? ` (${contact.relationship})` : '';
              contacts.push({
                value: contact.id,
                label: `${contact.name}${relationshipText}`,
                relationship: contact.relationship,
                options: contactOptions,
                contactType: ContactType.Contact,
                isEditable: true
              });
            }
          });
      }

      return [...contacts].sort((a, b) => {
        // Patient always first
        if (a.contactType === ContactType.Patient) return -1;
        if (b.contactType === ContactType.Patient) return 1;

        return a.label.localeCompare(b.label);
      });
    }, [patient.contacts, patient.email, patient.fullName, patient.id, patient.phone]);

    const handleEditContact = useCallback(
      (option: ISelectOption<string | number>) => {
        const isParentOption = option.options && option.options.length > 0;
        const groupOption = option as ContactGroupOption;

        if (isParentOption && groupOption.contactType === ContactType.Patient) {
          openPatientModal();
          return;
        }

        const contactId = groupOption.value as number;

        if (contactId) {
          openPatientContactModal(contactId);
        }
      },
      [openPatientContactModal, openPatientModal]
    );

    const getOptionActionText = useCallback((option: ISelectOption<string | number>) => {
      const hasEmailOption = option.options?.some(
        (subOption) => subOption.communicationType === CommunicationType.Email
      );

      return hasEmailOption ? 'EDIT' : 'EDIT/ADD EMAIL';
    }, []);

    return (
      <FormMultiAutocomplete
        name={name}
        options={sortedContacts}
        isDisabled={isDisabled}
        addText="Contact"
        onAddClick={openPatientContactModal}
        onEditClick={handleEditContact}
        placeholder="To:"
        inlineLabel="To:"
        aria-label={
          patientPageTestSelectors.callLogger.homeCareInstructionsSection.recipientsSelect
        } // needed for tests
        variant="secondary"
        hideSelectedOptions={false}
        displaySelectedSummary={false}
        sortAlphabetically={false}
        closeMenuOnSelect={false}
        isClearable
        getOptionActionText={getOptionActionText}
        components={{
          MultiValueLabel: ContactMultiValueLabel
        }}
        onChange={(_, actionMeta, eventKey) =>
          trackMultiSelectionInputUsageAnalyticsEvent(
            actionMeta,
            'Home Care Instructions Contacts',
            eventKey === 'Enter'
          )
        }
      />
    );
  }
);
