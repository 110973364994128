import { FC } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import classNames from 'classnames';

import { observer } from 'mobx-react';

import Comment from 'models/Comment';

import SanitizeHtml from 'views/Widgets/SanitizeHtml';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  comment: Comment;
}

export const TicketComment: FC<Props> = observer(({ comment }) => (
  <StyledContainer>
    <Text>{comment.authorFullName}</Text> <Text color="secondary">{comment.creationInfo}</Text>
    <SanitizeHtml html={comment.textWithLineBreaks} className={classNames('comment-description')} />
  </StyledContainer>
));

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(20)};

    &:not(:first-of-type) {
      border-top: 1px solid ${theme.palette.natural.border};
      padding-top: ${theme.spacing(20)};
    }
  `
);
