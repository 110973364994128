import { observable, computed, makeObservable } from 'mobx';

import { getNameWithCredentials } from 'utils/ClinicianCredentialUtils';

import { formatDate } from 'utils/DateUtils';
import { textWithLineBreaks } from 'utils/StringUtils';

export type CommentableType = 'ticket';

interface CommentAuthor {
  firstName: string;
  lastName: string;
  credential: string | null;
}
export default class Comment {
  id: number;
  @observable
  text: string;
  @observable
  createdAt: Date;
  @observable
  author: CommentAuthor;

  constructor(user: any, id: number, text: string, createdAt: Date) {
    makeObservable(this);
    this.author = {
      // snake case used on comment creation (until server is updated to V2)
      firstName: user.first_name || user.firstName,
      lastName: user.last_name || user.lastName,
      credential: user.credential
    };
    this.id = id;
    this.text = text;
    this.createdAt = createdAt;
  }

  @computed
  get authorFullName() {
    return getNameWithCredentials(
      `${this.author.firstName} ${this.author.lastName}`,
      this.author.credential
    );
  }

  @computed
  get creationInfo() {
    return formatDate(this.createdAt, 'MM/DD/YYYY, h:mm A');
  }

  @computed
  get textWithLineBreaks() {
    return textWithLineBreaks(this.text);
  }
}
