// @ts-strict-ignore
import { FC, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { ErrorName, HttpError } from 'errors';
import { observer } from 'mobx-react';
import { FieldError, useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { API_URLS } from 'constants/apiUrls';
import { CM_INELIGIBILITY, REASON_TYPE } from 'constants/reasons.const';

import { Reason } from 'models/Reason';

import { SelectOption } from 'models/SelectOption';

import { useCheckbox } from 'hooks/useInput';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

import { FormErrors } from 'components/Forms';

import { FormSelect } from 'components/UIkit/atoms/Dropdown';
import { SelectActionMetaName } from 'components/UIkit/atoms/Dropdown/SelectUtils';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import EditReasonModal from './CareManagementEditReasonModal';

export const MAX_REASON_LENGTH = 100;
const MAX_NOTE_LENGTH = 500;

enum Modal {
  main,
  edit
}

interface ReasonForm {
  reason: any; // TODO: replace with SelectOption<number> once issue fixed on RHF. see https://github.com/react-hook-form/react-hook-form/issues/9131
  note: string;
  newReason: string;
}

const REASON_FORM_DEFAULTS: ReasonForm = {
  reason: null,
  note: '',
  newReason: ''
};

export function useSelectedReason(reasonId: number, reasonType: REASON_TYPE, reasons: Reason[]) {
  const isEditLoading = useNetworkLoading(API_URLS.REASON_BY_ID(reasonId, reasonType));
  const value = reasons.find((reason: Reason) => reason.id === reasonId);
  return { isEditLoading, value };
}

interface Props {
  isOpen: boolean;
  reasonType: REASON_TYPE;
  patientName?: string;
  onClose: () => void;
  resetDataAfterClose: () => void;
  setSelectedReason: (reasonId: number, note: string) => Promise<void>;
}

const CareManagementSelectReasonModal: FC<Props> = ({
  isOpen,
  reasonType,
  patientName,
  onClose,
  setSelectedReason
}) => {
  const { reasonsStore, userStore } = useStores();
  const [activeModal, setActiveModal] = useState<Modal>(Modal.main);
  const [isAddingNewReason, setIsAddingNewReason] = useState(false);
  const saveNewCustomReason = useCheckbox(true);
  const [isLoading, setIsLoading] = useState(false);

  const reasons = reasonsStore.getReasonsByType(reasonType);
  const reasonOptions = useMemo(
    () =>
      reasons.map((reason) => ({
        value: reason.id,
        label: reason.title,
        isEditable: reason.isEditable && userStore.isManager
      })),
    [reasons, userStore.isManager]
  );
  const methods = useForm<ReasonForm>();
  const { setError, formState } = methods;
  const { errors } = formState;

  const [selectedReasonOption, newReason] = methods.watch(['reason', 'newReason']);
  const selectedReason = useSelectedReason(selectedReasonOption?.value, reasonType, reasons);

  const handleErrorResponse = (error: HttpError) => {
    if (error.name === ErrorName.DuplicateReason) {
      setError('newReason', {
        type: 'server',
        message: error.ui.title
      });
    }
  };

  const handleSaveClicked = async () => {
    try {
      setIsLoading(true);
      const { reason: selectedReasonOption, note, newReason } = methods.getValues();
      let reasonId = selectedReasonOption?.value;

      if (isAddingNewReason) {
        reasonId = await reasonsStore.createReason(
          reasonType,
          newReason,
          saveNewCustomReason.checked
        );
      }

      await setSelectedReason(reasonId, note);
      onClose();
      setIsAddingNewReason(false);
    } catch (e) {
      handleErrorResponse(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditCanceled = () => {
    methods.resetField('reason');
    setActiveModal(Modal.main);
  };

  const onEditReasonClick = (reason: SelectOption<number>) => {
    methods.setValue('reason', reason);
    setActiveModal(Modal.edit);
  };

  const saveNewReasonName = () => {
    setActiveModal(Modal.main);
  };

  const deleteReason = async () => {
    try {
      await reasonsStore.deleteReason(reasonType, selectedReason.value.id);
    } finally {
      setActiveModal(Modal.main);
      methods.resetField('reason');
      methods.resetField('note');
    }
  };

  const isIneligibility = reasonType === CM_INELIGIBILITY;
  const reasonLabel = `${isIneligibility ? 'Ineligibility Reason' : 'Opt-Out Reason Title'}`;

  const isEditReasonModalOpen = Boolean(selectedReasonOption) && activeModal === Modal.edit;

  return (
    <div>
      <EditReasonModal
        title={isIneligibility ? `Edit Ineligibility Reason` : `Edit Opt-Out Reason`}
        selectedReasonId={selectedReasonOption?.value}
        reasonType={reasonType}
        onSave={saveNewReasonName}
        onCancel={handleEditCanceled}
        onDelete={deleteReason}
        reasonLabel={reasonLabel}
        isOpen={isEditReasonModalOpen}
      />

      <FormModal
        defaultValues={REASON_FORM_DEFAULTS}
        methods={methods}
        isOpen={isOpen}
        isInternalModalOpen={isEditReasonModalOpen}
        title={`${patientName ? `[${patientName}] ` : ''} ${
          isIneligibility ? 'Mark as Ineligible' : 'Opt Out of Care Management'
        }`}
        confirmActions={[
          {
            onClick: methods.handleSubmit(handleSaveClicked),
            text: isIneligibility ? 'Mark as Ineligible' : 'Opt Out',
            disabled: isLoading
          }
        ]}
        closeAction={{
          onClick: () => {
            setIsAddingNewReason(false);
            onClose();
          },
          disabled: false
        }}
        secondaryAction={{
          type: 'controlled-labeled-checkbox',
          isVisible: isAddingNewReason,
          label: 'Save this reason for future use',
          id: 'save-reason',
          onChange: () => saveNewCustomReason.onChange(!saveNewCustomReason.checked),
          checked: saveNewCustomReason.checked
        }}
      >
        <Box mb={40}>
          <FormSelect
            options={reasonOptions}
            name="reason"
            placeholder="Select Reason"
            addText={userStore.isManager && 'Reason'}
            onEditClick={onEditReasonClick}
            onAddClick={() => setIsAddingNewReason(true)}
            onChange={(option, actionMeta) => {
              if (option && actionMeta.action === SelectActionMetaName.Select) {
                setIsAddingNewReason(false);
              }
            }}
            label={reasonLabel}
            isRequired={!newReason}
          />

          {isAddingNewReason && (
            <Box my={16}>
              <RHFStyledInput
                isRequired
                register={methods.register}
                maxLength={MAX_REASON_LENGTH}
                error={Boolean(errors.newReason)}
                name="newReason"
                placeholder={reasonLabel}
                rounded
              />
            </Box>
          )}

          {(selectedReasonOption || isAddingNewReason) && (
            <Box mt={16}>
              <RHFStyledInput
                register={methods.register}
                maxLength={MAX_NOTE_LENGTH}
                error={Boolean(errors.note)}
                name="note"
                placeholder={`Optional: add details to the ${
                  isIneligibility ? 'ineligibility' : 'opt-out'
                } reason`}
                rounded
                type="textarea"
              />
            </Box>
          )}
          <FormErrors errors={errors as Record<string, FieldError>} />
        </Box>
      </FormModal>
    </div>
  );
};

export default observer(CareManagementSelectReasonModal);
