import { FC, MouseEventHandler, ReactNode, useState } from 'react';

import { css, styled, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { Dialogs } from 'analytics/events/dialog';
import classNames from 'classnames';

import {
  ClearIndicatorProps,
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupHeadingProps,
  GroupProps,
  InputProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  SingleValueProps,
  ValueContainerProps
} from 'react-select';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import Icon from 'components/Icons/Icon';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import { ISelectOption, SelectComponents } from 'components/UIkit/atoms/Dropdown/Select.shared';
import { Text } from 'components/UIkit/atoms/Text';
import { theme } from 'components/UIkit/theme';

import { DefaultMenuOption, useOnEditClick } from './ReactSelectOptionComponent';

const CustomGroupHeading: FC<GroupHeadingProps<any, boolean>> = (props) => {
  const { data, selectProps } = props;
  const onEditClick = useOnEditClick(props);
  const groupData = data as unknown as ISelectOption<any>;
  const isEditable = groupData.isEditable;
  const extendedSelectProps = selectProps as any;
  const getOptionActionText = extendedSelectProps.getOptionActionText;
  const customActionText =
    isEditable && getOptionActionText ? getOptionActionText(groupData) : 'EDIT';

  return (
    <StyledGroupHeading {...props}>
      <Typography variant={selectProps.variant === 'secondary' ? 'body2' : 'form-text'}>
        {props.children}
      </Typography>
      {groupData.isEditable && (
        <EditOptionButton
          onClick={onEditClick}
          customText={customActionText}
          variant={selectProps.variant}
        />
      )}
    </StyledGroupHeading>
  );
};

const StyledGroupHeading: FC<GroupHeadingProps<any, boolean>> = styled(components.GroupHeading, {
  shouldForwardProp: () => true // react-select "theme" object  should be passed down to react-select component
})((props: GroupHeadingProps<any, boolean>) => {
  // when styling react-select component - use muiTheme instead of react-select theme
  const { palette } = props.selectProps.muiTheme as Theme;
  const variant = props.selectProps.variant || 'primary';
  const editColor = variant === 'secondary' ? palette.secondary.dark : palette.primary.main;

  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    // for edit button - match exactly with BaseStyledMenuOption
    .menu-option-edit {
      cursor: pointer;
      color: ${editColor};
      &:hover {
        opacity: 0.8;
      }
    }
  `;
});

// Custom Group component that uses our custom GroupHeading
export const CustomGroup: FC<GroupProps<any, boolean>> = (props) => {
  return <components.Group {...props} Heading={CustomGroupHeading} />;
};

const Control: FC<ControlProps<any, boolean>> = ({ children, ...props }) => (
  <components.Control {...props}>
    <Typography
      variant="form-text"
      component="div"
      display="flex"
      flexGrow={1}
      width="100%"
      flexWrap="wrap"
    >
      {children}
    </Typography>
  </components.Control>
);

const Input: FC<InputProps<any, true>> = (props) => {
  return <components.Input {...props} />;
};

const DefaultMultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <Icon.XBase height={9} width={9} />
  </components.MultiValueRemove>
);

const DefaultClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <Icon.XBase height={9} width={9} />
  </components.ClearIndicator>
);

const DefaultDropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon.Chevron />
  </components.DropdownIndicator>
);

export const defaultSelectComponents: SelectComponents = {
  Option: DefaultMenuOption,
  Group: CustomGroup,
  Control,
  Input,
  IndicatorSeparator: (): null => null,
  DropdownIndicator: DefaultDropdownIndicator,
  ClearIndicator: DefaultClearIndicator,
  MultiValueRemove: DefaultMultiValueRemove
};

// TODO: changed to styledAPI
export const ValueContainer: FC<ValueContainerProps<any, boolean>> = ({ children, ...props }) => {
  const { isFocused, isDisabled, variant } = props.selectProps;
  const count = props.getValue().length;
  const childNodes = children as ReactNode[];
  const lastChild = childNodes[childNodes.length - 1];
  const classes = classNames({
    'flex-nowrap': count < 2
  });

  return isFocused || count < 2 ? (
    <components.ValueContainer {...props} className={classes}>
      {children}
    </components.ValueContainer>
  ) : (
    <>
      <span
        className={classNames('select-value-summary', {
          secondary: variant === 'secondary',
          disabled: isDisabled
        })}
      >{`${count} Selected`}</span>
      <components.ValueContainer {...props} className="empty-value-container">
        {lastChild}
      </components.ValueContainer>
    </>
  );
};

// === ticket type autocomplete components ===//
export const TicketTypeMultiValueLabel: FC<MultiValueGenericProps<TicketSubTypeOption>> = (
  props
) => {
  const label = props.data.labelFull ? props.data.labelFull : props.data.label;
  return <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>;
};

export const TicketTypeSingleValueLabel: FC<SingleValueProps<TicketSubTypeOption>> = (props) => {
  const label = props.data.labelFull ? props.data.labelFull : props.data.label;
  return <components.SingleValue {...props}>{label}</components.SingleValue>;
};

export const TicketTypeCustomControl: FC<ControlProps> = (props) => (
  // @ts-ignore
  <StyledControl {...props}>{props.children}</StyledControl>
);

const StyledControl = styled(components.Control, {
  shouldForwardProp: () => true // react-select "theme" object  should be passed down to react-select component
})((props: ControlProps) => {
  // when styling react-select component - use muiTheme instead of react-select theme
  const { borderRadius } = props.selectProps.muiTheme as Theme;

  return css`
    border-radius: ${borderRadius.large} ${borderRadius.large} 0 0;
  `;
});

export const TicketTypeMultiValueRemove: FC<MultiValueRemoveProps<TicketSubTypeOption>> = (
  props
) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const onRemoveClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (props.data.parentKind !== 'symptom') {
      setShowWarning(true);
      return;
    }

    const shouldShowWarning =
      props.selectProps.value &&
      ('parentKind' in props.selectProps.value ||
        (props.selectProps.value as TicketSubTypeOption[]).filter(
          (option: TicketSubTypeOption) => option.parentKind === 'symptom'
        ).length === 1);

    if (shouldShowWarning) {
      setShowWarning(true);
      return;
    }

    (props.innerProps.onClick as any)(event);
  };

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <MessageDialog
        id={props.selectProps.isSub ? Dialogs.DeleteTicketTypeSubSelect : Dialogs.DeleteTicketType}
        isOpen={showWarning}
        title={`Delete ${props.data.labelFull}?`}
        testHook={`remove-operator-${props.data.value}-message-dialog`}
        handleClose={() => setShowWarning(false)}
        primaryActionProps={{
          text: 'Delete',
          onClick: (event) => props.innerProps.onClick(event as any)
        }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setShowWarning(false) }}
      >
        Any details you have entered in the corresponding text field will be erased.
      </MessageDialog>

      <components.MultiValueRemove
        {...props}
        innerProps={{ ...props.innerProps, onClick: onRemoveClick }}
      >
        <components.CrossIcon />
      </components.MultiValueRemove>
    </div>
  );
};

// === to be removed ofter ticket type re-design ===//
const SingleValue = ({ children, ...props }: any) => {
  const { selectProps } = props;
  return (
    <components.SingleValue {...props}>
      <span className="text-bold">{selectProps.label}: </span>
      {children}
    </components.SingleValue>
  );
};

const LabelInPlaceholder = (props: any) => {
  const { selectProps } = props;
  return (
    <components.SingleValue {...props}>
      <span className="text-bold">{selectProps.label}: </span>
      {selectProps.placeholder}
    </components.SingleValue>
  );
};

export const InlineLabelComponents = {
  SingleValue,
  Placeholder: LabelInPlaceholder
};

interface MenuWithAddOptionProps<OptionType> extends MenuProps<OptionType, boolean> {
  selectProps: {
    addText?: string;
    onAddClick?: () => void;
  } & MenuProps<OptionType, boolean>['selectProps'];
}

export const MenuWithAddOption = <OptionType,>(props: MenuWithAddOptionProps<OptionType>) => {
  const { addText, onAddClick } = props.selectProps;

  return (
    <div>
      <components.Menu {...props}>
        {props.children}
        {addText && onAddClick && (
          <AddOptionFooter
            addText={addText}
            onAddClick={onAddClick}
            selectProps={props.selectProps}
          />
        )}
      </components.Menu>
    </div>
  );
};

export const AddOptionFooter = ({
  addText,
  onAddClick,
  selectProps
}: {
  addText: string;
  onAddClick: () => void;
  selectProps?: any;
}) => {
  const sizeProps = selectProps.variant === 'secondary' ? { height: 12, width: 12 } : {};

  return (
    <StyledAddOptionFooterBox
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onAddClick();
        if (selectProps && selectProps.menuIsOpen) {
          selectProps.onMenuClose?.();
        }
      }}
      data-test-hook="select-add-option"
    >
      <StyledAddContainer p={12} variant={selectProps?.variant}>
        <Icon.CirclePlus {...sizeProps} />
        <StyledText
          variant={selectProps?.variant === 'secondary' ? 'body2' : 'form-text'}
          fontColor={
            selectProps?.variant === 'secondary'
              ? theme.palette.secondary.dark
              : theme.palette.primary.main
          }
        >{`Add ${addText}`}</StyledText>
      </StyledAddContainer>
    </StyledAddOptionFooterBox>
  );
};

interface StyledAddOptionProps {
  variant?: 'primary' | 'secondary';
}

const StyledAddOptionFooterBox = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    border-top: 1px solid ${theme.palette.natural.border};
    cursor: pointer;
    overflow: hidden;
    border-bottom-left-radius: ${theme.borderRadius.small};
    border-bottom-right-radius: ${theme.borderRadius.small};

    &:hover {
      background-color: ${theme.palette.natural.hover};
    }
  `
);

interface AddContainerProps {
  fontColor: string;
}

const StyledAddContainer = styled(Box)<StyledAddOptionProps>(
  ({ theme, variant }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(4)};
    color: ${theme.palette.text.primary};

    svg {
      color: ${variant === 'secondary' ? theme.palette.secondary.dark : theme.palette.primary.main};
    }
  `
);

const StyledText = styled(Text)<AddContainerProps>(
  ({ fontColor }) => css`
    color: ${fontColor};
  `
);

export const EditOptionButton = ({
  onClick,
  customText = 'EDIT',
  variant = 'primary'
}: {
  onClick: (e: React.MouseEvent) => void;
  customText?: string;
  variant?: 'primary' | 'secondary';
}) => {
  return (
    <span
      className="menu-option-edit"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <Typography variant={variant === 'secondary' ? 'subtext' : 'form-text'}>
        {customText}
      </Typography>
    </span>
  );
};
