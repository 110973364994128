import { GetLightweightPathwaysResponse } from 'fetchers/responses/pathways.response';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { NON_SYMPTOM_PATHWAY_ID_PREFIX } from 'components/LightweightPathway/LightweightPathway.constants';

const httpPathwayService = new HttpService('pathway', true);

export class PathwaysFetcher {
  static async getLightweightPathways(params: {
    symptomIds?: number[];
    ticketTypeIds?: number[];
  }): Promise<GetLightweightPathwaysResponse> {
    return await httpPathwayService.get<GetLightweightPathwaysResponse>({
      url: API_URLS.LIGHTWEIGHT_PATHWAY,
      networkLabel: 'lightweight-pathways',
      query: params,
      transformResponse: (response: GetLightweightPathwaysResponse) => {
        // nonSymptomPathways has no id, so we add type-based
        response.nonSymptomPathways.forEach((pathway) => {
          pathway.id = `${NON_SYMPTOM_PATHWAY_ID_PREFIX}${pathway.typeId!.toString()}`;
        });
        return response;
      }
    });
  }
}

export default PathwaysFetcher;
