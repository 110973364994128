import { TicketClinicianMessageType } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketClinicianMessage/TicketClinicianMessage.types';
import { TicketPatientMessageType } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketPatientMessage/TicketPatientMessage.types';

export enum TicketMessageType {
  Patient = 'patient',
  Clinician = 'clinician',
  Contact = 'callback_contact'
}

export interface BaseTicketMessage {
  text: string;
  id: number;
  senderName: string;
  createdAt: Date;
  senderType: TicketMessageType;
}

export type TicketMessage = TicketPatientMessageType | TicketClinicianMessageType;
