import { FC } from 'react';

import { isEmpty } from 'lodash/fp';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { formatDate } from 'utils/DateUtils';

import { getMissedDosesReasonsText } from 'utils/OralOncoUtils';

import { OralReportV2, OralQuestionTypeV2 } from 'models/OralReport/OralReportV2';

import {
  noInfoProvidedText,
  OralReportSummaryProps
} from 'components/Ticket/TicketRow/OralReport/OralReport.shared';

import { OralReportCycleV2 } from 'components/Ticket/TicketRow/OralReport/OralReportCycleV2';
import { OralReportRow } from 'components/Ticket/TicketRow/OralReport/OralReportRow';

export const OralReportSummaryV2: FC<OralReportSummaryProps<OralReportV2>> = observer(
  ({ reportId, oral, patient, isCallLoggerVersion }) => {
    const { constantsStore } = useStores();

    if (
      oral[OralQuestionTypeV2.ActiveMedication]?.isAnswered &&
      !oral[OralQuestionTypeV2.ActiveMedication].isOk
    ) {
      return (
        <OralReportRow isCallLoggerVersion={isCallLoggerVersion} title="Active Medication">
          No
        </OralReportRow>
      );
    }

    const {
      regimenAdherence,
      otherMedicationChange,
      startOfCycle,
      missedDoses,
      refillRequest,
      dosesRemaining,
      previousReportDate
    } = oral;

    const missedDosesReasonsText = getMissedDosesReasonsText(
      missedDoses!,
      constantsStore.missedDosesReasonsMap
    );

    return (
      <>
        {startOfCycle?.isAnsweredOrDontKnow && (
          <OralReportRow isCallLoggerVersion={isCallLoggerVersion} title="Start of Cycle">
            <OralReportCycleV2 reportId={reportId} oral={oral} patient={patient} />
          </OralReportRow>
        )}

        {((missedDoses?.isAnswered && !missedDoses.isOk) || missedDoses?.isIDontKnow) && (
          <OralReportRow isCallLoggerVersion={isCallLoggerVersion} title="Missed Doses">
            {missedDoses.isIDontKnow ? (
              'Unknown'
            ) : (
              <>
                {missedDoses.value}
                {` Since ${
                  previousReportDate
                    ? formatDate(previousReportDate, 'MMMM Do, YYYY')
                    : 'Start of Cycle'
                }`}
                {!isEmpty(missedDosesReasonsText) ? ` - ${missedDosesReasonsText}` : ''}
              </>
            )}
          </OralReportRow>
        )}

        {regimenAdherence?.isAnswered && !regimenAdherence.isOk && (
          <OralReportRow isCallLoggerVersion={isCallLoggerVersion} title="Regimen Change">
            Yes (
            {regimenAdherence.customMessage ? regimenAdherence.customMessage : noInfoProvidedText})
          </OralReportRow>
        )}

        {otherMedicationChange?.isAnswered && !otherMedicationChange.isOk && (
          <OralReportRow isCallLoggerVersion={isCallLoggerVersion} title="Other Medication Change">
            Yes (
            {otherMedicationChange.customMessage
              ? otherMedicationChange.customMessage
              : noInfoProvidedText}
            )
          </OralReportRow>
        )}

        {dosesRemaining?.isAnswered && !dosesRemaining.isOk && (
          <OralReportRow isCallLoggerVersion={isCallLoggerVersion} title="Doses Remaining">
            {constantsStore.getRemainingDoseOptionById(dosesRemaining.value!).text}
          </OralReportRow>
        )}

        {refillRequest?.isAnswered && !refillRequest.isOk && (
          <OralReportRow isCallLoggerVersion={isCallLoggerVersion} title="Refill Request">
            {refillRequest.value ? 'Yes' : 'No'}
            {refillRequest.customMessage && ` (${refillRequest.customMessage})`}
          </OralReportRow>
        )}
      </>
    );
  }
);
