import { FC } from 'react';

import { isNil } from 'lodash/fp';

import { OralReportVersion } from 'models/OralReport/OralReport.shared';

import { OralReportV1 } from 'models/OralReport/OralReportV1';
import { OralReportV2 } from 'models/OralReport/OralReportV2';
import Patient from 'models/Patient';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import DistressCausesReport from 'components/Ticket/TicketRow/DistressCausesReport';
import { OralReportSummaryV1 } from 'components/Ticket/TicketRow/OralReport/OralReportSummaryV1';
import { OralReportSummaryV2 } from 'components/Ticket/TicketRow/OralReport/OralReportSummaryV2';

interface Props {
  report: QuestionnaireAnswer;
  patient: Patient;
  isCallLoggerVersion?: boolean;
}

const OralReportSummary: FC<Props> = ({ report, patient, isCallLoggerVersion }) => {
  const { oral, version } = report.answer;
  const normalizeVersion = !isNil(version) ? version : OralReportVersion.V1;

  return (
    <>
      <DistressCausesReport
        report={report}
        patient={patient}
        isCallLoggerVersion={isCallLoggerVersion}
      />

      {normalizeVersion === OralReportVersion.V1 && oral && (
        <OralReportSummaryV1
          reportId={report.id}
          oral={oral as OralReportV1}
          patient={patient}
          isCallLoggerVersion={isCallLoggerVersion}
        />
      )}

      {normalizeVersion === OralReportVersion.V2 && oral && (
        <OralReportSummaryV2
          reportId={report.id}
          oral={oral as OralReportV2}
          patient={patient}
          isCallLoggerVersion={isCallLoggerVersion}
        />
      )}
    </>
  );
};

export default OralReportSummary;
