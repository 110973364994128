import React, { FC } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  onCancelClicked: () => void;
  names: string[];
}

const MultipleLandlineSmsPopup: FC<Props> = ({ isOpen, onCancelClicked, names }) => (
  <MessageDialog
    id={Dialogs.MultipleLandlineSms}
    title="Cannot Send SMS to Landline Phone"
    isOpen={isOpen}
    handleClose={onCancelClicked}
    primaryActionProps={{ text: 'OK', onClick: onCancelClicked }}
  >
    Please replace the following contacts’ phone with a mobile number:
    {names.map((name) => (
      <div key={name}>&bull; {name}</div>
    ))}
  </MessageDialog>
);

export default MultipleLandlineSmsPopup;
