import { ErrorName, transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { CommunicationType } from 'models/Call';

import { ProtocolType } from 'models/ScheduledProtocol';

export interface ReportRequestDetails {
  patientId: number;
  message: string;
  reportType: ProtocolType;
  reportName: string;
}

export interface ReportRequestResponse {
  successIds?: number[];
  blockedIds?: number[];
}

export interface SendHomeCareInstructionsResponse {
  blockedSmsContacts: number[];
  patientBlockedSms: boolean;
  ids: number[];
  patientLandline: boolean;
  landlinePhoneContacts: number[];
}

export interface HomeCareInstructionsRecipient {
  contactId?: number;
  communicationTypes: CommunicationType[];
}

export interface SendHomeCareInstructions {
  text: string;
  recipients: HomeCareInstructionsRecipient[];
  callId?: number;
}

export interface SendMessageToPatientBody {
  ticketId: number;
  message: string;
}

export interface MarkTicketMessagesAsSeenBody {
  ids: number[];
}

const httpService = new HttpService('patient communication');

export class PatientCommunicationFetcher {
  static async sendReportRequest(requestReportDetails: ReportRequestDetails) {
    return await httpService.post({
      url: API_URLS.REQUEST_REPORT,
      data: { requestReportDetails },
      transformResponse: (response: ReportRequestResponse) => ({
        successIds: response.successIds || [],
        blockedIds: response.blockedIds || []
      }),
      transformError: (error) => {
        error.ui.title = 'Failed to send sms to patient';
        return error;
      }
    });
  }

  static async snoozePatient(patientId: number) {
    return await httpService.post({
      url: API_URLS.SNOOZE_PATIENT,
      data: { patientId },
      transformError: transformErrorUiProps('Failed to send sms to patient')
    });
  }

  static async sendSmsToPatient(
    patientId: number,
    messageData: { message: string; contactId?: number }
  ) {
    return await httpService.post({
      url: API_URLS.SEND_SMS_TO_PATIENT(patientId),
      data: messageData,
      transformError: (error) => {
        if (error.name === ErrorName.UserBlockSendingSms) {
          error.ui.title = 'This Number Has Blocked SMS from Canopy';
          return error;
        }

        error.ui.title = 'Failed to send sms to patient';
        return error;
      }
    });
  }

  static async sendHomeCareInstructions(
    patientId: number,
    homeCareInstructions: SendHomeCareInstructions
  ): Promise<SendHomeCareInstructionsResponse> {
    return await httpService.post({
      url: API_URLS.SEND_HOMECARE_INSTRUCTIONS(patientId),
      data: homeCareInstructions
    });
  }

  static async updateMessagingConsent(patientId: number, messagingConsent: boolean) {
    return await httpService.patch({
      url: API_URLS.UPDATE_MESSAGING_CONSENT(patientId),
      data: { messagingConsent },
      transformError: transformErrorUiProps('Failed to update messaging consent')
    });
  }
}
