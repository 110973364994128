import { FC, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getDateSorter } from 'utils/DateUtils';
import { pluralize } from 'utils/StringUtils';

import OperatorTicket from 'models/OperatorTicket';
import Patient from 'models/Patient';
import Ticket, { TicketClass } from 'models/Ticket';

import ExpandableRow from 'views/Widgets/ExpandableRow';

import { CallbackTicketSummary } from 'components/Ticket/TicketRow/CallbackTicketSummary';
import { OperatorTicketSummary } from 'components/Ticket/TicketRow/OperatorTicketSummary';
import SymptomOperatorReportSummary from 'components/Ticket/TicketRow/SymptomOperatorReportSummary';
import SymptomReportSummary from 'components/Ticket/TicketRow/SymptomReportSummary';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  ticketIds: number[];
  patient: Patient;
}

export const CallConnectedTicketsSection: FC<Props> = observer(({ ticketIds, patient }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { ticketTypesStore, ticketsStore } = useStores();
  const getTicketSummary = (ticket: Ticket | undefined) => {
    if (!ticket) {
      return;
    }
    switch (ticket.class) {
      case TicketClass.patient:
        return patient.reportsSortedBySeverity.map((report, index) => {
          return index === 0 ? (
            <Box mb={12}>
              <SymptomReportSummary
                key={report.id}
                ticket={ticket}
                report={report}
                patient={patient}
                isCallLoggerVersion
              />
            </Box>
          ) : (
            <Box display="flex" flexDirection="row" alignItems="stretch">
              <Box width={4} flexShrink={0} bgcolor="grey.300" mr={20} />
              <Box mt={index === 1 ? 0 : 12}>
                <SymptomReportSummary
                  key={report.id}
                  ticket={ticket}
                  report={report}
                  patient={patient}
                  isCallLoggerVersion
                />
              </Box>
            </Box>
          );
        });
      case TicketClass.operator:
        if (ticket.isSymptomOperatorTicket) {
          return (
            <SymptomOperatorReportSummary
              ticket={ticket}
              patient={patient}
              isExpanded
              isCallLoggerVersion
            />
          );
        }
        const operatorTicket = ticket.operatorTicket as OperatorTicket;
        const [subTypeId] = operatorTicket.subTicketTypeIds;
        const subTypeNode = ticketTypesStore.getOperatorTicketTypeById(subTypeId);
        return (
          <OperatorTicketSummary
            ticket={ticket}
            descriptionContainerOptions={{}}
            subTypeNode={subTypeNode}
            isExpanded={isExpanded}
            isCallLoggerVersion
          />
        );
      case TicketClass.callbackRequest:
        return <CallbackTicketSummary ticket={ticket} patient={patient} isCallLoggerVersion />;
      case TicketClass.task:
      default:
        throw new Error(`Unsupported ticket class: ${ticket.class}`);
    }
  };
  const tickets = ticketIds
    .map((id) => ticketsStore.ticketsMap.get(id))
    .sort(getDateSorter('createdAt', false));
  return (
    <ExpandableRow
      isExpanded={isExpanded}
      title={
        <Box>
          <Text variant="h4">{pluralize('Connected Ticket', ticketIds.length)}</Text>
          <Text variant="body1" color="secondary">{` — ${ticketIds.length} ${pluralize(
            'Ticket',
            ticketIds.length
          )}`}</Text>
        </Box>
      }
      onToggleExpand={() => {
        trackSectionUsageAnalyticsEvent({
          action: isExpanded ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
          value: 'Connected Tickets',
          virtual_page: 'call logger'
        });

        setIsExpanded(!isExpanded);
      }}
    >
      <div>
        {tickets.map((ticket, index) => (
          <div key={ticket?.id}>
            {index > 0 && (
              <Box height={24} alignContent="center">
                <Divider />
              </Box>
            )}
            {getTicketSummary(ticket)}
          </div>
        ))}
      </div>
    </ExpandableRow>
  );
});
