import { FunctionComponent } from 'react';

import { Typography } from '@mui/material';
import { isEmpty } from 'lodash/fp';
import { observer } from 'mobx-react';

import ReactDOMServer from 'react-dom/server';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import OperatorTicket, { OperatorTicketSource } from 'models/OperatorTicket';

import Ticket from 'models/Ticket';

import { TicketTypeNode } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeNode';

import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';

import {
  OPERATOR_TICKETS_DESCRIPTION_MAX_LINES,
  useTicketProviderLocation
} from 'components/Ticket/TicketRow/shared';
import { Text } from 'components/UIkit/atoms/Text';

import { TextProps } from 'components/UIkit/atoms/Text/Text';

import { TicketPathwaysAnswers } from './TicketPathwaysAnswers';

interface Props {
  ticket: Ticket;
  descriptionContainerOptions: { maxNumberOfLines?: number };
  isExpanded: boolean;
  descriptionRef?: (node: HTMLElement) => void;
  subTypeNode?: TicketTypeNode;
  isCallLoggerVersion?: boolean;
}

export const OperatorTicketSummary: FunctionComponent<Props> = observer(
  ({
    ticket,
    subTypeNode,
    descriptionContainerOptions,
    isExpanded,
    descriptionRef,
    isCallLoggerVersion
  }) => {
    const { settingsStore } = useStores();

    const providerLocationText = useTicketProviderLocation(ticket);
    const operatorTicket = ticket.operatorTicket as OperatorTicket;

    const descriptionContainerOptionalProps: {} | Pick<TextProps, 'maxNumberOfLines'> =
      isExpanded || isCallLoggerVersion
        ? {}
        : { maxNumberOfLines: OPERATOR_TICKETS_DESCRIPTION_MAX_LINES };

    const getTitleText = () => (
      <div>
        <Text variant={isCallLoggerVersion ? 'body1' : 'h4'}>{subTypeNode?.fullName}</Text>
        {providerLocationText && (
          <Text variant={isCallLoggerVersion ? 'body2' : 'form-text'} color="secondary">
            {' '}
            — {providerLocationText}
          </Text>
        )}
      </div>
    );

    const hasPatientOperatorTicketSummaryPrefix =
      operatorTicket.source === OperatorTicketSource.Patient && Boolean(operatorTicket.notes);

    const isLightweightPathwaysQuestionsAnswersVisible =
      settingsStore.hasFeature(FEATURES.LIGHTWEIGHT_PATHWAYS) &&
      !isEmpty(ticket.operatorTicket?.pathwayIds);

    return (
      <>
        {getTitleText()}

        <Text
          variant="body2"
          {...descriptionContainerOptions}
          component="div"
          ref={descriptionRef}
          wordBreak="break-word"
        >
          {hasPatientOperatorTicketSummaryPrefix && (
            <Typography variant="body1">Patient Request via App:</Typography>
          )}{' '}
          <HtmlContent>{operatorTicket.notes}</HtmlContent>
        </Text>

        <Text
          variant="body2"
          {...descriptionContainerOptionalProps}
          component="div"
          ref={descriptionRef}
        >
          {isLightweightPathwaysQuestionsAnswersVisible && !isCallLoggerVersion && (
            <HtmlContent>
              {ReactDOMServer.renderToStaticMarkup(
                <TicketPathwaysAnswers ticket={ticket} isExpanded={isExpanded} />
              )}
            </HtmlContent>
          )}
        </Text>
      </>
    );
  }
);
