import { FC } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { formatDate } from 'utils/DateUtils';

import { RelationshipToPatientEnum } from 'models/Patient';

import { TicketMessageAvatar } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessageAvatar';

import { TicketPatientMessageType } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketPatientMessage/TicketPatientMessage.types';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  message: TicketPatientMessageType;
}

export const TicketPatientMessage: FC<Props> = ({ message }) => (
  <Box display="flex" flexDirection="column" gap={8}>
    <Box display="flex" alignItems="center" gap={8}>
      {!message.isSeen && <StyledMessageUnseenIndicator />}

      <TicketMessageAvatar senderName={message.senderName} senderType={message.senderType} />

      <Text>
        {message.senderName}{' '}
        {message.relationship !== RelationshipToPatientEnum.SELF && `(${message.relationship})`}{' '}
        <Text color="secondary">{formatDate(message.createdAt, 'MM/DD/YYYY, h:mm A')}</Text>
      </Text>
    </Box>

    <Text variant="body2">{message.text}</Text>
  </Box>
);

const StyledMessageUnseenIndicator = styled(Box)(
  ({ theme }) => css`
    width: 10px;
    height: 10px;
    background-color: ${theme.palette.tag.tag8};
    border-radius: ${theme.borderRadius.full};
    flex-shrink: 0;
  `
);
