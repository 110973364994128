import { AdvanceSearchValue, BasicSearchValue, SearchBaseAction } from './search-events.shared';

import { AnalyticsService, AnalyticEventName } from '../';

export type SearchHeaderUsageValue =
  | BasicSearchValue
  | AdvanceSearchValue
  | 'recently viewed'
  | 'search result';

export type SearchHeaderUsageMetric =
  | 'MRN'
  | 'NAME'
  | 'DOB'
  | 'NAME and DOB'
  | 'NAME and MRN'
  | 'DOB and MRN'
  | 'NAME and DOB and MRN';

interface Properties {
  action: SearchBaseAction;
  type?: 'regular search' | 'advanced search';
  value?: SearchHeaderUsageValue;
  source?: 'keyboard' | null;
}

export const trackSearchHeaderUsageAnalyticsEvent = ({ action, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SearchHeaderUsage,
    properties: { action, ...optionalProps }
  });
};
