// @ts-strict-ignore
import { observer } from 'mobx-react';
import { ticketSelectors } from 'tests/models/components/ticket/ticket.selectors';

import { TicketType } from 'tests/models/components/ticket/ticket.types';

import { useTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';
import { useTicketCalls } from 'mobx/hooks/useTicketCalls';

import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import Ticket from 'models/Ticket';

import CardStack from 'views/Widgets/CardStack';

import { TicketPatientCallsList } from 'views/Widgets/PatientCallsList';

import { TicketSectionId } from 'views/WorkQueue/WorkQueue.types';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { getSymptomIconAndAlertType } from 'components/Ticket/TicketRow/shared';
import { TicketCommunicationSection } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketCommunicationSection';
import TicketRowIcon from 'components/Ticket/TicketRow/TicketRowIcon';
import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';

import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';

import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

import SymptomReportSummary from './SymptomReportSummary';
import TicketCreatedBy from './TicketCreatedBy';

import TicketRenderer from './TicketRenderer';

import './TicketView.scss';

interface TicketReportRowProps {
  ticket: Ticket;
  className?: string;
  reports: QuestionnaireAnswer[];
  withPatientLink?: boolean;
  ticketRefs?: (node: HTMLElement, ticketId: number) => void;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
  ticketSectionId: TicketSectionId;
}

const TicketReportRow = ({
  ticket,
  className,
  reports,
  withPatientLink,
  ticketRefs,
  ticketIndex,
  ticketSectionCurrentPage = 0,
  ticketSectionId
}: TicketReportRowProps) => {
  const { ticketsStore } = useStores();
  const { ticketActions: Actions, tab, hideActionButtons } = useTicketOverviewContext();
  const { isExpanded, toggle } = useTicketToggle(
    ticket.id,
    false,
    ticketSectionCurrentPage,
    false,
    ticket.messagesCount > 0,
    tab
  );

  const isActionInProgress = useTicketNetworkLoading(ticket.id);
  const patient = usePatientModel(ticket.patientId);

  const { calls, isLoadingCalls } = useTicketCalls(ticket, isExpanded);

  const ticketSelected = ticketsStore.ticketsBulkActionSet.has(ticket.id);

  const renderExpandText = (index: number) => {
    if (index === 0) {
      return (
        <TicketSummaryButton
          onClick={toggle}
          isExpanded={isExpanded}
          items={{
            draft: ticket.draftCallCount,
            comment: ticket.commentCount,
            call: ticket.callCount,
            callAttempt: ticket.callAttemptsCount,
            report: reports ? reports.length - 1 : 0
          }}
        />
      );
    }
  };

  const protocol = patient.mainScheduledProtocol;

  let numberOfStackedItems: number = 0; // we can have calls and reports rows

  if (ticket.callsAndDraftsCount > 0) {
    numberOfStackedItems = ticket.callsAndDraftsCount;
  }

  if (reports?.length) {
    numberOfStackedItems += reports.length - 1;
  }

  return (
    <CardStack
      isSelected={ticketSelected}
      isOpen={isExpanded}
      classes={{
        root: className,
        closed: 'report-row-closed'
      }}
      numberOfStackedItems={numberOfStackedItems}
    >
      {reports.map((report, index) => {
        const { urgency } = report;
        const isFirst = index === 0;
        const iconType = getSymptomIconAndAlertType(urgency);
        return (
          <TicketRenderer
            testHook={ticketSelectors.container(ticket.id, TicketType.Patient, ticketSectionId)}
            ticketRefsCallback={ticketRefs}
            ticketId={ticket.id}
            key={report.id}
            isLoading={isActionInProgress}
            icon={<TicketRowIcon type={iconType} />}
            classes={{
              container: 'no-border',
              body: 'wide-summary'
            }}
            actions={
              isFirst ? (
                <Actions
                  ticket={ticket}
                  ticketIndex={ticketIndex}
                  ticketSectionCurrentPage={ticketSectionCurrentPage}
                />
              ) : null
            }
            patient={patient}
            withPatientLink={withPatientLink}
            summary={
              <SymptomReportSummary ticket={ticket} report={report} patient={patient}>
                {renderExpandText(index)}
              </SymptomReportSummary>
            }
            createdByText={<TicketCreatedBy ticket={ticket} report={report} protocol={protocol} />}
          >
            {isExpanded && isFirst && (
              <TicketCommunicationSection
                ticket={ticket}
                ticketIndex={ticketIndex}
                ticketSectionCurrentPage={ticketSectionCurrentPage}
              />
            )}
          </TicketRenderer>
        );
      })}
      {TicketPatientCallsList({
        calls,
        patient,
        isLoadingCalls,
        ticket,
        hideActionButtons
      })}
    </CardStack>
  );
};

export default observer(TicketReportRow);
