import { FC } from 'react';

import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { Testable } from 'utils/TypeUtils';

import { RHFStyledInput } from 'views/Widgets/StyledInput';

interface Props extends Testable {
  name: string;
  error?: any;
  label?: string;
  validate?: (value: any) => boolean | string;
  min?: string;
  max?: string;
  isRequired?: boolean;
  disabled?: boolean;
}

const FormDateField: FC<Props> = ({
  name,
  error,
  label,
  min,
  max,
  validate,
  isRequired,
  disabled,
  testHook
}) => {
  const { register } = useFormContext();

  return (
    <RHFStyledInput
      type="date"
      isRequired={isRequired}
      name={name}
      error={Boolean(error)}
      register={register}
      min={min}
      max={max}
      validate={validate}
      label={label}
      disabled={disabled}
      testHook={testHook}
    />
  );
};

export default observer(FormDateField);
