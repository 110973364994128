// @ts-strict-ignore
import { FunctionComponent } from 'react';

import { isEmpty } from 'lodash/fp';
import { observer } from 'mobx-react';

import ReactDOMServer from 'react-dom/server';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import OperatorTicket from 'models/OperatorTicket';
import Patient from 'models/Patient';

import Ticket from 'models/Ticket';

import { TagPrefix } from 'components/Ticket/TicketRow/AlertTag';
import AlertTags from 'components/Ticket/TicketRow/AlertTags';
import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';
import {
  OPERATOR_TICKETS_DESCRIPTION_MAX_LINES,
  useTicketProviderLocation
} from 'components/Ticket/TicketRow/shared';

import { TicketPathwaysAnswers } from 'components/Ticket/TicketRow/TicketPathwaysAnswers';
import { Text } from 'components/UIkit/atoms/Text';
import { TextProps } from 'components/UIkit/atoms/Text/Text';

interface Props {
  ticket: Ticket;
  patient: Patient;
  isExpanded: boolean;
  descriptionRef?: (node: HTMLElement) => void;
  isCallLoggerVersion?: boolean;
}

const SymptomOperatorReportSummary: FunctionComponent<Props> = ({
  ticket,
  patient,
  isExpanded,
  descriptionRef,
  isCallLoggerVersion
}) => {
  const { ticketTypesStore, alertsStore, settingsStore } = useStores();
  const providerLocationText = useTicketProviderLocation(ticket);
  const descriptionContainerOptionalProps: {} | Pick<TextProps, 'maxNumberOfLines'> =
    isExpanded || isCallLoggerVersion
      ? {}
      : { maxNumberOfLines: OPERATOR_TICKETS_DESCRIPTION_MAX_LINES };
  const operatorTicket = ticket.operatorTicket as OperatorTicket;
  const getTitleText = () => {
    return (
      <div>
        <Text variant={isCallLoggerVersion ? 'body1' : 'h4'}>Symptom Report via Triage Call</Text>
        {providerLocationText && (
          <Text variant={isCallLoggerVersion ? 'body2' : 'form-text'} color="secondary">
            {' '}
            — {providerLocationText}
          </Text>
        )}
      </div>
    );
  };

  const getDescriptionText = () => {
    const { subTypes } = operatorTicket.getOperatorTicketTypes;

    const isLightweightPathwaysQuestionsAnswersVisible =
      settingsStore.hasFeature(FEATURES.LIGHTWEIGHT_PATHWAYS) &&
      !isEmpty(ticket.operatorTicket?.pathwayIds);

    return (
      <>
        {subTypes.map((subType, index) => {
          const ticketType = ticketTypesStore.getTicketType(subType);
          const dxTags = alertsStore
            .getDxAlertDescriptionsForSymptom(ticketType.id, patient.dxCodeIds)
            .map((text) => ({ prefix: TagPrefix.Dx, text }));
          const txTags = alertsStore
            .getRelevantTxAlertsForSymptom(ticketType.id, patient)
            .map((text) => ({ prefix: TagPrefix.Tx, text }));

          return (
            <span key={subType} className="d-inline-block">
              <Text variant={isCallLoggerVersion ? 'body2' : 'body1'}>{ticketType?.name}</Text>
              <span className="d-inline-block">
                <AlertTags tags={[...dxTags, ...txTags]} />
              </span>
              {index < subTypes.length - 1 && <>,&nbsp;</>}
            </span>
          );
        })}

        {operatorTicket.notes && (
          <Text wordBreak="break-word" variant="body2">
            {' '}
            - <HtmlContent inline>{operatorTicket.notes}</HtmlContent>
          </Text>
        )}

        <Text
          variant="body2"
          {...descriptionContainerOptionalProps}
          component="div"
          ref={descriptionRef}
        >
          {isLightweightPathwaysQuestionsAnswersVisible && !isCallLoggerVersion && (
            <HtmlContent>
              {ReactDOMServer.renderToStaticMarkup(
                <TicketPathwaysAnswers ticket={ticket} isExpanded={isExpanded} />
              )}
            </HtmlContent>
          )}
        </Text>
      </>
    );
  };

  return (
    <>
      <div>{getTitleText()}</div>

      <div>{getDescriptionText()}</div>
    </>
  );
};

export default observer(SymptomOperatorReportSummary);
