import { CommunicationType } from 'models/Call';
import { SelectOption } from 'models/SelectOption';

export enum ContactType {
  Patient = 'patient',
  Contact = 'contact'
}

export interface ContactOption extends SelectOption<string> {
  isEditable?: boolean;
  communicationType: CommunicationType;
  contactId?: number;
  actualValue?: string;
}

export interface ContactGroupOption extends SelectOption<number> {
  relationship?: string;
  options: ContactOption[];
  contactType: ContactType;
  isEditable?: boolean;
}

export const CommunicationTypeToLabelMap = {
  [CommunicationType.Email]: 'Email',
  [CommunicationType.Sms]: 'SMS'
};
