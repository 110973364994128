// @ts-strict-ignore
import { computed, observable, makeObservable } from 'mobx';

import { UserType } from 'utils/AccountUtils';

import Department from './Department';
import UserModel from './UserModel';

export enum ClinicianUserStatus {
  ACTIVE = 'active',
  INVITED = 'invited',
  INACTIVE = 'inactive'
}

export enum FeatureIntroCodes {
  CareTimer = 'CareTimer',
  EditCareIntervals = 'EditCareIntervals',
  CallLoggerSmartSummary = 'CallLoggerSmartSummary',
  AdvancedSearch = 'AdvancedSearch',
  ToggleRadioButton = 'ToggleRadioButton',
  ResolveTicketWithMessage = 'ResolveTicketWithMessage'
}

export default class Doctor extends UserModel {
  @observable id: number;

  @observable credentialId: number;

  @observable role: UserType;

  @observable departments: Department[];

  @observable departmentIds: number[];

  @observable userStatus: ClinicianUserStatus;

  @observable createdAt: Date;

  @observable featureIntros?: FeatureIntroCodes[];

  constructor(user: UserModel) {
    super(
      user.userId,
      user.firstName,
      user.lastName,
      user.email,
      user.gender,
      user.picture,
      user.phone
    );
    makeObservable(this);
  }

  static getDoctorFromJson(doctorJson: any): Doctor {
    const userModel = UserModel.getUserFromJson(doctorJson);
    const doctor = new Doctor(userModel);
    doctor.id = doctorJson.id;
    doctor.credentialId = doctorJson.credentialId;
    doctor.role = doctorJson.role;
    doctor.departmentIds = doctorJson.departmentIds;
    doctor.createdAt = doctorJson.createdAt;
    doctor.userStatus = doctorJson.userStatus;
    doctor.featureIntros = doctorJson.featureIntros || [];

    return doctor;
  }
  @computed
  get isAdmin() {
    return this.role === UserType.ADMIN;
  }

  @computed
  get isActive(): boolean {
    return this.userStatus === ClinicianUserStatus.ACTIVE;
  }

  hasFeature(featureIntroCode: FeatureIntroCodes) {
    return this.featureIntros?.includes(featureIntroCode);
  }
}
