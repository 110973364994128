// @ts-strict-ignore

import { Component } from 'react';

import { AnalyticEventAction } from 'analytics';
import { Dialogs } from 'analytics/events/dialog';
import { trackEPROTimeBucketsAnalyticsEvent } from 'analytics/events/epro-time-buckets';
import { trackTabNavigationAnalyticsEvent } from 'analytics/events/tab-navigation';
import copy from 'copy-html-to-clipboard';
import { ErrorName } from 'errors';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import IdleTimer from 'react-idle-timer';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';
import { PatientPageTabs } from 'tests/models/pages/patient-page/patient-page.types';

import {
  AlertsStore,
  CallLoggingStore,
  CallsStore,
  CareTimerStore,
  ConstantsStore,
  DepartmentStore,
  PathwayStore,
  PatientPageStore,
  ReasonsStore,
  SettingsStore,
  TasksStore,
  TicketFiltersStore,
  TicketsStore,
  UiStore,
  UserStore
} from 'mobx/stores';

import { getClinicianFullName, getNameWithCredentials } from 'utils/ClinicianCredentialUtils';

import { getPatientIdFromRoute } from 'utils/RouteUtils';
import { removeUrlParam } from 'utils/urlUtils';

import { clearAllToasts, showToast } from 'utils/UserMessageUtils';

import { FEATURES } from 'constants/features';

import Call, { IncludeSummaryInEmrNote } from 'models/Call';
import { ICallSaveOptions } from 'models/CallSaveOptions';
import { FeatureIntroCodes } from 'models/Doctor';
import Patient from 'models/Patient';
import Ticket from 'models/Ticket';
import { UserPreferences } from 'models/UserModel';

import { USER_PREFERENCES_LOCAL_STORAGE_KEY } from 'hooks/useUserPreferences';

import ConnectTicketsToCallEditModal from 'views/Modals/ConnectTicketsToCallEditModal';
import { FeatureIntroPopup } from 'views/Modals/FeatureIntroPopup';

import { EpisodeEditCreateProvider } from 'views/Pages/EpisodesManagement/episode-context';
import { PatientEpisodesAndTasks } from 'views/Pages/EpisodesManagement/PatientEpisodesAndTasks';
import { PatientDetailsCard } from 'views/Patient/PatientDetails/PatientDetailsCard';
import {
  HIGHLIGHT_TICKET_QUERY_PARAM,
  PATIENT_TABS,
  TAB_QUERY_PARAM
} from 'views/Patient/PatientMain/PatientMainView.constants';
import PatientTicketOverview from 'views/Patient/PatientMain/PatientTicketOverview';
import { CallLogging } from 'views/Widgets/CallLogging/CallLogging';
import CollapsibleSection from 'views/Widgets/CollapsibleSection';
import ToggleBar, { TabOption } from 'views/Widgets/ToggleBar';

import Loading from 'components/Loaders/Loading';
import { PatientContextProvider } from 'components/Patient/PatientContextProvider';
import TicketOverviewProvider, {
  ActionCallbacks
} from 'components/Ticket/TicketsContainers/TicketOverviewProvider';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import CallsTab from './CallsTab';

import ReportsTable from './ReportsTable/ReportsTable';
import ResolvedTickets from './ResolvedTickets';

import './PatientMainView.scss';

const DATA_LOST_WARNING_BEFORE_NAVIGATION =
  'This call and any notes taken will be lost if you navigate away from this page without submitting it.';

const DEFAULT_TABS: {
  label: 'Open Items' | 'Resolved Tickets' | 'Calls' | 'Episodes & Tasks';
  value: string;
  feature: FEATURES;
}[] = [
  { label: 'Open Items', value: PATIENT_TABS.OPEN_TICKETS, feature: FEATURES.OPEN_ITEMS_TAB },
  {
    label: 'Resolved Tickets',
    value: PATIENT_TABS.RESOLVED_TICKETS,
    feature: FEATURES.RESOLVED_TICKETS_TAB
  },
  { label: 'Calls', value: PATIENT_TABS.CALLS, feature: FEATURES.CALLS_TAB },
  {
    label: 'Episodes & Tasks',
    value: PATIENT_TABS.EPISODES_AND_TASKS,
    feature: FEATURES.EPISODE_AND_TASKS_TAB
  }
];

const VALID_TAB_VALUES = Object.values(PATIENT_TABS);

const BULK_ACTIONS_TABS = [PATIENT_TABS.OPEN_TICKETS, PATIENT_TABS.EPISODES_AND_TASKS];

const toggleOptions = [
  {
    label: '14 Days',
    value: 14
  },
  {
    label: '30 Days',
    value: 30
  },
  {
    label: 'All Reports',
    value: 0
  }
];

interface IParams {
  patientId: string;
  activeTab: string;
}

interface IPatientMainProps extends RouteComponentProps<IParams> {
  patientPageStore: PatientPageStore;
  constantsStore: ConstantsStore;
  pathwaysStore: PathwayStore;
  alertsStore: AlertsStore;
  departmentsStore: DepartmentStore;
  ticketsStore: TicketsStore;
  ticketFiltersStore: TicketFiltersStore;
  userStore: UserStore;
  uiStore: UiStore;
  callLoggingStore: CallLoggingStore;
  callsStore: CallsStore;
  reasonsStore: ReasonsStore;
  tasksStore: TasksStore;
  careTimerStore: CareTimerStore;
  settingsStore: SettingsStore;
}

interface IPatientMainState {
  dropdownOpen: boolean;
  isDuringPersonalRequestSend: boolean;
  isPersonalRequestModalOpen: boolean;
  firstPatientResponseReceived: boolean;
  callDraft: Call | null;
  symptomChartDaysToLoad: number;
  showCareTimerAutoPausedDialog: boolean;
  idleTimerSecondsLeft: number;
  hasServerError: boolean;
  reopeningTaskIds: Set<number>;
}

@inject('patientPageStore')
@inject('constantsStore')
@inject('pathwaysStore')
@inject('departmentsStore')
@inject('ticketsStore')
@inject('userStore')
@inject('alertsStore')
@inject('ticketFiltersStore')
@inject('uiStore')
@inject('callLoggingStore')
@inject('reasonsStore')
@inject('settingsStore')
@inject('callsStore')
@inject('tasksStore')
@inject('careTimerStore')
@observer
class PatientMainView extends Component<IPatientMainProps, IPatientMainState> {
  idleTimerRef: IdleTimer = null;

  idleTimerCountdown: any = null;

  mounted: boolean = false;

  isNavigatingAfterLocalPatientDeleted = false;

  defaultTabOptions: TabOption<string>[] = [];

  state: IPatientMainState = {
    dropdownOpen: false,
    isDuringPersonalRequestSend: false,
    isPersonalRequestModalOpen: false,
    firstPatientResponseReceived: false,
    callDraft: null,
    symptomChartDaysToLoad: 0,
    showCareTimerAutoPausedDialog: false,
    idleTimerSecondsLeft:
      this.props.settingsStore.institutionSettings.careTimerIdleWarningDurationMillis / 1000,
    hasServerError: false,
    reopeningTaskIds: new Set()
  };

  keepOnPage = (e: any) => {
    const message = DATA_LOST_WARNING_BEFORE_NAVIGATION;
    if (this.isCallLoggingOpen()) {
      e.returnValue = message;
      return message;
    }
    return e;
  };

  resumeCareIfNeeded = () => {
    if (this.props.careTimerStore.wasInterrupted) {
      const nameWithCredentials = this.getNameWithCredentialsForCurrent();
      this.props.careTimerStore.resumeSession({
        id: this.props.userStore.currentDoctor.id,
        name: nameWithCredentials
      });
    }
  };

  verifySelectTab() {
    if (!this.getSelectedTab()) {
      //the selected tab will be the first element in tabOptions
      this.setSelectedTab(this.defaultTabOptions[0].value);
    }
  }

  onVisibilityChanged = () => {
    if (!this.props.callLoggingStore.isTimerActive() && document.visibilityState === 'visible') {
      this.props.callLoggingStore.setIsGlobalCallLoggingTimerActive(false);
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.defaultTabOptions = this.getDefaultTabOptions();
    window.addEventListener('beforeunload', this.keepOnPage);
    window.addEventListener('visibilitychange', this.onVisibilityChanged);
    this.props.constantsStore.fetchCareManagementFilterData();
    this.props.reasonsStore.fetchQuickAdditionReasons();

    if (this.props.settingsStore.hasFeature(FEATURES.CARE_MANAGEMENT)) {
      this.props.reasonsStore.fetchCareManagementReasons();
    }
    this.initializePatientPage();
    this.props.callLoggingStore.setIsGlobalCallLoggingTimerActive(false);
    window.addEventListener('click', this.resumeCareIfNeeded);
    this.verifySelectTab();
  }

  async componentDidUpdate(prevProps: Readonly<IPatientMainProps>) {
    if (
      getPatientIdFromRoute(this.props.match.params) !==
      getPatientIdFromRoute(prevProps.match.params)
    ) {
      this.closeAndReset();
      this.props.ticketsStore.resetTicketsBulkActionSet();

      if (prevProps.match.params) {
        try {
          if (!this.isNavigatingAfterLocalPatientDeleted) {
            await this.props.careTimerStore.endSession();
          }
        } finally {
          this.isNavigatingAfterLocalPatientDeleted = false;
          if (this.mounted) {
            this.initializePatientPage();
          }
        }
      } else {
        this.isNavigatingAfterLocalPatientDeleted = false;
        this.initializePatientPage();
      }
    }

    this.verifySelectTab();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.defaultTabOptions = [];
    window.removeEventListener('beforeunload', this.keepOnPage);
    window.removeEventListener('click', this.resumeCareIfNeeded);
    window.removeEventListener('visibilitychange', this.onVisibilityChanged);
    this.props.patientPageStore.stopRefreshSinglePatient();
    this.props.callLoggingStore.reset();
    this.props.ticketsStore.clearData();
    this.props.uiStore.setIntro(false);
    if (this.props.careTimerStore.currentSession?.length && this.props.userStore.isAuthenticated) {
      this.props.careTimerStore.endSession();
    }

    this.props.careTimerStore.setInterrupted(false);
  }

  initializePatientPage = async () => {
    this.props.patientPageStore.stopRefreshSinglePatient();
    const patientId = getPatientIdFromRoute(this.props.match.params);
    // get credentials for doctor
    const nameWithCredentials = this.getNameWithCredentialsForCurrent();
    if (this.props.userStore.currentDoctor.hasFeature(FeatureIntroCodes.CareTimer)) {
      this.props.careTimerStore.startNewSession(
        { id: this.props.userStore.currentDoctor.id, name: nameWithCredentials },
        patientId
      );
    } else {
      this.props.uiStore.setIntro(true);
    }

    try {
      await this.props.patientPageStore.loadSinglePatientDataPeriodically(patientId);
    } finally {
      this.setState({ firstPatientResponseReceived: true });
    }

    this.props.alertsStore.getClinicianAlerts();
  };

  private getNameWithCredentialsForCurrent() {
    const fullName = getClinicianFullName(this.props.userStore.currentDoctor);
    const credentialText = this.props.constantsStore.getClinicianCredentialById(
      this.props.userStore.currentDoctor.credentialId
    )?.text;
    const nameWithCredentials = getNameWithCredentials(fullName, credentialText || null);
    return nameWithCredentials;
  }

  isCallLoggingOpen = () => this.props.callLoggingStore.isCallLoggerOpen;

  toggleCallLogging = () => {
    this.props.callLoggingStore.setIsCallLoggerOpen(!this.props.callLoggingStore.isCallLoggerOpen);
  };

  onDaysToLoadChange = (daysToLoad: number) => {
    const daysTabName = toggleOptions.find((option) => option.value === daysToLoad).label as
      | '14 Days'
      | '30 days'
      | 'All Reports';
    trackEPROTimeBucketsAnalyticsEvent({ action: AnalyticEventAction.Select, value: daysTabName });

    this.setState({ symptomChartDaysToLoad: daysToLoad });
  };

  setSelectedTab = (selectedTab: string) => {
    this.props.ticketsStore.resetTicketsBulkActionSet();
    const params = new URLSearchParams();
    params.set(TAB_QUERY_PARAM, selectedTab);

    this.props.history.replace({
      search: params.toString()
    });
  };

  getSelectedTab() {
    const params = new URLSearchParams(this.props.location.search);
    const activeTab = params.get(TAB_QUERY_PARAM);
    if (VALID_TAB_VALUES.includes(activeTab)) {
      return activeTab;
    }
  }

  handleTabSelection = (newSelectedTab: string) => {
    const newSelectedTabName = DEFAULT_TABS.find((tab) => tab.value === newSelectedTab).label;

    trackTabNavigationAnalyticsEvent({
      action: AnalyticEventAction.TabSelect,
      value: newSelectedTabName
    });

    const selectedTab = this.getSelectedTab();

    selectedTab !== newSelectedTab && this.setSelectedTab(newSelectedTab);
  };

  handleDraftCallSaved = (call: Call) => {
    const { updateDraftCall, createDraftCall } = this.props.callsStore;
    const draftAction = call.id ? updateDraftCall : createDraftCall;

    return draftAction(call, this.props.patientPageStore.patient).then(() => {
      this.props.callLoggingStore.reset();
      this.props.patientPageStore.loadPatientAndTickets(call.patientId);
    });
  };

  closeAndReset = () => {
    this.props.callLoggingStore.reset();
    this.setState({ ...this.state, hasServerError: false });
  };

  hideCareTimerAutoPausedDialog = () => {
    this.setState({ showCareTimerAutoPausedDialog: false });
    this.props.careTimerStore.setInterrupted(true);
  };

  handleCallSaved = async (
    call: Call,
    options: ICallSaveOptions,
    markup?: string
  ): Promise<any> => {
    if (options.copyToClipboard && !call.isDraft && markup) {
      copy(markup, { asHtml: true });
    }

    if (call.isDraft) {
      return this.handleDraftCallSaved(call);
    }

    if (options.sendToEmr) {
      const copyToClipboardMessage = !options.copyToClipboard ? ' ' : '• Notes copied to clipboard';
      this.closeAndReset();
      showToast({ message: `Saving Call & Sending to EMR...${copyToClipboardMessage}` });
    }

    if (call.includeSummaryInEmrNote === IncludeSummaryInEmrNote.DontOfferAgain) {
      const userPreferences = localStorage.getItem(USER_PREFERENCES_LOCAL_STORAGE_KEY);

      if (userPreferences) {
        const parsedUserPreferences = JSON.parse(userPreferences) as UserPreferences;

        localStorage.setItem(
          USER_PREFERENCES_LOCAL_STORAGE_KEY,
          JSON.stringify({ ...parsedUserPreferences, generateSmartSummary: false })
        );
      }
    }

    try {
      const savedCall = await this.props.callsStore.addNewCall(call, options);

      this.closeAndReset();
      return savedCall;
    } catch (error) {
      this.setState({ ...this.state, hasServerError: true });

      if (error.name === ErrorName.FailedSendingCallToEmr) {
        const { patient } = this.props.patientPageStore;
        error.ui.description = `We were unable to submit the call with ${patient.fullName} (MRN: ${
          patient.mrn || '- '
        }) to the EMR. Please manually copy / paste the call notes into the EMR`;
      }

      if (options.sendToEmr) {
        clearAllToasts();
        this.handleEditCallClick(call);
      }
      throw error;
    }
  };

  handleCallCanceled = async () => {
    if (
      this.props.callLoggingStore.selectedResumedDraft &&
      this.props.callLoggingStore.selectedResumedDraft.id
    ) {
      await this.props.callsStore.deleteCall(this.props.callLoggingStore.selectedResumedDraft);
      this.props.callLoggingStore.setSelectedResumedDraft(null);
    }

    this.toggleCallLogging();
    showToast({ message: 'Call Deleted' });
  };

  handleEditCallClick = (call: Call) => {
    this.props.callLoggingStore.setSelectedResumedDraft(call);
    this.props.pathwaysStore.setCurrentPathwayTemplate(null);
    this.props.callLoggingStore.setIsCallLoggerOpen(true);
    this.props.patientPageStore.loadSinglePatientFromServer(call.patientId);
  };

  handleUserIdle = () => {
    this.idleTimerCountdown = setInterval(() => {
      this.setState((prevState) => {
        let secondsLeft = Math.floor(prevState.idleTimerSecondsLeft - 1);
        let { showCareTimerAutoPausedDialog } = prevState;

        if (secondsLeft <= 0) {
          clearInterval(this.idleTimerCountdown);
          this.props.careTimerStore.endInterval();
          showCareTimerAutoPausedDialog = true;
          secondsLeft =
            this.props.settingsStore.institutionSettings.careTimerIdleWarningDurationMillis / 1000;
        }
        return {
          idleTimerSecondsLeft: secondsLeft,
          showCareTimerAutoPausedDialog
        };
      });
    }, 1000);
  };

  handleUserActive = () => {
    clearInterval(this.idleTimerCountdown);
    this.setState({
      idleTimerSecondsLeft:
        this.props.settingsStore.institutionSettings?.careTimerIdleWarningDurationMillis / 1000
    });
  };

  getDefaultTabOptions = (): TabOption<string>[] => {
    const tabsOptions: TabOption<string>[] = [];

    DEFAULT_TABS.forEach((tab) => {
      if (this.props.settingsStore.hasFeature(tab.feature)) {
        tabsOptions.push({ value: tab.value, label: tab.label });
      }
    });

    return tabsOptions;
  };

  getTabOptions = (patient: Patient) => {
    if (!patient) {
      return this.defaultTabOptions;
    }

    const { drafts } = patient.callsByType;
    const { length: draftsCount } = drafts;

    return this.defaultTabOptions.map((option) => {
      const isCallsTab = option.value === PATIENT_TABS.CALLS;
      const hasDrafts = draftsCount > 0;
      const shouldModifyLabel = isCallsTab && hasDrafts;
      let { label } = option;

      if (shouldModifyLabel) {
        label = `${label} (${draftsCount} ${draftsCount > 1 ? 'Drafts' : 'Draft'})`;
      }

      return {
        ...option,
        label,
        testHook: `${option.value}_tabToggle`
      };
    });
  };

  renderReports() {
    const { patient } = this.props.patientPageStore;
    const symptomOperatorTickets = this.props.patientPageStore.patientTickets;

    if (
      (patient.questionnairesAnswers.length <= 0 && symptomOperatorTickets.length <= 0) ||
      !this.props.settingsStore.hasFeature(FEATURES.PATIENT_REPORTED_OUTCOMES_CHART)
    ) {
      return null;
    }

    return (
      <div className="symptoms-chart-section">
        <CollapsibleSection
          name="Patient-Reported Outcomes Chart"
          trigger={<div className="symptoms-chart-header">Patient-Reported Outcomes Chart</div>}
          trailing={
            <div className="d-flex">
              <ToggleBar
                id="symptomChartToggle"
                options={toggleOptions}
                selected={this.state.symptomChartDaysToLoad}
                onOptionSelected={this.onDaysToLoadChange}
                isSquared
                className="symptoms-toggle"
                size="small"
              />
            </div>
          }
        >
          <ReportsTable
            patient={patient}
            daysToLoad={this.state.symptomChartDaysToLoad}
            symptomOperatorTickets={symptomOperatorTickets}
          />
        </CollapsibleSection>
      </div>
    );
  }

  renderEmptyTicketsAndReportsState = () => {
    return (
      <div className="content-empty">
        <h1 className="empty-content-text">No Tickets or Symptom History</h1>
      </div>
    );
  };

  renderContentSection = () => {
    const { patient } = this.props.patientPageStore;
    const selectedTab = this.getSelectedTab();
    const { tasksStore } = this.props;
    const patientOperatorSymptomTickets = this.props.patientPageStore.patientTickets;
    const showTicketsEmptyState =
      patient.questionnairesAnswers.length === 0 &&
      patientOperatorSymptomTickets.length === 0 &&
      tasksStore.tasks.length === 0 &&
      selectedTab === PATIENT_TABS.OPEN_TICKETS;

    const showSmartSummaryFeatureIntroPopup =
      this.props.settingsStore.hasFeature(FEATURES.CALL_LOGGER_SMART_SUMMARY) &&
      this.props.userStore.currentDoctor.featureIntros &&
      this.props.pathwaysStore.currentPathwayInfo;

    return (
      <TicketOverviewProvider
        allowBulkActions={BULK_ACTIONS_TABS.includes(selectedTab)}
        actionCallbacks={this.getActionCallbacks()}
        getPatientSymptomOperatorTickets={this.props.ticketsStore.getPatientSymptomOperatorTickets}
        hideActionButtons={selectedTab === PATIENT_TABS.RESOLVED_TICKETS}
      >
        {this.props.callsStore.currentEditedCall && (
          <ConnectTicketsToCallEditModal onSave={this.patientPageSync} />
        )}
        {this.isCallLoggingOpen() && (
          <>
            {showSmartSummaryFeatureIntroPopup && <FeatureIntroPopup />}

            <CallLogging
              patient={patient}
              onCallCanceled={this.handleCallCanceled}
              onNewCallSaved={this.handleCallSaved}
              call={this.props.callLoggingStore.selectedResumedDraft}
              selectedTab={selectedTab}
              hasServerError={this.state.hasServerError}
            />
          </>
        )}
        {selectedTab === PATIENT_TABS.OPEN_TICKETS && (
          <div data-test-hook={patientPageTestSelectors.tab(PatientPageTabs.OpenItems)}>
            <PatientTicketOverview />

            {showTicketsEmptyState
              ? this.renderEmptyTicketsAndReportsState()
              : this.renderReports()}
          </div>
        )}
        {selectedTab === PATIENT_TABS.RESOLVED_TICKETS && (
          <ResolvedTickets
            patient={patient}
            emptyState={this.renderEmptyTicketsAndReportsState()}
            testHook={patientPageTestSelectors.tab(PatientPageTabs.ResolvedTickets)}
          />
        )}

        {selectedTab === PATIENT_TABS.CALLS && (
          <CallsTab
            patient={patient}
            onEditCallClick={this.handleEditCallClick}
            testHook={patientPageTestSelectors.tab(PatientPageTabs.Calls)}
          />
        )}

        {selectedTab === PATIENT_TABS.EPISODES_AND_TASKS && (
          <div
            className="content-section active"
            data-test-hook={patientPageTestSelectors.tab(PatientPageTabs.EpisodesAndTasks)}
          >
            <EpisodeEditCreateProvider patient={patient}>
              <PatientEpisodesAndTasks patient={patient} />
            </EpisodeEditCreateProvider>
          </div>
        )}
      </TicketOverviewProvider>
    );
  };

  syncPatient = () => {
    const { patient } = this.props.patientPageStore;
    if (patient) {
      this.props.patientPageStore.loadSinglePatientFromServer(patient.id);
    }
  };

  onTicketActionCallback = () => {
    const { ticketsStore } = this.props;
    const { patient } = this.props.patientPageStore;
    if (!patient) {
      return;
    }
    // TODO: why was this here?
    removeUrlParam(this.props.history, this.props.location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
    ticketsStore.fetchTicketsForPatient(patient.id);
  };

  onTaskActionCallback = () => {
    const { tasksStore } = this.props;

    // TODO: why was this here?
    removeUrlParam(this.props.history, this.props.location.search, HIGHLIGHT_TICKET_QUERY_PARAM);
    tasksStore.resetAndFetchTasks();
  };

  patientPageSync = () => {
    this.onTaskActionCallback();
    this.onTicketActionCallback();
    this.syncPatient();
  };

  handleResolve = (_ticketIds: number[], navigationPath?: string) => {
    if (navigationPath) {
      this.isNavigatingAfterLocalPatientDeleted = true;
      this.props.history.replace(navigationPath);
      return;
    }

    this.patientPageSync();
  };

  getActionCallbacks = (): ActionCallbacks => ({
    onResolve: this.handleResolve,
    onAssign: (ticket: Ticket) => {
      ticket.isTask ? this.onTaskActionCallback() : this.onTicketActionCallback();
    },
    onEditTask: () => {
      this.onTaskActionCallback();
      this.syncPatient();
    },
    onEditTicket: () => {
      this.onTicketActionCallback();
      this.syncPatient();
    },
    onDeleteTicket: (ticket: Ticket) => {
      const { ticketsStore } = this.props;
      // TODO: keep client?
      ticketsStore.ticketsMap.delete(ticket.id);
    },
    onDeleteTask: (task: Ticket) => {
      const { tasksStore } = this.props;
      // TODO: keep client?
      tasksStore.ticketsMap.delete(task.id);
    },
    onStatusChange: this.onTaskActionCallback,
    onRescheduleTask: this.onTaskActionCallback,
    onRequestReport: this.syncPatient,
    onSnooze: this.syncPatient,
    onReopenTask: async (task: Ticket) => {
      const { tasksStore } = this.props;

      if (this.state.reopeningTaskIds.has(task.id)) {
        return;
      }

      try {
        this.setState((prevState) => ({
          reopeningTaskIds: new Set([...prevState.reopeningTaskIds, task.id])
        }));

        await tasksStore.reopenTask(task);
        this.onTaskActionCallback();
        this.syncPatient();
      } finally {
        this.setState((prevState) => {
          const newSet = new Set(prevState.reopeningTaskIds);
          newSet.delete(task.id);
          return { reopeningTaskIds: newSet };
        });
      }
    }
  });

  render() {
    const { patient } = this.props.patientPageStore;
    const patientIdParam = getPatientIdFromRoute(this.props.match.params);
    const didLoadCareManagement =
      !this.props.settingsStore.hasFeature(FEATURES.CARE_MANAGEMENT) ||
      (this.props.constantsStore.cmStatuses.size && this.props.reasonsStore.isInitialized);
    const isDataLoading =
      !patient ||
      patient.id !== patientIdParam ||
      !this.props.departmentsStore.root ||
      !this.props.alertsStore.alerts ||
      !this.state.firstPatientResponseReceived ||
      !didLoadCareManagement;

    if (isDataLoading) {
      return <Loading primaryColor />;
    }

    const institutionSettings = this.props.settingsStore.institutionSettings;
    const isIdleWarningOpen =
      this.state.idleTimerSecondsLeft <
      institutionSettings.careTimerIdleWarningDurationMillis / 1000;
    const selectedTab = this.getSelectedTab();
    return (
      <PatientContextProvider>
        {this.props.careTimerStore.shouldEnableIdleTimer && (
          <IdleTimer
            ref={(ref) => {
              this.idleTimerRef = ref;
            }}
            timeout={
              institutionSettings.careTimerIdleTimeoutMillis -
              institutionSettings.careTimerIdleWarningDurationMillis
            }
            onIdle={this.handleUserIdle}
          />
        )}
        {!this.props.userStore.currentDoctor.hasFeature(FeatureIntroCodes.CareTimer) && (
          <div className="care-timer-intro-bg fadeIn" />
        )}
        <div className="animated fadeIn patient-main-view">
          <Prompt
            when={this.isCallLoggingOpen()}
            message={(location, action) => {
              const isAboutToSwitchPatient =
                action === 'PUSH' && !location.pathname.startsWith(`/patient/${patient.id}`);
              return isAboutToSwitchPatient ? DATA_LOST_WARNING_BEFORE_NAVIGATION : true;
            }}
          />
          <Helmet>
            <title>{`${patient.fullName} - Canopy`}</title>
          </Helmet>

          {isIdleWarningOpen && (
            <MessageDialog
              id={Dialogs.CareTimerWillPause}
              isOpen
              title={`Care Timer Will Pause in ${this.state.idleTimerSecondsLeft}`}
              handleClose={this.handleUserActive}
              primaryActionProps={{ text: 'I’m Still Here', onClick: this.handleUserActive }}
            >
              There hasn’t been any activity on this page for almost{' '}
              {moment.duration(institutionSettings.careTimerIdleTimeoutMillis).as('minutes')}{' '}
              minutes. Click below to continue the Care Timer.
            </MessageDialog>
          )}

          <MessageDialog
            id={Dialogs.CareTimerAutoPause}
            isOpen={this.state.showCareTimerAutoPausedDialog}
            title="Care Timer Auto-Paused"
            handleClose={this.hideCareTimerAutoPausedDialog}
            primaryActionProps={{ text: 'OK', onClick: this.hideCareTimerAutoPausedDialog }}
          >
            The Care Timer automatically paused after{' '}
            {moment.duration(institutionSettings.careTimerIdleTimeoutMillis).as('minutes')} minutes
            of inactivity. Click on it or any button in the Patient Page to resume.
          </MessageDialog>
          <div className="limited-width">
            <div className="patient-main-content">
              <div className="patients-page-header">
                <TicketOverviewProvider
                  allowBulkActions={BULK_ACTIONS_TABS.includes(selectedTab)}
                  actionCallbacks={this.getActionCallbacks()}
                  getPatientSymptomOperatorTickets={
                    this.props.ticketsStore.getPatientSymptomOperatorTickets
                  }
                >
                  <PatientDetailsCard
                    patient={patient}
                    disableCallLogging={this.isCallLoggingOpen()}
                    isEditable
                    withRefreshButton
                    selectedTab={this.getSelectedTab()}
                  />
                </TicketOverviewProvider>
                <ToggleBar
                  id="patientTabsToggle"
                  options={this.getTabOptions(patient)}
                  selected={this.getSelectedTab()}
                  onOptionSelected={this.handleTabSelection}
                  isSquared
                  className="patients-page-tabs-selector"
                  itemClassName="patients-page-tabs-selector-item"
                  size="small"
                />
              </div>
              {this.renderContentSection()}
            </div>
          </div>
        </div>
      </PatientContextProvider>
    );
  }
}

export default PatientMainView;
