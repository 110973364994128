import { action, computed, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import ObjectCopier from 'utils/ObjectCopier';

import CallbackTicket from 'models/CallbackTicket';

import OperatorTicket from 'models/OperatorTicket';
import TaskTicket from 'models/TaskTicket';

import { ISelectOption } from 'views/Widgets/StyledSelect';

import { TicketMessage } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.types';

import Comment from './Comment';
import TicketAssign from './TicketAssign';
import { TicketTypeKind } from './TicketTypes';

export interface TicketClinician {
  id: number;
  firstName: string;
  lastName: string;
  credentialId: number;
}

export enum TicketClass {
  operator = 'operator',
  patient = 'patient',
  task = 'task',
  callbackRequest = 'callbackRequest'
}

export enum TicketStatus {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  RESOLVED = 'resolved',
  DELETED = 'deleted',
  CLOSED = 'closed'
}

export const ticketStatusToString: Record<TicketStatus, string> = {
  [TicketStatus.OPEN]: 'To-Do',
  [TicketStatus.IN_PROGRESS]: 'In Progress',
  [TicketStatus.RESOLVED]: 'Resolved',
  [TicketStatus.CLOSED]: 'Closed',
  [TicketStatus.DELETED]: 'Deleted'
};

export const TICKET_STATUS_ALL_OPTIONS: ISelectOption<TicketStatus>[] = Object.values(TicketStatus)
  .filter((status) => status !== TicketStatus.DELETED)
  .map((status) => {
    return {
      label: ticketStatusToString[status],
      value: status
    };
  });

export default class Ticket extends ObjectCopier {
  static TICKET_ACTIVE_STATUSES = [TicketStatus.OPEN, TicketStatus.IN_PROGRESS];
  id: number;

  patientId: number;

  @observable
  status: TicketStatus;

  @observable
  assignee: TicketAssign | null = null;

  @observable
  resolvedDate: Date;

  createdAt: Date;

  class: TicketClass;

  @observable
  operatorTicket: OperatorTicket | null = null;

  @observable
  taskTicket: TaskTicket | null = null;

  @observable
  callbackTicket: CallbackTicket | null = null;

  @observable
  callIds: number[] = [];

  @observable
  comments: Comment[] | null = null;

  @observable
  commentCount: number;

  @observable
  callCount: number;

  @observable
  draftCallCount: number;

  @observable
  callAttemptsCount: number;

  @observable
  messages: TicketMessage[] | null = null;

  @observable
  messagesCount: number;

  @observable
  newMessagesCount: number;

  @observable
  lastIncomingMessageDate: Date;

  constructor(rawTicket: any) {
    super();
    makeObservable(this);
    this.id = rawTicket.ticketId;
    this.patientId = rawTicket.patientId;
    this.status = rawTicket.status;
    this.resolvedDate = rawTicket.resolvedDate;
    this.createdAt = rawTicket.createdAt;
    this.class = rawTicket.ticketClass;
    this.callIds = (rawTicket.callIds || []).map((callId: string) => parseInt(callId));
    this.commentCount = rawTicket.commentCount;
    this.callCount = rawTicket.callCount;
    this.draftCallCount = rawTicket.draftCallCount;
    this.callAttemptsCount = rawTicket.callAttemptsCount;
    this.messagesCount = rawTicket.messages?.messagesCount || 0;
    this.newMessagesCount = rawTicket.messages?.newMessagesCount || 0;
    this.lastIncomingMessageDate = rawTicket.messages?.lastIncomingMessageDate;

    this.assignee = rawTicket.assignee
      ? new TicketAssign(new Date(rawTicket.assignedAt), rawTicket.assignee, true)
      : null;
  }

  @action
  setTicketStatus(status: TicketStatus) {
    this.status = status;
  }

  @computed
  get isOperatorTicket() {
    return this.class === TicketClass.operator && Boolean(this.operatorTicket);
  }

  @computed
  get isCallbackRequestTicket() {
    return this.class === TicketClass.callbackRequest && Boolean(this.callbackTicket);
  }

  @computed
  get isTask() {
    return this.class === TicketClass.task;
  }

  @computed
  get isSymptomOperatorTicket() {
    return this.isOperatorTicket && this.operatorTicket?.kind === TicketTypeKind.symptom;
  }

  @computed
  get isGeneralOperatorTicket() {
    return this.isOperatorTicket && this.operatorTicket?.kind === TicketTypeKind.other;
  }

  @computed
  get isPatientTicket() {
    return this.class === TicketClass.patient;
  }

  @computed
  get callsAndDraftsCount() {
    return this.callCount + this.draftCallCount;
  }

  @computed
  get isResolved() {
    return this.status === TicketStatus.RESOLVED;
  }

  @computed
  get isClosedOrResolved() {
    return this.status === TicketStatus.RESOLVED || this.status === TicketStatus.CLOSED;
  }

  @computed
  get isActive() {
    return this.status === TicketStatus.OPEN;
  }

  get patientHasContact() {
    if (this.isOperatorTicket) {
      return Boolean(this.operatorTicket?.patientContactId);
    }

    return false;
  }

  isAssignedToDoctor = computedFn((docId: number) => {
    return this.assignee?.doctor.id === docId;
  });

  get mainAssign() {
    return this.assignee;
  }
  @action
  addComment(comment: Comment) {
    if (!this.comments) {
      return;
    }
    this.comments.push(comment);
    this.commentCount = this.comments.length;
  }

  @action
  setComments(comments: Comment[]) {
    this.comments = comments;
    this.commentCount = comments.length;
  }

  @action
  setMessages(messages: TicketMessage[]) {
    this.messages = messages;
    this.messagesCount = messages.length;
  }

  @computed
  get parsedStatus(): string {
    return ticketStatusToString[this.status];
  }

  @action
  clearTicketNewMessagesCount() {
    this.newMessagesCount = 0;
  }
}

export const TICKET_ACTIVE_STATUS_OPTIONS = Ticket.TICKET_ACTIVE_STATUSES.map((status) => ({
  label: ticketStatusToString[status],
  value: status
}));
