// @ts-strict-ignore
import { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { ALL_ROLES, NO_ROLE } from 'constants/tasks.const';

import { FormSelectInlineLabel } from 'components/UIkit/atoms/Dropdown';

interface Props {
  onEditCreateRole: (roleId?: number) => void;
}

const EditableRolesDropdown: FC<Props> = ({ onEditCreateRole }) => {
  const { constantsStore, userStore } = useStores();

  const options = constantsStore.rolesForSelect.map((option) => ({
    value: option.value.id,
    label: option.label,
    isEditable: option.value.id !== ALL_ROLES && option.value.id !== NO_ROLE && userStore.isManager
  }));

  return (
    <FormSelectInlineLabel
      options={options}
      isRequired
      addText={userStore.isManager && 'Role'}
      name="role"
      sortAlphabetically={false}
      placeholder="Choose Role"
      onEditClick={(option) => {
        onEditCreateRole(option.value);
      }}
      onAddClick={onEditCreateRole}
      label="Role"
    />
  );
};

export default observer(EditableRolesDropdown);
