import { useCallback } from 'react';

import { ErrorName } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import { TicketResolutionResponse } from 'fetchers/TicketsFetcher';

import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';

export interface ResolveTicketsData {
  ticketIds: number[];
  comment?: string;
  allowResolveLocalPatientTicket?: boolean;
  patientId?: number;
}

interface UseTicketResolveReturn {
  (resolveTicketData: ResolveTicketsData, navigationPath?: string): Promise<
    TicketResolutionResponse | undefined
  >;
}

export const useTicketResolve = (): UseTicketResolveReturn => {
  const { ticketsStore } = useStores();
  const {
    openDisconnectDraftPopup,
    openResolveTicketWithMessageForTheFirstTimeDialog,
    openUndeliveredMessageToPatientDialog,
    openHidePatientDialog,
    openMergePatientsModal
  } = useTicketOverviewContext();
  const { onResolve } = useTicketActionCallbacks();

  return useCallback(
    async function resolveTickets(resolveTicketsData: ResolveTicketsData, navigationPath?: string) {
      const ticketIds = resolveTicketsData.ticketIds;

      try {
        const resolutionResponse = await ticketsStore.resolveTickets(
          ticketIds,
          resolveTicketsData.comment,
          resolveTicketsData.allowResolveLocalPatientTicket || false
        );
        onResolve && onResolve(ticketIds, navigationPath);
        return resolutionResponse;
      } catch (error: any) {
        if (error.name === ErrorName.ResolveClosedTicket) {
          onResolve && onResolve(ticketIds);
          return undefined;
        }

        if (error.name === ErrorName.TicketConnectedToDraft) {
          openDisconnectDraftPopup({
            ticketIds,
            callback: async () => {
              await resolveTickets(resolveTicketsData);
            }
          });
          return undefined;
        }

        if (error.name === ErrorName.ResolvingFailedOutboundMessages) {
          openUndeliveredMessageToPatientDialog(resolveTicketsData);
          return undefined;
        }

        if (error.name === ErrorName.PatientCannotReplyAfterTicketResolve) {
          openResolveTicketWithMessageForTheFirstTimeDialog(resolveTicketsData);
          return undefined;
        }

        if (error.name === ErrorName.DuplicateEmrPatientFound) {
          openMergePatientsModal(
            resolveTicketsData,
            error.httpFailure.serverData.duplicatePatientData
          );
          return undefined;
        }

        if (
          error.name === ErrorName.NoDuplicateEmrPatientFound ||
          error.name === ErrorName.MultipleDuplicateEmrPatientFound
        ) {
          openHidePatientDialog(resolveTicketsData);
          return undefined;
        }

        throw error;
      }
    },
    [
      openDisconnectDraftPopup,
      openResolveTicketWithMessageForTheFirstTimeDialog,
      openUndeliveredMessageToPatientDialog,
      openHidePatientDialog,
      openMergePatientsModal,
      onResolve,
      ticketsStore
    ]
  );
};
