export enum TicketSummaryItemDisplayName {
  Comment = 'comment',
  Call = 'call',
  CallAttempt = 'call attempt',
  Draft = 'draft',
  Report = 'ticket',
  Pathway = 'pathway',
  Message = 'message',
  NewMessage = 'new message'
}

export type TicketSummaryItemType =
  | 'comment'
  | 'call'
  | 'callAttempt'
  | 'report'
  | 'draft'
  | 'pathway'
  | 'message'
  | 'newMessage';
