import {
  BaseTicketMessage,
  TicketMessageType
} from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages.types';

export enum TicketClinicianMessageStatusType {
  Sent = 'sent',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing'
}

export enum TicketClinicianMessageStatusErrorReason {
  OptedOut = 'optedOut'
}

export interface TicketClinicianMessageType extends BaseTicketMessage {
  status: TicketClinicianMessageStatusType;
  credential: string;
  errorReason?: TicketClinicianMessageStatusErrorReason;
  senderType: TicketMessageType.Clinician;
}
