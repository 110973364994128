// @ts-strict-ignore
import { AnalyticEventAction } from 'analytics';
import { trackMultiSelectAnalyticsEvent } from 'analytics/events/multi-select';
import { ErrorWithUi } from 'errors';
import { orderBy as orderByFp } from 'lodash/fp';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { RootStore } from 'mobx/stores';
import { DataMap } from 'mobx/stores/DataMap';

import { CallsFetcher } from 'fetchers/CallsFetcher';

import CommentsFetcher from 'fetchers/CommentsFetcher';
import TicketsFetcher, {
  ICreateTicketResult,
  IOperatorTicketsCreateRequest,
  IOperatorTicketUpdateRequest,
  ITicketCommentCreateRequest,
  TicketCheckStatusResponse,
  TicketResolutionResponse
} from 'fetchers/TicketsFetcher';

import { handleErrorSilently } from 'services/errorHandlingService';

import { RequestConfig } from 'utils/http.utils';
import { extractTicketQueryFromFilters, mergeWithQueryDefaults } from 'utils/serverFiltersUtils';
import { ExtendedTicket } from 'utils/TicketClusteringUtils';

import {
  getTicketResolutionResultMessages,
  TICKET_MISSING_TYPE,
  TicketResolveResponseType
} from 'utils/TicketMessageUtils';

import { showToast } from 'utils/UserMessageUtils';

import Patient from 'models/Patient';
import Ticket, { TicketClass, TicketStatus } from 'models/Ticket';

import { TicketsQueryRequestParams } from 'views/Filters/filters.types';

export enum TicketTypes {
  ticket = 'ticket',
  task = 'task'
}

export default class TicketsStore {
  @observable
  ticketsMap: DataMap<Ticket>;

  @observable
  ticketsBulkActionSet = observable.set<number>();

  @observable
  initialTicketsReceived: boolean = false;

  @observable
  lastRetrievedTimestamp: number = null;

  @observable
  patientTicketsFetched: number;

  rootStore: RootStore;

  refreshInterval: any;

  refreshSinglePatientTicketsInterval: NodeJS.Timeout;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    const { stores } = this.rootStore;
    this.ticketsMap = new DataMap<Ticket>({
      fallbacks: new Set([
        {
          token: 'TicketsStore.ticketsMap_ticketTypes',
          isDataExist: (ticket: Ticket) => stores.ticketTypesStore.isTicketTypesAvailable(ticket),
          fetchMissingData: () => stores.ticketTypesStore.fetchOperatorTicketTypes(),
          onDataNotFound: (ticket: Ticket) => {
            throw new ErrorWithUi(TICKET_MISSING_TYPE(ticket.id));
          }
        }
      ])
    });
  }

  @computed
  get tickets(): Ticket[] {
    return this.ticketsMap.items.filter((ticket: Ticket) => ticket.class !== TicketClass.task);
  }

  @computed
  get openTickets() {
    return this.tickets.filter((ticket) => ticket.status !== TicketStatus.RESOLVED);
  }

  handleTicketsResponse = (tickets: Ticket[], requestTimestamp: number) => {
    runInAction(() => {
      if (!this.initialTicketsReceived) {
        this.initialTicketsReceived = true;
      }
      this.lastRetrievedTimestamp ? this.addTickets(tickets) : this.setTickets(tickets);
      this.lastRetrievedTimestamp = requestTimestamp;
    });
  };

  ticketCheckStatus = (ticketId: number): Promise<TicketCheckStatusResponse> => {
    return TicketsFetcher.ticketCheckStatus(ticketId);
  };

  fetchTicketsFromServer = async (): Promise<any> => {
    const { ticketFiltersStore } = this.rootStore.stores;
    const queryFromFilters = extractTicketQueryFromFilters(ticketFiltersStore.filters);

    const queryWithDefaults = mergeWithQueryDefaults(
      queryFromFilters,
      ticketFiltersStore.defaultTicketsQueryValues
    );

    const closedStatus = this.lastRetrievedTimestamp ? [TicketStatus.RESOLVED] : [];

    const finalQuery = {
      ...queryWithDefaults,
      lastRetrievedTimestamp: this.lastRetrievedTimestamp,
      reportTypes: ticketFiltersStore.reportTypesFilterValue,
      ticketStatuses: [...Ticket.TICKET_ACTIVE_STATUSES, ...closedStatus],
      includeAssignToMe: true
    };

    const requestTimestamp = Date.now();
    const tickets = await TicketsFetcher.searchTickets(finalQuery);

    this.handleTicketsResponse(tickets, requestTimestamp);
  };

  resetAndFetchTickets = () => {
    this.resetTimestamp();
    return this.fetchTicketsFromServer();
  };

  async fetchTicketsForPatient(patientId: number, clearTickets = true) {
    const { ticketFiltersStore } = this.rootStore.stores;
    const queryFromFilters = extractTicketQueryFromFilters(ticketFiltersStore.filters);

    const queryWithDefaults = mergeWithQueryDefaults(
      queryFromFilters,
      ticketFiltersStore.defaultTicketsQueryValues
    );

    const finalQuery = {
      ...queryWithDefaults,
      reportTypes: ticketFiltersStore.reportTypesFilterValue,
      ticketStatuses: Ticket.TICKET_ACTIVE_STATUSES,
      includeAssignToMe: true,
      patientId
    };

    const tickets = await TicketsFetcher.searchTickets(finalQuery);
    runInAction(() => {
      clearTickets ? this.setTickets(tickets) : this.addTickets(tickets);
      this.patientTicketsFetched = patientId;
    });
  }

  @action
  fetchTicketsForSinglePatientPeriodically(patientId: number) {
    if (!this.refreshSinglePatientTicketsInterval) {
      const { settingsStore } = this.rootStore.stores;
      const { singlePatientRetrievalInterval } = settingsStore.institutionSettings;
      this.refreshSinglePatientTicketsInterval = setInterval(() => {
        this.fetchTicketsForPatient(patientId).catch(handleErrorSilently);
      }, singlePatientRetrievalInterval);
    }
    return this.fetchTicketsForPatient(patientId);
  }

  searchTickets = async (query: TicketsQueryRequestParams, cancellationKey?: string) => {
    const tickets = await TicketsFetcher.searchTickets(query, cancellationKey);
    this.addTickets(tickets);
    return tickets;
  };

  searchTicketsForResolvedTicketsTab = (patientId: number) => {
    return this.searchTickets({
      patientId,
      ticketStatuses: [TicketStatus.RESOLVED]
    });
  };

  searchTicketsForCreate = async (patientId: number) => {
    const tickets = await this.searchTickets({
      patientId,
      ticketStatuses: Ticket.TICKET_ACTIVE_STATUSES
    });

    this.setTickets(tickets);
  };

  getPatientTickets = computedFn((patient: Patient): Ticket[] => {
    return this.tickets.filter((ticket) => patient.id === ticket.patientId);
  });

  getPatientOpenTickets = computedFn((patient?: Patient): Ticket[] => {
    if (!patient) {
      return [];
    }
    return this.openTickets.filter((ticket) => {
      if (patient.id === ticket.patientId) {
        return (
          ticket.isOperatorTicket ||
          ticket.isCallbackRequestTicket ||
          patient.hasUnacknowledgedReports
        );
      }
      return false;
    });
  });

  patientHasOpenTickets = computedFn((patient: Patient): boolean => {
    return this.getPatientOpenTickets(patient).length > 0;
  });

  getPatientSymptomOperatorTickets = computedFn(
    (patient: Patient): Ticket[] =>
      this.getPatientOpenTickets(patient).filter((ticket) => ticket.isSymptomOperatorTicket) || []
  );

  getPatientResolvedTickets = computedFn((patient: Patient): Ticket[] =>
    this.getPatientTickets(patient).filter((ticket) => ticket.isResolved)
  );

  getPatientSortedResolvedTickets = computedFn((patient: Patient): Ticket[] => {
    return orderByFp<Ticket>(['resolvedDate'], ['asc'], this.getPatientResolvedTickets(patient));
  });

  /**
   * Clustering sorted reports to sorted tickets (ascending date order)
   * Under the condition that a report creation date must be before ticket resolution
   */
  getPatientSortedResolvedTicketsWithReports = computedFn(
    (patient: Patient): ExtendedTicket[] | null => {
      const sortedResolvedTickets = this.getPatientSortedResolvedTickets(patient) || [];
      if (sortedResolvedTickets.length === 0) {
        return null;
      }

      let reports = Object.create(patient.sortedAcknowledgedReports);
      let extendedTickets: ExtendedTicket[] = [];

      sortedResolvedTickets.forEach((ticket) => {
        // simple case of operator ticket just add empty reports array
        if (ticket.isOperatorTicket || ticket.isCallbackRequestTicket) {
          // using unshift because we want final result to be: last resolved ticket in the beginning
          extendedTickets.unshift({
            ticket,
            reports: []
          });
          return;
        }
        const ticketResolvedDate = new Date(ticket.resolvedDate);

        let extendedTicket: ExtendedTicket = {
          ticket: ticket,
          reports: []
        };

        while (reports.length) {
          const [report] = reports;
          const reportCreateDate = new Date(report.createdAt);

          // report will only get clustered to current ticket if its creation date is before ticket was resolved
          if (reportCreateDate < ticketResolvedDate) {
            // unshift for order purposes
            extendedTicket.reports.unshift(report);
            // once the report belongs to a ticket we dont want to iterate
            // it again for the next ticket so we pull it from the beginning
            reports.shift();
          } else {
            break;
          }
        }

        if (extendedTicket.reports.length) {
          extendedTickets.unshift(extendedTicket);
        } else {
          // not something that should happen since ticket creation is dependent on reports
          console.warn(`ticket ${extendedTicket.ticket.id} has no reports`);
        }
      });

      return extendedTickets;
    }
  );

  @action
  stopRefresh() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
  }

  @action
  stopSinglePatientRefresh() {
    clearInterval(this.refreshSinglePatientTicketsInterval);
    this.refreshSinglePatientTicketsInterval = null;
  }

  @action
  clearData() {
    this.ticketsMap.clear();
    this.initialTicketsReceived = false;
    this.patientTicketsFetched = null;
  }

  @action
  resetStore() {
    this.clearData();
    this.stopRefresh();
    this.resetTimestamp();
  }

  @action
  resetTimestamp() {
    this.lastRetrievedTimestamp = null;
  }

  @action
  clearTickets() {
    // Clearing all besides tasks
    this.tickets.forEach((ticket) => {
      this.ticketsMap.delete(ticket.id);
    });
  }

  // sync comments - temp fix for https://expain.atlassian.net/browse/EH-2444
  syncComments(tickets: Ticket[]) {
    tickets.forEach((ticket) => {
      const oldTicket = this.ticketsMap.get(ticket.id);
      ticket.comments = oldTicket?.comments;
    });
  }

  @action
  setTickets = (tickets: Ticket[]) => {
    this.syncComments(tickets);
    this.clearTickets();
    this.addTickets(tickets, false);
  };

  @action
  addTickets(tickets: Ticket[], syncComments = true) {
    if (syncComments) {
      this.syncComments(tickets);
    }
    this.ticketsMap.replaceItems(tickets);
  }

  updateTicketAssign(ticket: Ticket, doctorId: number | null) {
    const isReassign = Boolean(ticket.mainAssign);
    return TicketsFetcher.assignTicket(ticket.id, doctorId, isReassign);
  }

  async deleteTicket(ticketId: number) {
    await TicketsFetcher.deleteTicket(ticketId);
  }

  @action
  async resolveTickets(
    ticketIds: number[],
    comment: string = '',
    allowResolveLocalPatientTicket: boolean = false
  ): Promise<TicketResolutionResponse> {
    const resolveTicketsResult = await TicketsFetcher.resolveTickets(
      ticketIds,
      comment,
      allowResolveLocalPatientTicket
    );
    this.handleTicketResolutionMessages(resolveTicketsResult);
    return resolveTicketsResult;
  }

  handleTicketResolutionMessages = (response: TicketResolveResponseType) => {
    const { title, description } = getTicketResolutionResultMessages(response);
    showToast({ message: title, description });
  };

  updateTicket(id: number, data: IOperatorTicketUpdateRequest): Promise<Ticket> {
    return TicketsFetcher.updateTicket(id, data);
  }

  async createTickets(data: IOperatorTicketsCreateRequest): Promise<ICreateTicketResult> {
    return await TicketsFetcher.createTickets(data);
  }

  addTicketComment(ticket: Ticket, text: string) {
    const createTicketRequestData: ITicketCommentCreateRequest = {
      commentableId: ticket.id,
      commentableType: 'ticket',
      userId: this.rootStore.stores.userStore.currentDoctor.userId,
      text: text
    };

    return TicketsFetcher.createTicketComment(createTicketRequestData).then((comment) => {
      ticket.addComment(comment);
      return comment;
    });
  }

  async fetchCommentsForTicket(ticket: Ticket) {
    const comments = await CommentsFetcher.fetchCommentsByIdAndType(ticket.id, 'ticket');
    ticket.setComments(comments);
    return comments;
  }

  async fetchMessagesForTicket(ticket: Ticket) {
    const messages = await TicketsFetcher.getTicketMessages(ticket.id);
    ticket.setMessages(messages);
    return messages;
  }

  getTicketHistory(ticket: Ticket, requestConfig: RequestConfig) {
    return TicketsFetcher.getTicketHistory(ticket.id, requestConfig);
  }

  fetchTicketsCalls(ticketIds: number[] = []) {
    return CallsFetcher.fetchCallsForTickets(ticketIds);
  }

  @action
  resetTicketsBulkActionSet() {
    this.ticketsBulkActionSet.clear();
  }

  @action
  toggleBulkTicket(ticketId: number) {
    let action: AnalyticEventAction.Add | AnalyticEventAction.Remove;

    if (this.ticketsBulkActionSet.has(ticketId)) {
      action = AnalyticEventAction.Remove;
      this.ticketsBulkActionSet.delete(ticketId);
    } else {
      action = AnalyticEventAction.Add;
      this.ticketsBulkActionSet.add(ticketId);
    }

    trackMultiSelectAnalyticsEvent({
      action,
      value: `${this.ticketsBulkActionSet.size} selected items`
    });
  }

  @computed get ticketsBulkAction(): Ticket[] {
    return this.ticketsMap.getItemByKeys(Array.from(this.ticketsBulkActionSet));
  }

  // return patientId if all tickets in bulk have the same patientId, otherwise return null;
  @computed get ticketsBulkPatientId(): number {
    if (this.ticketsBulkAction.length > 0) {
      const { patientId } = this.ticketsBulkAction[0];
      if (this.ticketsBulkAction.every((ticket) => ticket.patientId === patientId)) {
        return patientId;
      }
    }
    return null;
  }

  @computed get bulkActionTickets(): Ticket[] {
    return this.ticketsMap.getItemByKeys(Array.from(this.ticketsBulkActionSet));
  }

  @computed get bulkActionTicketIds() {
    return Array.from(this.ticketsBulkActionSet);
  }

  @computed get bulkSelectedTicketsTicketTypes(): Set<TicketTypes> {
    const res = new Set<TicketTypes>();

    this.bulkActionTickets.forEach((ticket: Ticket) =>
      res.add(ticket.class === TicketClass.task ? TicketTypes.task : TicketTypes.ticket)
    );

    return res;
  }
}
