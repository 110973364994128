import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  children: ReactNode;
  title: string;
  isCallLoggerVersion?: boolean;
}

export const OralReportRow: FC<Props> = ({ title, children, isCallLoggerVersion }) => (
  <Box display="flex">
    <Text
      variant={isCallLoggerVersion ? 'body2' : 'body1'}
      color={isCallLoggerVersion ? 'secondary' : 'primary'}
    >
      {title}:&nbsp;
    </Text>
    {children}
  </Box>
);
