import { useState } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { resolveAndCommentModalSelectors } from 'tests/models/components/modal/resolved-and-comment-modal/resolve-and-comment-modal.selectors';

import { getFormattedCommentWithMention } from 'utils/MentionUtils';

import { useAsyncMentionOptions } from 'hooks/useAsyncMentionOptions';
import { useBulkTicketResolve } from 'hooks/useBulkTicketResolve';

import { FormMentionableInput } from 'components/UIkit/atoms/Input';
import { MENTION_CLASS } from 'components/UIkit/atoms/Input/FormMentionableInput';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  ticketIds: number[];
  patientId?: number;
}

interface ResolveAndCommentFields {
  comment: string;
}

export const ResolveAndCommentModal = observer(
  ({ isOpen, onClose, ticketIds, patientId }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const methods = useForm<ResolveAndCommentFields>({
      mode: 'all',
      shouldUnregister: true
    });
    const { handleSubmit, formState } = methods;
    const mentionOptions = useAsyncMentionOptions();
    const bulkTicketResolve = useBulkTicketResolve();

    const submit = async ({ comment }: ResolveAndCommentFields) => {
      setIsLoading(true);
      const formattedComment = getFormattedCommentWithMention(comment);

      try {
        onClose();
        await bulkTicketResolve(ticketIds, formattedComment, patientId);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    const disableSubmit = !formState.isValid || isLoading;

    return (
      <FormModal
        methods={methods}
        defaultValues={null}
        title="Resolve & Comment"
        isOpen={isOpen}
        confirmActions={[
          {
            text: 'Resolve',
            onClick: handleSubmit(submit),
            disabled: disableSubmit,
            testHook: resolveAndCommentModalSelectors.submitButton
          }
        ]}
        closeAction={{ onClick: onClose, disabled: false }}
        resetDataAfterClose={() => setIsLoading(false)}
        testHook={resolveAndCommentModalSelectors.container}
      >
        <Box mb={40}>
          <StyledFormMentionableInput options={mentionOptions} name="comment" isRequired />
        </Box>
      </FormModal>
    );
  }
);

const StyledFormMentionableInput = styled(FormMentionableInput)`
  & .${MENTION_CLASS}__control {
    min-height: 90px;
  }
`;
