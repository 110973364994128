import { AdvanceSearchValue, BasicSearchValue, SearchBaseAction } from './search-events.shared';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = SearchBaseAction | AnalyticEventAction.PasteFromClipboard;

type Value = BasicSearchValue | AdvanceSearchValue | 'add new patient' | 'search result';

interface Properties {
  action: Action;
  value?: Value;
  source?: 'keyboard' | null;
  type?: 'regular search' | 'advanced search';
}

export const trackSearchUsageAnalyticsEvent = ({ action, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SearchUsage,
    properties: { action, ...optionalProps }
  });
};
