import { Box, css, keyframes, styled } from '@mui/material';

const patientResultAnimation = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 40px;
    opacity: 1;
  }`;

export const StyledSearchResultsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'maxHeight'
})<{ maxHeight?: string }>(
  ({ theme, maxHeight }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.palette.natural.white};
    padding-top: ${theme.spacing(8)};
    max-height: ${maxHeight};
    overflow-y: auto;

    &.no-results {
      padding-top: 0;
    }

    &.loading {
      opacity: 0;
    }

    .patient {
      flex: 1;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      gap: 12px;
      font-weight: 400;
      animation-name: ${patientResultAnimation};
      animation-duration: 450ms;
      animation-fill-mode: forwards;

      .patient-name {
        flex: 1;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .patient-info {
        display: flex;
        min-width: 440px;
        gap: 20px;

        span.label {
          color: ${theme.palette.text.secondary};
        }

        .mrn,
        .dob,
        .phone {
          display: flex;
          flex-shrink: 0;
          white-space: nowrap;
        }

        .dob {
          width: 120px;
        }

        .mrn {
          width: 145px;
          justify-content: flex-start;
        }
      }

      &:hover,
      &.active {
        color: ${theme.palette.text.primary};
        background-color: ${theme.palette.natural.hover};
      }

      &.current {
        background-color: ${theme.palette.natural.border};
      }

      &.no-animation {
        animation-name: none;
        animation-fill-mode: none;
      }
    }
  `
);
