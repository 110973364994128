import { FC } from 'react';

import { Box } from '@mui/material';

import { NumericReportedCause } from 'models/ReportedCause';

import { Text } from 'components/UIkit/atoms/Text';

import { TagPrefix } from './AlertTag';
import AlertTags from './AlertTags';

interface Props {
  cause: NumericReportedCause | undefined;
  isFever: boolean;
  txTags?: string[];
  isCallLoggerVersion?: boolean;
}

export const TemperatureOrFeverCauseReport: FC<Props> = ({
  cause,
  isFever,
  txTags,
  isCallLoggerVersion
}) => {
  return (
    <>
      {cause && (
        <Box mb={0} display="flex" alignItems="center">
          <Text
            variant={isCallLoggerVersion ? 'body2' : 'body1'}
            color={isCallLoggerVersion ? 'secondary' : 'primary'}
          >
            {isFever ? 'Fever' : 'Temperature'}:&nbsp;
          </Text>
          <Text variant="body2" display="flex" alignItems="center">
            {cause.value === 'iDontKnow' ? 'Unknown' : `${cause.value}°F`}
            {txTags?.length > 0 && (
              <AlertTags tags={txTags.map((text) => ({ prefix: TagPrefix.Tx, text }))} />
            )}
          </Text>
        </Box>
      )}
    </>
  );
};
