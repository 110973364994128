import { FC } from 'react';

import Patient from 'models/Patient';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import DistressCausesReport from 'components/Ticket/TicketRow/DistressCausesReport';

interface Props {
  report: QuestionnaireAnswer;
  patient: Patient;
  isCallLoggerVersion?: boolean;
}

export const DrugSpecificReportSummary: FC<Props> = ({ report, patient, isCallLoggerVersion }) => {
  return (
    <>
      <DistressCausesReport
        report={report}
        patient={patient}
        isCallLoggerVersion={isCallLoggerVersion}
      />
    </>
  );
};
