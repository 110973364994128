import { FunctionComponent } from 'react';

import { observer } from 'mobx-react';

import { CallbackType } from 'models/CallbackTicket';
import Patient from 'models/Patient';
import Ticket from 'models/Ticket';

import { TOGGLE_PARAM } from 'hooks/useToggle';

import { PATIENT_TABS, TAB_QUERY_PARAM } from 'views/Patient/PatientMain/PatientMainView.constants';

import { useTicketProviderLocation } from 'components/Ticket/TicketRow/shared';
import { InternalLink } from 'components/UIkit/atoms/Link';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  ticket: Ticket;
  patient: Patient;
  isCallLoggerVersion?: boolean;
}

export const CallbackTicketSummary: FunctionComponent<Props> = observer(
  ({ ticket, patient, isCallLoggerVersion }) => {
    const providerLocationText = useTicketProviderLocation(ticket);
    let ticketTitle = 'Callback Request';
    if (ticket.callbackTicket?.callbackType === CallbackType.NoBirthdayDefined) {
      ticketTitle = 'Invitation Error';
    }
    const getTicketSummaryTitle = () => (
      <>
        <Text variant={isCallLoggerVersion ? 'body1' : 'h4'}>{ticketTitle}</Text>
        {providerLocationText && (
          <Text variant={isCallLoggerVersion ? 'body2' : 'form-text'} color="secondary">
            {' '}
            — {providerLocationText}
          </Text>
        )}
      </>
    );

    const patientPageWithOpenedEditModalPath = `/patient/${patient?.id}?${TAB_QUERY_PARAM}=${PATIENT_TABS.OPEN_TICKETS}&${TOGGLE_PARAM}=true`;

    const backwardsCompatibleCallbackRow = (reportType: CallbackType) => {
      // When transferring to actual callback request tickets (instead of the old patient.last_callback_request)
      // we created a script to create tickets for all callback requests that were in the system.
      // these callbacks are missing the dob, so we need to handle them differently.
      let source;
      if (reportType === CallbackType.DobMismatchIvr) {
        source = 'Report Line';
      } else {
        source = 'Web App';
      }
      return (
        <>
          {`Patient was unable to authenticate their birthdate to submit a report on the ${source}. Click `}
          <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to view and edit
          their birthdate.
        </>
      );
    };

    const getTicketSummaryDescription = () => {
      switch (ticket.callbackTicket?.callbackType) {
        case CallbackType.DobMismatchWeb:
          if (!ticket.callbackTicket?.callbackDOB) {
            return backwardsCompatibleCallbackRow(CallbackType.DobMismatchWeb);
          }
          return (
            <>
              {`Patient was unable to authenticate their birthdate to submit a report via web. They
            indicated a correct date of ${ticket.callbackTicket.formattedCallbackDob}. Click `}
              <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to view and
              edit their birthdate.
            </>
          );

        case CallbackType.DobMismatchIvr:
          if (!ticket.callbackTicket?.callbackDOB) {
            return backwardsCompatibleCallbackRow(CallbackType.DobMismatchIvr);
          }
          return (
            <>
              {`Patient failed to enter their birthdate correctly to authenticate themselves on the Report 
Line. They indicated their four-digit birthdate (mmdd) as ${ticket.callbackTicket.formattedCallbackDob}. Click `}
              <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to view and
              edit their birthdate.
            </>
          );

        case CallbackType.NoBirthdayDefined:
          return (
            <>
              Could not request a report from this patient, as they have no birthdate in the system.
              Click <InternalLink to={patientPageWithOpenedEditModalPath}>here</InternalLink> to
              edit their birthdate.
            </>
          );

        case CallbackType.LearnMore:
          return 'Patient indicated that they would like to learn more before consenting to report via web.';

        default:
          return '';
      }
    };

    return (
      <>
        {getTicketSummaryTitle()}

        <div>{getTicketSummaryDescription()}</div>
      </>
    );
  }
);
