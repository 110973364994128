import { useCallback } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { TicketResolutionResponse } from 'fetchers/TicketsFetcher';

import { useTicketResolve } from 'hooks/useTicketResolve';

interface UseTicketResolveReturn {
  (ticketIds: number[], comment?: string, patientId?: number): Promise<
    TicketResolutionResponse | undefined
  >;
}

export const useBulkTicketResolve = (): UseTicketResolveReturn => {
  const { ticketsStore } = useStores();
  const ticketResolve = useTicketResolve();

  return useCallback(
    function bulkResolveTickets(ticketIds: number[], comment?: string, patientId?: number) {
      ticketsStore.resetTicketsBulkActionSet();
      return ticketResolve({ ticketIds, comment, patientId });
    },
    [ticketResolve, ticketsStore]
  );
};
