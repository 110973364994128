import { FC, useCallback, MouseEvent } from 'react';

import { Box } from '@mui/material';
import { css, Palette, styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { components, OptionProps } from 'react-select';

import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';
import { SelectVariant } from 'components/UIkit/atoms/Dropdown/Select.shared';

import { EditOptionButton } from './ReactSelectComponents';

export function useOnEditClick(props: any) {
  const { onEditClick } = props.selectProps;
  const { data } = props;

  return useCallback(
    (event: MouseEvent<any>) => {
      if (onEditClick) {
        onEditClick(data);
        event.stopPropagation(); // prevent selecting item when onEditClick is called
      }
    },
    [onEditClick, data]
  );
}

export const DefaultMenuOption: FC<OptionProps<any, boolean>> = ({ children, ...props }) => {
  const onEditClick = useOnEditClick(props);
  const variant = props.selectProps.variant;
  const selectedIconSize = variant === 'secondary' ? 9 : 12;

  return (
    <StyledMenuOption {...props}>
      <StyledIconWrapper>
        {((props.data.isDisabled && props.data.disabledOptions?.isSelected) ||
          props.isSelected) && (
          <Icon.CheckMark height={selectedIconSize} width={selectedIconSize} />
        )}
      </StyledIconWrapper>

      <Box display="flex" flexGrow={1}>
        <SimpleTooltip
          title={<Box p={20}>{props.data.disabledOptions?.tooltipText}</Box>}
          disabled={!props.data.isDisabled || !props.data.disabledOptions?.tooltipText}
          placement="right"
          shouldTruncate={false}
        >
          <Typography variant={variant === 'secondary' ? 'body2' : 'form-text'}>
            {children}
          </Typography>
        </SimpleTooltip>
      </Box>
      {props.data.isEditable && <EditOptionButton onClick={onEditClick} />}
    </StyledMenuOption>
  );
};

// get colorSet by precedence
type OptionColorSet = {
  color: string;
  editColor: string;
  backgroundColor: string;
};

function getOptionColorSet(
  variant: SelectVariant,
  isSelected: boolean,
  isFocused: boolean,
  isDisabled: boolean,
  palette: Palette
): OptionColorSet {
  if (isDisabled) {
    return {
      color: palette.text.disabled,
      editColor: palette.text.disabled,
      backgroundColor: palette.natural.white
    };
  }

  if (isFocused) {
    if (variant === 'primary') {
      return {
        color: isSelected ? palette.primary.main : palette.text.primary,
        editColor: palette.primary.main,
        backgroundColor: palette.natural.hover
      };
    }

    return {
      color: isSelected ? palette.secondary.main : palette.text.primary,
      editColor: palette.secondary.main,
      backgroundColor: palette.natural.hover
    };
  }

  if (isSelected) {
    if (variant === 'primary') {
      return {
        color: palette.primary.main,
        editColor: palette.primary.main,
        backgroundColor: palette.natural.white
      };
    }
    return {
      color: palette.secondary.main,
      editColor: palette.secondary.main,
      backgroundColor: palette.natural.white
    };
  }

  return {
    color: palette.text.primary,
    editColor: variant === 'secondary' ? palette.secondary.dark : palette.primary.main,
    backgroundColor: palette.natural.white
  };
}

export const StyledMenuOption: FC<OptionProps<any, boolean>> = (props) => (
  <div data-test-hook={`select-option-${props.data.value}`}>
    <BaseStyledMenuOption {...props} />
  </div>
);

const BaseStyledMenuOption: FC<OptionProps<any, boolean>> = styled(components.Option, {
  shouldForwardProp: () => true // react-select "theme" object  should be passed down to react-select component
})((props: OptionProps<any, boolean>) => {
  const { isSelected, isFocused, isDisabled } = props;

  // when styling react-select component - use muiTheme instead of react-select theme
  const { palette, spacing } = props.selectProps.muiTheme as Theme;
  const { color, editColor, backgroundColor } = getOptionColorSet(
    props.selectProps.variant || 'primary',
    isSelected,
    isFocused,
    isDisabled,
    palette
  );

  return css`
    display: flex;
    align-items: center;
    cursor: ${isDisabled ? 'initial' : 'pointer'};
    padding: ${spacing(8, 28, 8, 8)};
    color: ${color};
    background-color: ${backgroundColor};
    // for edit button
    .menu-option-edit {
      color: ${editColor};
    }

    ${!isDisabled &&
    css`
      &:active {
        background-color: ${palette.natural.hover};
      }
    `}
  `;
});

const StyledIconWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 12px; // replace by icon size from theme (wait for EH-3989)
    min-width: 12px; // same as above (should take space even when mark icon not displayed)
    margin-right: ${theme.spacing(4)};
  `
);
