import { FC } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import CallLogIntervalAuditTrailRecord from 'models/CallLogIntervalAuditTrailRecord';

import Patient from 'models/Patient';
import { UserPreferences } from 'models/UserModel';

import { CallConnectedTicketsSection } from 'views/Widgets/CallLogging/CallLoggingBody/CallConnectedTicketsSection';
import { CallGeneratedSection } from 'views/Widgets/CallLogging/CallLoggingBody/CallGeneratedSection';
import 'views/Widgets/CallLogging/CallLoggingBody/CallLoggingBody.scss';
import { CallMyNotesSection } from 'views/Widgets/CallLogging/CallLoggingBody/CallMyNotesSection';
import { HomeCareInstructionsSection } from 'views/Widgets/CallLogging/CallLoggingBody/HomeCareInstructionsSection';

interface Props {
  patient: Patient;
  userPreferences: UserPreferences;
  auditTrail: CallLogIntervalAuditTrailRecord[];
}

export const CallLoggingBody: FC<Props> = ({ patient, userPreferences, auditTrail }) => {
  const { callLoggingStore, settingsStore } = useStores();

  return (
    <div className="window-body">
      <div className="calls-list">
        {Boolean(callLoggingStore.currentCall.ticketIds?.size) && (
          <CallConnectedTicketsSection
            patient={patient}
            ticketIds={Array.from(callLoggingStore.currentCall.ticketIds)}
          />
        )}

        <CallGeneratedSection patient={patient} />

        {settingsStore.hasFeature(FEATURES.HOMECARE_INSTRUCTIONS) && (
          <HomeCareInstructionsSection patient={patient} />
        )}

        <CallMyNotesSection generateSmartSummaryPreference={userPreferences.generateSmartSummary} />
      </div>
    </div>
  );
};
