import Patient, { IPatientContact } from 'models/Patient';

export enum TicketPatientContactType {
  Self = 'self',
  Contact = 'contact'
}

export type TicketPatientContactModel<T extends TicketPatientContactType> =
  T extends TicketPatientContactType.Contact
    ? { type: TicketPatientContactType.Contact; model: IPatientContact }
    : { type: TicketPatientContactType.Self; model: Patient };
