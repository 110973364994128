import { FC } from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { ActionMeta } from 'react-select';

import { ISelectOption } from './Select.shared';
import { FormProps, getErrorByName } from './withForm.shared';

// async select doesn't load options locally
export interface AsyncFormProps extends Omit<FormProps, 'options'> {}

export function withFormAsyncSelect<Props extends AsyncFormProps>(
  WrappedComponent: FC<{ value: any }>
) {
  return ({ isRequired, name, validate, onChange, defaultValue = null, ...rest }: Props) => {
    const { control, formState } = useFormContext();
    const isError = getErrorByName(formState.errors, name);

    return (
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={{
          required: isRequired,
          validate: validate
        }}
        render={({ field }) => {
          return (
            <WrappedComponent
              value={field.value}
              onChange={(
                option: ISelectOption<any>,
                actionMeta: ActionMeta<any>,
                eventKey: string | null
              ) => {
                field.onChange(option);

                onChange && onChange(option, actionMeta, eventKey);
              }}
              isError={isError}
              isRequired
              {...rest}
            />
          );
        }}
      />
    );
  };
}
