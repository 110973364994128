import { FC } from 'react';

import { css, styled } from '@mui/material/styles';

import { AnalyticEventAction } from 'analytics';

import { trackSaveDraftMenuAnalyticsEvent } from 'analytics/events/save-draft-menu';

import { observer } from 'mobx-react';

import { patientPageTestSelectors } from 'tests/models/pages/patient-page/patient-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import Call from 'models/Call';

import { ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { Text } from 'components/UIkit/atoms/Text';

export const NO_ANSWER_COMMENT = "Patient Didn't Answer";
export const VOICEMAIL_COMMENT = 'Left a Voicemail';

interface Props {
  onSaveDraftClicked: (isCallAttempt?: boolean) => void;
  isSaveInProgress: boolean;
}

interface SaveDraftMenuProps extends Props {
  call: Partial<Call>;
  isSummaryManuallyEdited: boolean;
}

export const SaveDraftMenu: FC<SaveDraftMenuProps> = observer(
  ({ onSaveDraftClicked, call, isSaveInProgress, isSummaryManuallyEdited }) => {
    const { ticketsStore } = useStores();

    const saveDraftCallAttempt = async (
      comment: typeof NO_ANSWER_COMMENT | typeof VOICEMAIL_COMMENT
    ) => {
      const { ticketIds } = call;

      const ticketId = Array.from(ticketIds!)[0];
      const ticket = ticketsStore.ticketsMap.get(ticketId);

      if (ticket) {
        await ticketsStore.addTicketComment(ticket, comment);
        trackSaveDraftMenuAnalyticsEvent({
          action: AnalyticEventAction.Select,
          virtual_page: 'call logger',
          value: comment,
          summary_manual_edited: isSummaryManuallyEdited ? 'yes' : 'no'
        });
        onSaveDraftClicked(true);
      }
    };

    const saveDraftOptions: ITooltipOption[] = [
      {
        text: 'Save Draft',
        onClick: () => {
          trackSaveDraftMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            virtual_page: 'call logger',
            value: 'Save Draft',
            summary_manual_edited: isSummaryManuallyEdited ? 'yes' : 'no'
          });
          onSaveDraftClicked();
        },
        disabled: isSaveInProgress,
        testHook: patientPageTestSelectors.callLogger.saveDraftMenu.saveDraftOption
      },
      {
        groupHeader: 'Save & Comment ticket',
        text: NO_ANSWER_COMMENT,
        disabled: isSaveInProgress,
        testHook: patientPageTestSelectors.callLogger.saveDraftMenu.patientDidntAnswerOption,
        onClick: () => saveDraftCallAttempt(NO_ANSWER_COMMENT)
      },
      {
        text: VOICEMAIL_COMMENT,
        disabled: isSaveInProgress,
        onClick: () => saveDraftCallAttempt(VOICEMAIL_COMMENT)
      }
    ];

    return (
      <Tooltip
        label={
          <Text
            variant="tab"
            ml="auto"
            mt={12}
            testHook={patientPageTestSelectors.callLogger.saveDraftButton}
          >
            Save Draft
          </Text>
        }
        trigger={TooltipTrigger.CLICK}
        onShow={() =>
          trackSaveDraftMenuAnalyticsEvent({
            action: AnalyticEventAction.Click,
            virtual_page: 'call logger',
            summary_manual_edited: isSummaryManuallyEdited ? 'yes' : 'no'
          })
        }
        disabled={isSaveInProgress}
        placement="bottom-end"
        testHook={patientPageTestSelectors.callLogger.saveDraftMenu.container}
      >
        <TooltipSelect options={saveDraftOptions} />
      </Tooltip>
    );
  }
);

export const SaveDraftButton: FC<Props> = observer(({ onSaveDraftClicked, isSaveInProgress }) =>
  isSaveInProgress ? (
    <Text
      ml="auto"
      variant="tab"
      color="disabled"
      testHook={patientPageTestSelectors.callLogger.saveDraftButton}
    >
      Saving...
    </Text>
  ) : (
    <StyledText
      ml="auto"
      variant="tab"
      component="div"
      testHook={patientPageTestSelectors.callLogger.saveDraftButton}
      onClick={() => onSaveDraftClicked()}
    >
      Save Draft
    </StyledText>
  )
);

const StyledText = styled(Text)(
  ({ theme }) => css`
    cursor: pointer;

    &:hover {
      color: ${theme.palette.primary.main};
    }
  `
);
