import { FC } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { pluralize } from 'utils/StringUtils';

import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  count: number;
}

export const TicketNewMessagesExpander: FC<Props> = ({ count }) => (
  <StyledContainer className="new-messages">
    <Text variant="body1" color="white">
      {count} {pluralize('New Message', count)}
    </Text>
  </StyledContainer>
);

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.tag.tag8};
    opacity: 0.95;
    padding: ${theme.spacing(4, 8)};
    border-radius: ${theme.borderRadius.medium};
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  `
);
