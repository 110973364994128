import { FC } from 'react';

import { useFormContext, Controller } from 'react-hook-form';
import { ActionMeta } from 'react-select/dist/declarations/src/types';

import { ISelectOption } from './Select.shared';
import { FormProps, getErrorByName } from './withForm.shared';

export function withFormSelect<Props extends FormProps>(WrappedComponent: FC<{ value: any }>) {
  return ({
    isRequired,
    name,
    options,
    validate,
    onChange,
    defaultValue = null,
    addText,
    onAddClick,
    ...rest
  }: Props) => {
    const { control, formState } = useFormContext();
    const isError = getErrorByName(formState.errors, name);

    return (
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={{
          required: isRequired,
          validate: (currentOption) => {
            // satisfy isRequired
            if (isRequired && !currentOption) {
              return false;
            }
            // satisfy provided validation function (optional)
            return !validate || validate(currentOption);
          }
        }}
        render={({ field }) => {
          return (
            <WrappedComponent
              value={field?.value ? field.value : null}
              onChange={(
                option: ISelectOption<any>,
                actionMeta: ActionMeta<any>,
                eventKey: string | null
              ) => {
                field.onChange(option, actionMeta);

                onChange && onChange(option, actionMeta, eventKey);
              }}
              isError={isError}
              options={options}
              isRequired
              addText={addText}
              onAddClick={onAddClick}
              {...rest}
            />
          );
        }}
      />
    );
  };
}
