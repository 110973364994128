import { FC } from 'react';

import { Box } from '@mui/material';

import classNames from 'classnames';

import Ticket from 'models/Ticket';

import { TicketComments } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketComments';

import 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketCommunicationSection.scss';

import { TicketMessages } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketMessages/TicketMessages';
import TicketContact from 'components/Ticket/TicketRow/TicketContact/TicketContact';

interface Props {
  ticket: Ticket;
  showContact?: boolean;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
  showMessages?: boolean;
}

export const TicketCommunicationSection: FC<Props> = ({
  ticket,
  showContact = true,
  ticketIndex,
  ticketSectionCurrentPage,
  showMessages = false
}) => {
  const classes = classNames('tickets-comments-and-contact d-flex flex-column', {
    'comments-only': !showContact
  });

  return (
    <div className={classes}>
      {showContact && <TicketContact ticket={ticket} />}

      <Box display="flex" gap={28}>
        <Box flex={1}>
          <TicketComments
            ticket={ticket}
            ticketIndex={ticketIndex}
            ticketSectionCurrentPage={ticketSectionCurrentPage}
          />
        </Box>

        {showMessages && (
          <Box flex={1}>
            <TicketMessages
              ticket={ticket}
              ticketIndex={ticketIndex}
              ticketSectionCurrentPage={ticketSectionCurrentPage}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};
