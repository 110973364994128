// @ts-strict-ignore
import { HttpError, UserInfoInUseFailure } from 'errors';

import { useStores } from 'mobx/hooks/useStores';

import { TOGGLE_PARAM } from './useToggle';

export function usePostEmailInUseError() {
  const { uiStore } = useStores();

  return (error: HttpError<UserInfoInUseFailure>) => {
    const { patientName, patientId } = error.httpFailure.serverData;
    const index = patientName.indexOf(' ');
    const usedFirstName = index === -1 ? patientName : patientName.substring(0, index);

    uiStore.postError(
      error,
      `${patientName} is using this email. Please use a different email or change their’s first.`,
      {
        actionText: `Edit ${usedFirstName}`,
        actionCallback: () => {
          const win = window.open(`/#/patient/${patientId}?${TOGGLE_PARAM}=true`, '_blank');
          win.focus();
        }
      }
    );
  };
}
