// @ts-strict-ignore
import { FC, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { getFormattedCommentWithMention } from 'utils/MentionUtils';

import { API_LABELS } from 'constants/apiUrls';

import Comment from 'models/Comment';

import Ticket from 'models/Ticket';

import { useAsyncMentionOptions } from 'hooks/useAsyncMentionOptions';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';
import { TicketComment } from 'components/Ticket/TicketRow/TicketCommunicationSection/TicketComments/TicketComment';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { FormMentionableInput } from 'components/UIkit/atoms/Input';
import { MENTION_CLASS } from 'components/UIkit/atoms/Input/FormMentionableInput';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  ticket: Ticket;
  onSubmit?: () => void;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

export const TicketComments: FC<Props> = observer(
  ({ ticket, ticketIndex, ticketSectionCurrentPage }) => {
    const { ticketsStore } = useStores();
    const mentionOptions = useAsyncMentionOptions();
    const [isCommenting, setIsCommenting] = useState(false);
    const inProgress = useNetworkLoading(API_LABELS.COMMENTS(ticket.id));
    const methods = useForm();
    const { tab } = useTicketOverviewContext();

    // Added this state to handle the scenario where, upon clicking 'Refresh Data,' tickets are refetched, and the initial ticket.comments value is null.
    // Since ticket comments are fetched immediately after, there is a brief delay (about 1 second) during which comments are unavailable, causing a visual jump.
    const [comments, setComments] = useState<Comment[]>(ticket.comments || []);

    useEffect(
      function init() {
        async function initComments() {
          if (ticket.commentCount > 0) {
            const comments = await ticketsStore.fetchCommentsForTicket(ticket);
            setComments(comments);
          }
        }

        initComments();
      },
      [ticketsStore, ticket]
    );

    const handleSubmit = async (data: { newComment: string }) => {
      const text = getFormattedCommentWithMention(data.newComment);
      trackActionButtonAnalyticsEvent({
        action: AnalyticEventAction.Comment,
        ticket_id: ticket.id,
        patient_id: ticket.patientId,
        tab,
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });

      setIsCommenting(true);
      try {
        await ticketsStore.addTicketComment(ticket, text);
        const comments = await ticketsStore.fetchCommentsForTicket(ticket);
        setComments(comments);

        methods.reset({ newComment: '' });
      } finally {
        setIsCommenting(false);
      }
    };

    return (
      <div className="ticket-comments d-flex flex-column">
        <Text color="secondary" mb={20}>
          STAFF COMMENTS
        </Text>

        {comments.map((comment) => (
          <TicketComment key={comment.id} comment={comment} />
        ))}

        <FormProvider {...methods}>
          <StyledNewCommentContainer className="new-comment-container">
            <FormMentionableInput
              options={mentionOptions}
              name="newComment"
              isRequired
              placeholder="Add Staff Comment"
              variant="secondary"
            >
              <div className="comment-send">
                <StyledIconButton
                  onClick={methods.handleSubmit(handleSubmit)}
                  disabled={isCommenting || inProgress}
                >
                  <Icon.Send height={20} width={20} />
                </StyledIconButton>
              </div>
            </FormMentionableInput>
          </StyledNewCommentContainer>
        </FormProvider>
      </div>
    );
  }
);

const StyledNewCommentContainer = styled(Box)(
  ({ theme }) => css`
    .${MENTION_CLASS}:focus-within + div.comment-send button.icon-button {
      color: ${theme.palette.secondary.dark};
      opacity: 1;
    }
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    color: ${theme.palette.natural.black};
    opacity: 0.4;
  `
);
