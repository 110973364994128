import { observer } from 'mobx-react';

import { Redirect, Switch } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { Route } from 'services/sentryService';

import { FEATURES } from 'constants/features';

import { useHasAnalyticsFeatures } from 'hooks/useFeature';

import PatientsActivation from 'views/Pages/Activation/PatientsActivation';
import AnalyticsDashboardPage from 'views/Pages/AnalyticsDashboard/AnalyticsDashboardPage';
import CareManagementPage from 'views/Pages/CareManagement/CareManagementPage';
import CreateTicketsPage from 'views/Pages/CreateTickets/CreateTickets';
import Invitation from 'views/Pages/Invitation/Invitation';
import { PathwayBuilderPage } from 'views/Pages/PathwayBuilder/PathwayBuilderPage';
import { PatientList } from 'views/Pages/PatientList/PatientList';
import { PatientRegistrationPage } from 'views/Pages/PatientRegistration/PatientRegistrationPage';
import PracticeManagerPage from 'views/Pages/PracticeManagement/PracticeManagerPage';

import ClinicianAlertsPage from 'views/Patient/Alerts/ClinicianAlerts';
import PatientMainView from 'views/Patient/PatientMain/PatientMainView';

import { WorkQueuePage } from 'views/WorkQueue/WorkQueuePage';

import PrivateRoute from 'components/PrivateRoute';

const PrivateRoutes = () => {
  const { settingsStore, userStore } = useStores();
  const hasAnalyticsFeatures = useHasAnalyticsFeatures();

  //We have a scenario where user does not have the Work Queue feature - in this case we will show him an empty page.
  const shouldAllowEmptyPage =
    userStore.isAuthenticated && !settingsStore.hasFeature(FEATURES.WORK_QUEUE_PAGE);

  const getRedirectRoute = () => {
    if (settingsStore.hasFeature(FEATURES.WORK_QUEUE_PAGE)) {
      return '/work-queue';
    }

    if (shouldAllowEmptyPage) {
      return '/home';
    }

    return '/';
  };

  const redirectRoute = getRedirectRoute();

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/home"
        component={() => <div data-test-hook="empty-page" />}
        redirectRoute={redirectRoute}
        shouldAllow={shouldAllowEmptyPage}
      />

      <PrivateRoute
        exact
        path="/triage"
        component={WorkQueuePage}
        redirectRoute={redirectRoute}
        shouldAllow={settingsStore.hasFeature(FEATURES.WORK_QUEUE_PAGE)}
      />

      <PrivateRoute
        exact
        path="/work-queue/:activeTab?"
        component={WorkQueuePage}
        redirectRoute={redirectRoute}
        shouldAllow={settingsStore.hasFeature(FEATURES.WORK_QUEUE_PAGE)}
      />

      <PrivateRoute
        path="/patient/:patientId"
        component={PatientMainView}
        redirectRoute={redirectRoute}
        shouldAllow={settingsStore.hasFeature(FEATURES.PATIENT_PAGE)}
      />

      <PrivateRoute
        exact
        path="/alerts"
        component={ClinicianAlertsPage}
        name="PatientAlerts"
        redirectRoute={redirectRoute}
        shouldAllow={settingsStore.hasFeature(FEATURES.TRIAGE_RULES)}
      />

      <PrivateRoute
        exact
        path="/invitation"
        component={Invitation}
        redirectRoute={redirectRoute}
        shouldAllow={settingsStore.hasFeature(FEATURES.INVITE_PATIENTS)}
      />

      <PrivateRoute
        exact
        path="/create-tickets"
        component={CreateTicketsPage}
        redirectRoute={redirectRoute}
        shouldAllow={settingsStore.hasFeature(FEATURES.CREATE_TICKET)}
      />

      <PrivateRoute
        exact
        path="/care-management/:activeTab"
        component={CareManagementPage}
        redirectRoute={redirectRoute}
        shouldAllow={settingsStore.hasFeature(FEATURES.CARE_MANAGEMENT)}
      />

      <PrivateRoute
        exact
        path="/practice-analytics"
        component={AnalyticsDashboardPage}
        redirectRoute={redirectRoute}
        shouldAllow={hasAnalyticsFeatures}
      />

      <PrivateRoute
        exact
        path="/practice/:activeTab"
        shouldAllow={userStore.isManager && settingsStore.hasFeature(FEATURES.PRACTICE_MANAGER)}
        component={PracticeManagerPage}
        redirectRoute={redirectRoute}
      />

      <PrivateRoute
        exact
        path="/pathway-builder/:activeTab"
        shouldAllow={userStore.isAdmin}
        component={PathwayBuilderPage}
        redirectRoute={redirectRoute}
      />

      <PrivateRoute
        path="/activation"
        redirectRoute={redirectRoute}
        component={PatientsActivation}
        shouldAllow={settingsStore.hasFeature(FEATURES.AWAITING_ACTIVATION)}
      />

      <PrivateRoute
        path="/patient-list"
        redirectRoute={redirectRoute}
        component={PatientList}
        shouldAllow={userStore.isAdmin}
      />

      <PrivateRoute
        path="/patient-registration"
        redirectRoute={redirectRoute}
        component={PatientRegistrationPage}
        shouldAllow={settingsStore.hasFeature(FEATURES.PATIENT_MESSAGING)}
      />

      <Route path="/" render={() => <Redirect to={redirectRoute} />} />
    </Switch>
  );
};

export default observer(PrivateRoutes);
