import { FC, useMemo, useState } from 'react';

import { Box, useTheme } from '@mui/material';

import { css, styled } from '@mui/material/styles';
import { isEqual } from 'lodash/fp';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import PatientsFetcher from 'fetchers/PatientsFetcher';

import { DATE_ONLY_FORMAT, formatDate } from 'utils/DateUtils';

import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import { ResolveTicketsData, useTicketResolve } from 'hooks/useTicketResolve';

import {
  DuplicatePatientData,
  MergePatientsModalColumn
} from 'views/Modals/MergePatientsModal/MergePatientsModal.types';

import { usePatientModel } from 'components/Patient/usePatientModel';
import { useHidePatientDialog } from 'components/Ticket/TicketsContainers/TicketsContainers.shared';

import { Modal } from 'components/UIkit/atoms/Modal';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  resolveTicketsData: ResolveTicketsData;
  duplicatePatientData: DuplicatePatientData[];
}

export const MergePatientsModal: FC<Props> = observer(
  ({ isOpen, onClose, resolveTicketsData, duplicatePatientData }) => {
    const patient = usePatientModel();
    const { dialog, openDialog } = useHidePatientDialog(onClose);
    const ticketResolve = useTicketResolve();
    const { palette } = useTheme();
    const { careTimerStore } = useStores();
    const [isLoading, setIsLoading] = useState(false);

    const mergePatients = async () => {
      setIsLoading(true);
      try {
        const localPatient = duplicatePatientData.find((patient) => patient.type === 'local');
        const targetPatient = duplicatePatientData.find((patient) => patient.type === 'regular');
        const isLocalPatient = patient?.id === localPatient!.id;

        await careTimerStore.endSession();
        await PatientsFetcher.mergePatients({
          localPatientId: localPatient!.id,
          targetPatientId: targetPatient!.id
        });

        await ticketResolve(
          resolveTicketsData,
          isLocalPatient ? `/patient/${targetPatient!.id}` : undefined
        );
      } finally {
        onClose();
        setIsLoading(false);
      }
    };

    const columns: MergePatientsModalColumn[] = useMemo(
      () => [
        {
          accessor: 'type',
          name: '',
          Cell: (cellValue) => (
            <Text key={cellValue} width="200px" textAlign="start" py={12}>
              {cellValue === 'local' ? 'Canopy Record' : 'Practice Management Record'}
            </Text>
          ),
          width: '200px'
        },
        {
          accessor: 'firstName',
          name: 'First Name'
        },
        {
          name: 'Last Name',
          accessor: 'lastName'
        },
        {
          name: 'Date of Birth',
          accessor: 'dateOfBirth',
          formatCell: (cellValue) => (cellValue ? formatDate(cellValue, DATE_ONLY_FORMAT) : '')
        },
        {
          name: 'Creation Date',
          accessor: 'createdAt',
          formatCell: (cellValue) => (cellValue ? formatDate(cellValue, 'M/D/YYYY') : ''),
          compareFn: (cellValue1, cellValue2) =>
            isEqual(
              formatDate(cellValue1, DATE_ONLY_FORMAT),
              formatDate(cellValue2, DATE_ONLY_FORMAT)
            )
        },
        {
          name: 'Phone Number',
          accessor: 'phoneNumber',
          formatCell: (cellValue) => (cellValue ? getFormattedPhoneNumber(cellValue) : '-')
        },
        {
          name: 'MRN',
          accessor: 'mrn'
        }
      ],
      []
    );

    const getColumnColor = (key: Exclude<keyof DuplicatePatientData, 'id'>) => {
      if (!duplicatePatientData.length) {
        return palette.tag.highRisk;
      }

      if (key === 'type') {
        return palette.common.white;
      }

      if (key === 'mrn') {
        return palette.natural.inactiveBackground;
      }

      const column = columns.find((column) => column.accessor === key);

      const isValuesEqual = column?.compareFn
        ? column.compareFn(duplicatePatientData[0][key], duplicatePatientData[1][key])
        : isEqual(
            duplicatePatientData[0][key]?.toLowerCase(),
            duplicatePatientData[1][key]?.toLowerCase()
          );

      return isValuesEqual ? palette.primary.light : palette.tag.highRisk;
    };

    return (
      <>
        {dialog}

        <Modal
          size="large"
          isOpen={isOpen}
          title="Is this the same patient?"
          subtitle={`"${patient?.fullName || 'Patient'}" was registered locally in Canopy.
Please verify whether this patient matches the one in your practice management system (for merging).`}
          confirmActions={[
            { text: 'Yes - Same Patient', disabled: isLoading, onClick: mergePatients }
          ]}
          closeAction={{
            text: 'No - Different Patient',
            onClick: () => openDialog(resolveTicketsData),
            disabled: isLoading
          }}
        >
          <Box mb={40}>
            <StyledHeaderRow>
              {columns.map(({ name, accessor, width }) => (
                <StyledHeaderCell key={accessor} color={getColumnColor(accessor)} width={width}>
                  <Text>{name}</Text>
                </StyledHeaderCell>
              ))}
            </StyledHeaderRow>

            <StyledRowContainer>
              {duplicatePatientData.map((patientRow, index) => (
                <StyledRow key={patientRow.id}>
                  {columns.map(
                    ({ accessor, Cell, formatCell, width = '123px', align = 'center' }) => {
                      if (Cell) {
                        return Cell(patientRow[accessor]);
                      }

                      const cellContent = formatCell
                        ? formatCell(patientRow[accessor])
                        : patientRow[accessor];

                      return (
                        <Text
                          key={accessor}
                          width={width}
                          textAlign={align}
                          variant="body2"
                          py={12}
                          title={cellContent}
                        >
                          {cellContent}
                        </Text>
                      );
                    }
                  )}
                </StyledRow>
              ))}
            </StyledRowContainer>
          </Box>
        </Modal>
      </>
    );
  }
);

const StyledRowContainer = styled(Box)(
  ({ theme }) => css`
    div:nth-child(even) {
      background-color: ${theme.palette.natural.inactiveBackground};
    }
  `
);

const StyledRow = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing(24)};
    padding: ${theme.spacing(0, 8)};
    border-radius: ${theme.borderRadius.small};
  `
);

const StyledHeaderRow = styled(StyledRow)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(12)};
  `
);

const StyledHeaderCell = styled(Box)<{
  color: string;
  width?: string;
}>(
  ({ theme, color, width = '123px' }) =>
    css`
      background-color: ${color};
      text-align: center;
      width: ${width};
      height: 21px;
      border-radius: ${theme.borderRadius.medium};
    `
);
