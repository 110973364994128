import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Open | AnalyticEventAction.Cancel | AnalyticEventAction.Save;

type Value = 'edit' | 'new';

interface Properties {
  action: Action;
  value?: Value;
  patientId?: number;
}

export const trackEditContactModalAnalyticsEvent = ({
  action,
  patientId,
  ...optionalProperties
}: Properties) => {
  AnalyticsService.trackEvent<Exclude<Properties, 'patientId'> & { patient_id?: number }>({
    name: AnalyticEventName.CallbackContactsModal,
    properties: { action, ...optionalProperties, patient_id: patientId }
  });
};
