// @ts-strict-ignore
import { FC, useEffect, useMemo, useState } from 'react';

import { AnalyticEventAction } from 'analytics';
import { Dialogs } from 'analytics/events/dialog';
import { trackEditPatientModalAnalyticsEvent } from 'analytics/events/edit-patient-modal';
import {
  trackDropdownInputUsageAnalyticsEvent,
  trackInputUsageAnalyticsEvent,
  trackMultiSelectionInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';
import { trackOptOutRemoteMonitoringAnalyticsEvent } from 'analytics/events/opt-out-remote-monitoring';
import { trackOptOutRemoteMonitoringModalAnalyticsEvent } from 'analytics/events/opt-out-remote-monitoring-modal';
import { ErrorName } from 'errors';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { observer } from 'mobx-react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { editPatientModalTestSelectors } from 'tests/models/components/modal/edit-patient-modal/edit-patient-modal.selectors';

import { sharedTestSelectors } from 'tests/shared/shared.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { handleErrorSilently } from 'services/errorHandlingService';

import { parseDateForInputField } from 'utils/DateUtils';

import { formatInternationalNumber } from 'utils/FormatUtils';

import { phoneTypeOptions } from 'utils/PhoneUtils';

import * as ValidationUtils from 'utils/ValidationUtils';

import { FEATURES } from 'constants/features';

import Patient, { IPatientContact } from 'models/Patient';
import PatientLocation from 'models/PatientLocation';
import { PatientOptOut } from 'models/PatientOptOut';
import PatientProvider from 'models/PatientProvider';
import PatientTag from 'models/PatientTag';
import { PhoneType } from 'models/PhoneNumberDetails';
import ScheduledProtocol, { ProtocolName, ProtocolType } from 'models/ScheduledProtocol';

import { usePostEmailInUseError } from 'hooks/useEmailInUseError';
import { usePostPhoneInUseError } from 'hooks/usePhoneInUseError';

import { PatientOptOutModal } from 'views/Modals/OptOutModal';
import { PatientContactModalForm } from 'views/Modals/PatientContactModalForm';

import ScheduledProtocolModal from 'views/Modals/ScheduledProtocolModal';
import EditInfoInputs, { localeOptions, sexOptions } from 'views/Widgets/EditInfoInputs';
import { PatientLocationAutocomplete } from 'views/Widgets/PatientLocationInput';
import { PatientProviderAutocomplete } from 'views/Widgets/PatientProviderInput';
import { ReportProtocolInput } from 'views/Widgets/ReportPotocolInput';
import StyledPhoneInput from 'views/Widgets/StyledPhoneInput';
import { ISelectOption } from 'views/Widgets/StyledSelect';

import { EditPatientsContacts } from 'components/Patient/EditPatientContacts';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { FormAutocomplete, FormMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

import './EditPatientInfoModal.scss';

interface EditPatientInfoModalProps {
  patient: Patient;
  isOpen: boolean;
  onEditSuccessful: () => void;
  onCancelClicked: () => void;
}

interface IEditPatientInfoForm {
  firstName: string;
  lastName: string;
  mrn: string;
  dateOfBirth: string;
  ssn: string;
  sex: ISelectOption<number>;
  language: ISelectOption<string>;
  provider: ISelectOption<PatientProvider>;
  location: ISelectOption<PatientLocation>;
  tags: ISelectOption<number>[];
  phoneType: ISelectOption<PhoneType>;
  phone: string;
  email: string;
  countryCode: string;
  protocol: ScheduledProtocol;
}

const EditPatientInfoModal: FC<EditPatientInfoModalProps> = (props: EditPatientInfoModalProps) => {
  const [patient, setPatient] = useState(props.patient.copyWith());
  const [contactToEdit, setContactToEdit] = useState<IPatientContact>(null);
  const [contactToRemove, setContactToRemove] = useState<IPatientContact>(null);
  const [isPatientContactModalOpen, setIsPatientContactModalOpen] = useState(false);
  const [isOptOutModalOpen, setIsOptOutModalOpen] = useState(false);
  const [optOutInfo, setOptOutInfo] = useState(props.patient.optOut);
  const [selectedProtocolType, setSelectedProtocolType] = useState(
    patient.scheduledProtocols && patient.mainScheduledProtocol
      ? patient.mainScheduledProtocol.type
      : null
  );
  const [isProtocolWarningOpen, setIsProtocolWarningOpen] = useState(false);
  const [isProtocolModalOpen, setIsProtocolModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    constantsStore,
    patientPageStore,
    providersStore,
    locationsStore,
    settingsStore,
    userStore,
    uiStore
  } = useStores();
  const history = useHistory();

  const defaultValues = useMemo(() => {
    let phoneNumber;

    if (patient.phone) {
      phoneNumber = parsePhoneNumberFromString(patient.phone);
    }

    const language = localeOptions.find((option: ISelectOption<string>) =>
      option.value.includes(patient.language)
    );
    return {
      firstName: patient.firstName,
      lastName: patient.lastName,
      location: patient.locationId
        ? locationsStore.locationsForSelect.find((option) => option.value.id === patient.locationId)
        : null,
      phoneType: phoneTypeOptions.find((option) => option.value === patient.phoneType),
      countryCode: phoneNumber ? `+${phoneNumber.countryCallingCode.toString()}` : '+1',
      phone: phoneNumber ? phoneNumber.nationalNumber.toString() : '',
      language: language,
      sex: sexOptions.find((option) => option.value === patient.sex),
      dateOfBirth: patient.dateOfBirth ? parseDateForInputField(patient.dateOfBirth) : null,
      provider: patient.providerId
        ? providersStore.providersForSelect.find((option) => option.value.id === patient.providerId)
        : null,
      tags: constantsStore
        .getTagsByIds(patient.tags)
        .map((tag: PatientTag) => ({ value: tag.id, label: tag.name })),
      mrn: patient.mrn ? patient.mrn : '',
      email: patient.email ? patient.email : '',
      protocol: patient.scheduledProtocols ? patient.mainScheduledProtocol : null
    };
  }, [patient, providersStore, constantsStore, locationsStore]);

  const methods = useForm<IEditPatientInfoForm>({
    shouldUnregister: false
  });

  const {
    getValues,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState,
    trigger,
    reset,
    register
  } = methods;
  const [phoneType, phone, countryCode, protocol] = watch([
    'phoneType',
    'phone',
    'countryCode',
    'protocol'
  ]);

  useEffect(
    function registerProtocolFormField() {
      register('protocol', {
        required: false,
        validate: (value) => {
          return (
            Boolean(optOutInfo) ||
            !patient.isRemoteMonitorConsented ||
            Boolean(value) ||
            !settingsStore.hasFeature(FEATURES.REPORT_PROTOCOL)
          );
        }
      });
    },
    [register, optOutInfo, patient.isRemoteMonitorConsented, settingsStore]
  );

  useEffect(
    function triggerValidationOnOptOutChange() {
      // Re trigger validation on optOutInfo change as they are slightly different
      if (formState.isSubmitted) {
        trigger();
      }
    },
    [optOutInfo, formState.isSubmitted, trigger]
  );

  useEffect(
    function updateProtocolOnExternalChange() {
      setPatient(props.patient.copyWith());
    },
    [props.patient, props.patient.mainScheduledProtocol]
  );

  useEffect(
    function lookupPhoneTypeOnPhoneChanged() {
      const internationalPhone = countryCode + phone;
      if (
        (formState.dirtyFields.phone || formState.dirtyFields.countryCode) &&
        ValidationUtils.isValidPhoneNumber(internationalPhone)
      ) {
        const lookUpPhoneDetails = async () => {
          try {
            const phoneDetails = await userStore.lookUpPhoneDetails(internationalPhone);

            if (phoneDetails) {
              const phoneType = phoneTypeOptions.find(
                (option) => option.value === phoneDetails.type
              );

              setValue('phoneType', phoneType);
            }
          } catch (error) {
            handleErrorSilently(error);
            setValue('protocol', null);
            setValue('phoneType', null);
          } finally {
            clearErrors('phoneType');
          }
        };

        lookUpPhoneDetails();
      }
    },
    [
      phone,
      countryCode,
      clearErrors,
      setValue,
      userStore,
      formState.dirtyFields.phone,
      formState.dirtyFields.countryCode
    ]
  );

  //clear protocol when phone type field is changed to "Landline" and the protocol is "Mobile App"
  useEffect(
    function clearProtocol() {
      if (phoneType?.value === PhoneType.landline && protocol?.type === ProtocolType.mobile) {
        setValue('protocol', null);
      }
    },
    [protocol, phoneType, setValue]
  );

  const handleOptOutClosed = (optOutInfo: PatientOptOut) => {
    setIsOptOutModalOpen(!isOptOutModalOpen);
    setOptOutInfo(optOutInfo);
  };

  const onProtocolWarningChangeCancel = () => {
    if (protocol) {
      setSelectedProtocolType(protocol.type);
    }

    setIsProtocolWarningOpen(false);
  };

  const handleProtocolSubmitted = (newProtocol: ScheduledProtocol) => {
    newProtocol.type = selectedProtocolType;
    setValue('protocol', newProtocol, {
      shouldDirty: true
    });
    setIsProtocolModalOpen(false);
  };

  const handleProtocolCanceled = () => {
    const hasPreviousReport = !!patient.scheduledProtocols && patient.scheduledProtocols.length > 0;
    setIsProtocolModalOpen(false);
    setSelectedProtocolType(hasPreviousReport ? patient.mainScheduledProtocol.type : null);
    setValue('protocol', hasPreviousReport ? patient.scheduledProtocols[0] : null);
  };

  const handlePhoneInUseError = usePostPhoneInUseError();
  const handleEmailInUseError = usePostEmailInUseError();
  const handleInvalidError = (error: Error) =>
    uiStore.postError(error, `This phone number is invalid, try using another one`);

  const handleProtocolOptionChanged = (name: ProtocolName, type: ProtocolType) => {
    const selectedProtocolLabel = ScheduledProtocol.getProtocolDisplayName(type);

    trackInputUsageAnalyticsEvent({
      action: AnalyticEventAction.Update,
      value: 'Report Protocol',
      selected_value: selectedProtocolLabel || 'Choose Protocol Later',
      type: 'dropdown'
    });

    const isSwitchingType = protocol && protocol.type !== type && protocol.name !== name;
    setIsProtocolModalOpen(!isSwitchingType);
    setSelectedProtocolType(type);
    setIsProtocolWarningOpen(isSwitchingType);
  };

  const handleOptOutClick = () => {
    if (!optOutInfo) {
      trackOptOutRemoteMonitoringModalAnalyticsEvent({ action: AnalyticEventAction.Open });
      setIsOptOutModalOpen(!isOptOutModalOpen);
    } else {
      trackOptOutRemoteMonitoringAnalyticsEvent({ action: AnalyticEventAction.OptIn });
      setOptOutInfo(null);
    }
  };

  const handleSaveClicked = async (patientFormData: IEditPatientInfoForm) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const hasDefaultOptOut = Boolean(props.patient.optOut); //the opt out checkbox value after opening the modal
    const hasCurrentOptOut = Boolean(optOutInfo);
    const isOptOutCheckboxDirty = hasCurrentOptOut !== hasDefaultOptOut;

    const isFormDirty = isDirty || isOptOutCheckboxDirty; //we need this extra check because optOutInfo is not part of rhf

    trackEditPatientModalAnalyticsEvent({
      action: AnalyticEventAction.Save,
      value: isFormDirty ? 'save with changes' : 'save without changes'
    });

    const updateFields = {
      firstName: patientFormData.firstName.trim(),
      lastName: patientFormData.lastName.trim(),
      dateOfBirth: patientFormData.dateOfBirth,
      sex: patientFormData.sex?.value,
      phone: patientFormData.countryCode + patientFormData.phone,
      phoneType: patientFormData.phoneType.value,
      remoteMonitoringConsent: patient.remoteMonitoringConsent,
      copayConsentGiven: patient.copayConsentGiven,
      patientReadTerms: patient.patientReadTerms,
      financialAssistance: patient.financialAssistance,
      protocol: patientFormData.protocol,
      // mrn is disabled for edit no need to get it from form data
      // (react hook form wont initialize the value if field is disabled)
      mrn: patient.mrn,
      location: patientFormData.location?.value,
      providerId: patientFormData.provider?.value.id,
      locale: patientFormData.language.value,
      email: patientFormData.email,
      optOut: optOutInfo,
      tags: patientFormData.tags?.map((tag) => tag.value) || []
    };

    try {
      await patientPageStore.updatePatient(patient.id, updateFields);
      props.onEditSuccessful();
      reset(patientFormData);

      if (patientPageStore.patient) {
        setPatient(patientPageStore.patient.copyWith());
      }

      patientPageStore.updateRecentSearchedPatients(
        patient.id,
        patientFormData.firstName.trim(),
        patientFormData.lastName.trim(),
        patientFormData.dateOfBirth
      );
    } catch (error) {
      if (error.name === ErrorName.PhoneInUse) {
        return handlePhoneInUseError(error);
      }
      if (error.name === ErrorName.EmailInUse) {
        return handleEmailInUseError(error);
      }

      if (error.name === ErrorName.InvalidPhone) {
        return handleInvalidError(error);
      }
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelClicked = () => {
    setSelectedProtocolType(
      props.patient.scheduledProtocols && props.patient.mainScheduledProtocol
        ? props.patient.mainScheduledProtocol.type
        : null
    );
    setIsProtocolWarningOpen(false);
    setIsProtocolModalOpen(false);
    setIsOptOutModalOpen(false);
    setOptOutInfo(props.patient.optOut);
    props.onCancelClicked();
  };

  const openPatientContactModal = (contactId?: number) => {
    setIsPatientContactModalOpen(true);

    if (!contactId) {
      setContactToEdit({
        id: null,
        name: null,
        phoneNumber: null,
        email: null,
        relationship: null
      });
      return;
    }

    const selectedContact = patient.contacts.find((contact) => contact.id === contactId);
    setContactToEdit(selectedContact);
  };

  const removeContact = async () => {
    await patientPageStore.deleteContact(patient.id, contactToRemove);
    setIsPatientContactModalOpen(false);
    setContactToRemove(null);
    setContactToEdit(null);
  };

  const [firstName] = getValues(['firstName']);

  const isReportProtocolDropdownDisabled =
    !ValidationUtils.isValidPhoneNumber(formatInternationalNumber(countryCode, phone)) ||
    !patient.isRemoteMonitorConsented ||
    !!optOutInfo ||
    !settingsStore.hasFeature(FEATURES.REPORT_PROTOCOL);

  const { isDirty } = formState;
  return (
    <div>
      <MessageDialog
        id={Dialogs.DeleteAndSwitchProtocol}
        isOpen={isProtocolWarningOpen}
        title="Delete & Switch Protocols?"
        handleClose={() => setIsProtocolWarningOpen(false)}
        primaryActionProps={{
          text: 'Switch Protocol',
          onClick: () => {
            setIsProtocolModalOpen(true);
            setIsProtocolWarningOpen(false);
            setValue('protocol', null);
          }
        }}
        secondaryActionProps={{ text: 'Keep Protocol', onClick: onProtocolWarningChangeCancel }}
        testHook={sharedTestSelectors.switchProtocolPopup}
      >
        {protocol
          ? `${firstName} will no longer recieve the protocol "${ScheduledProtocol.getProtocolDisplayName(
              protocol.type
            )}", if you switch to the new
        protocol "${ScheduledProtocol.getProtocolDisplayName(selectedProtocolType)}".`
          : null}
      </MessageDialog>

      <MessageDialog
        id={Dialogs.RemoveCallbackContact}
        isOpen={contactToRemove !== null}
        handleClose={() => setContactToRemove(null)}
        title="Remove this callback contact?"
        primaryActionProps={{ text: 'Remove', onClick: removeContact }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setContactToRemove(null) }}
      >
        {contactToRemove ? `"${contactToRemove.name}"` : ''}
      </MessageDialog>

      <PatientOptOutModal isOpen={isOptOutModalOpen} onClose={handleOptOutClosed} />

      {isProtocolModalOpen && (
        <ScheduledProtocolModal
          onScheduledProtocolSubmitted={handleProtocolSubmitted}
          onScheduledProtocolCanceled={handleProtocolCanceled}
          isOpen={isProtocolModalOpen}
          protocol={protocol}
          type={selectedProtocolType}
        />
      )}

      <PatientContactModalForm
        patient={patient}
        contact={contactToEdit}
        isOpen={isPatientContactModalOpen}
        onDelete={() => setContactToRemove(contactToEdit)}
        onCancel={() => {
          setContactToEdit(null);
          setIsPatientContactModalOpen(false);
        }}
        onSuccess={() => {
          setContactToEdit(null);
          setIsPatientContactModalOpen(false);
        }}
      />

      <FormModal
        testHook={editPatientModalTestSelectors.container}
        isInternalModalOpen={isOptOutModalOpen || isProtocolModalOpen || isPatientContactModalOpen}
        defaultValues={defaultValues}
        methods={methods}
        isOpen={props.isOpen}
        resetFieldsTriggers={[history?.location]}
        title={props.patient.fullName}
        confirmActions={[
          {
            onClick: handleSubmit(handleSaveClicked, (e) =>
              trackEditPatientModalAnalyticsEvent({
                action: AnalyticEventAction.Save,
                value: 'missing fields'
              })
            ),
            text: isSaving ? 'Saving...' : 'Save',
            disabled: isSaving,
            testHook: editPatientModalTestSelectors.submitButton
          }
        ]}
        closeAction={{ onClick: handleCancelClicked, disabled: false }}
        secondaryAction={{
          type: 'controlled-labeled-checkbox',
          label: 'Opt Out of Remote Monitoring',
          id: 'optOutCheckbox',
          name: 'optOutCheckbox',
          onChange: handleOptOutClick,
          checked: !!optOutInfo
        }}
      >
        <div className="edit-patient-info-modal">
          <EditInfoInputs
            isExistingPatient
            isOptOutFlow={Boolean(optOutInfo)}
            isUninvitedFlow={!patient.isRemoteMonitorConsented}
            disableInputs={false}
            lastInput={
              <EditPatientsContacts
                setContactToEdit={setContactToEdit}
                openPatientContactModal={openPatientContactModal}
              />
            }
          />

          <Row className="mb-3">
            <Col xs={12} sm={6} lg={6}>
              <StyledPhoneInput
                disabled={false}
                error={Boolean(formState.errors.phone || formState.errors.countryCode)}
                label="Phone Number"
                onBlur={(event, valueAfterFocus, currentValue) => {
                  trackOpenTextInputUsageAnalyticsEvent(
                    currentValue,
                    valueAfterFocus,
                    'Phone Number'
                  );
                }}
              />
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <FormAutocomplete
                label="Phone Type"
                isRequired
                options={phoneTypeOptions}
                name="phoneType"
                onChange={(value, actionMeta, eventKey) =>
                  trackDropdownInputUsageAnalyticsEvent(
                    actionMeta,
                    'Phone Type',
                    eventKey === 'Enter'
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} sm={6} lg={6}>
              <PatientProviderAutocomplete
                isRequired={
                  patient.isRemoteMonitorConsented &&
                  settingsStore.institutionSettings.providerFieldEditable
                }
                trackAnalyticsEvent={trackDropdownInputUsageAnalyticsEvent}
              />
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <PatientLocationAutocomplete
                isRequired={patient.isRemoteMonitorConsented}
                trackAnalyticsEvent={trackDropdownInputUsageAnalyticsEvent}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} sm={6} lg={6}>
              <FormMultiAutocomplete
                options={constantsStore.activeTagsForSelect}
                name="tags"
                label="Tags (Optional)"
                sortAlphabetically={false}
                onChange={(value, actionMeta, eventKey) =>
                  trackMultiSelectionInputUsageAnalyticsEvent(
                    actionMeta,
                    'Tags',
                    eventKey === 'Enter'
                  )
                }
              />
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <ReportProtocolInput
                onProtocolChanged={handleProtocolOptionChanged}
                disabled={isReportProtocolDropdownDisabled}
                error={Boolean(formState.errors.protocol)}
                value={protocol}
                phoneNumType={phoneType?.value}
                isInvite={false}
                isOptOutFlow={false}
                openUp
              />
            </Col>
          </Row>
        </div>
      </FormModal>
    </div>
  );
};

export default observer(EditPatientInfoModal);
