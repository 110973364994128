import { FC, useState } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { useStores } from 'mobx/hooks/useStores';

import { FeatureIntroCodes } from 'models/Doctor';

import { ResolveTicketsData, useTicketResolve } from 'hooks/useTicketResolve';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
  resolveTicketsData: ResolveTicketsData;
}

export const ResolveTicketWithMessageForTheFirstTimeDialog: FC<Props> = ({
  isOpen,
  closeDialog,
  resolveTicketsData
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const ticketResolve = useTicketResolve();
  const { userStore } = useStores();

  async function resolve() {
    try {
      setIsLoading(true);
      await userStore.updateIntroFinished(FeatureIntroCodes.ResolveTicketWithMessage);
      closeDialog();
      await ticketResolve(resolveTicketsData);
    } finally {
      closeDialog();
      setIsLoading(false);
    }
  }

  return (
    <MessageDialog
      id={Dialogs.ResolveTicketWithMessage}
      isOpen={isOpen}
      handleClose={closeDialog}
      title="Patient Cannot Reply After Ticket Resolve"
      primaryActionProps={{ text: 'Resolve', onClick: resolve, disabled: isLoading }}
      secondaryActionProps={{ text: 'Cancel', onClick: closeDialog }}
    >
      Once a ticket is resolved, the patient receives a message that the issue has been addressed,
      and will no longer be able to reply.
    </MessageDialog>
  );
};
