import React, { FC } from 'react';

import { Dialogs } from 'analytics/events/dialog';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';

interface Props {
  isOpen: boolean;
  onCancelClicked: () => void;
  names: string[];
}

const MultipleSmsBlockedPopup: FC<Props> = ({ isOpen, onCancelClicked, names }) => (
  <MessageDialog
    id={Dialogs.MultipleUnsubscribeSms}
    title="Contact(s) Blocked SMS from Canopy"
    isOpen={isOpen}
    handleClose={onCancelClicked}
    primaryActionProps={{ text: 'OK', onClick: onCancelClicked }}
  >
    This occurs when somebody has replied STOP to an SMS message from Canopy in the past.
    <br />
    <br />
    To un-block, the following contacts should text START to the same number they previously
    blocked:
    <br />
    {names.map((name) => (
      <div key={name}>&bull; {name}</div>
    ))}
  </MessageDialog>
);

export default MultipleSmsBlockedPopup;
