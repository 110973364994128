// @ts-strict-ignore
import { FC, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useStores } from 'mobx/hooks/useStores';

import { CM_QUICK_ADDITION } from 'constants/reasons.const';

import { QuickAddition } from 'models/QuickAddition';

import NoFooterModal from 'views/Modals/BaseModal/NoFooterModal';
import { SaveCancelFooter } from 'views/Widgets/SaveCancelFooter';
import { RHFStyledInput } from 'views/Widgets/StyledInput';

import Icon from 'components/Icons/Icon';
import { TextIconButton } from 'components/UIkit/atoms/Button';
import { LabeledCheckbox } from 'components/UIkit/atoms/Checkbox';

import { useIntervalContext } from './IntervalsContext';

import {
  EntriesEditorHeaderCell,
  EntriesEditorHeaderRow,
  EntriesEditorWrapper,
  refreshCurrent
} from './IntervalsEditor.shared';
import { QuickAdditionsEditorRow } from './QuickAdditionsEditorRow';

import './IntervalsEditor.scss';
import './QuickAdditionsEditor.scss';

export const QuickAdditionsEditor: FC = () => {
  const { reasonsStore } = useStores();
  const { quickAdditionsCtx, quickAdditionIdsWithoutReason, setQuickAdditionIdsWithoutReasons } =
    useIntervalContext();
  const [saveNewReasonChecked, setSaveNewReasonChecked] = useState(true);
  const [newReasonQuickAddition, setNewReasonQuickAddition] = useState(null);
  const [newCurrentQuickAdditionId, setNewCurrentQuickAdditionId] = useState<string>(null);
  const methods = useForm<{ reasonName: string }>({
    defaultValues: {
      reasonName: ''
    }
  });

  const addQuickAddition = () => {
    const newQuickAddition = QuickAddition.createEmpty();
    quickAdditionsCtx.setCurrent([...quickAdditionsCtx.current, newQuickAddition]);
    setNewCurrentQuickAdditionId(newQuickAddition.uniqueIdentifier);
  };

  const handleReasonChanged = (newReasonId: number, quickAddition: QuickAddition) => {
    if (quickAdditionIdsWithoutReason.includes(quickAddition.uniqueIdentifier)) {
      const filteredQuickAdditionsWithoutReasons = quickAdditionIdsWithoutReason.filter(
        (quickAdditionId) => quickAdditionId !== quickAddition.uniqueIdentifier
      );
      setQuickAdditionIdsWithoutReasons(filteredQuickAdditionsWithoutReasons);
    }

    if (newReasonId === null) {
      setNewReasonQuickAddition(quickAddition);
      return;
    }

    const newReason = reasonsStore.getReasonByIdTypeAndType(newReasonId, CM_QUICK_ADDITION);
    if (newReason) {
      quickAddition.reason = newReason;
      refreshCurrent(quickAdditionsCtx);
    }
  };

  const handleNewReasonSaved = async (data: { reasonName: string }) => {
    const reasonId = await reasonsStore.createReason(
      CM_QUICK_ADDITION,
      data.reasonName,
      saveNewReasonChecked
    );
    newReasonQuickAddition.reason = {
      id: reasonId,
      isActive: saveNewReasonChecked,
      isDeleted: false,
      isEditable: saveNewReasonChecked,
      title: data.reasonName
    };
    refreshCurrent(quickAdditionsCtx);
    setNewReasonQuickAddition(null);
    methods.reset();
  };

  return (
    <EntriesEditorWrapper>
      <EntriesEditorHeaderRow>
        <EntriesEditorHeaderCell colSize={3}>
          <b>Addition</b>
        </EntriesEditorHeaderCell>
        <EntriesEditorHeaderCell colSize={3}>
          <b>Logged</b>
        </EntriesEditorHeaderCell>
        <EntriesEditorHeaderCell colSize={5}>
          <b>Reason</b>
        </EntriesEditorHeaderCell>
      </EntriesEditorHeaderRow>
      <NoFooterModal title="Add Quick Addition Reason" isOpen={Boolean(newReasonQuickAddition)}>
        <div className="my-3">
          <RHFStyledInput
            name="reasonName"
            register={methods.register}
            disabled={false}
            isRequired
            maxLength={100}
            error={Boolean(methods.formState.errors.reasonName)}
            rounded
          />
        </div>
        <SaveCancelFooter
          onCancel={() => setNewReasonQuickAddition(null)}
          onSave={methods.handleSubmit(handleNewReasonSaved)}
        >
          <LabeledCheckbox
            label="Save this reason for future use"
            id="save-reason"
            onChange={() => setSaveNewReasonChecked(!saveNewReasonChecked)}
            checked={saveNewReasonChecked}
          />
        </SaveCancelFooter>
      </NoFooterModal>
      {quickAdditionsCtx.current.map((quickAddition: QuickAddition) => (
        <QuickAdditionsEditorRow
          key={quickAddition.uniqueIdentifier}
          quickAddition={quickAddition}
          isNew={quickAddition.uniqueIdentifier === newCurrentQuickAdditionId}
          handleReasonChanged={handleReasonChanged}
        />
      ))}

      <TextIconButton
        onClick={addQuickAddition}
        disabled={!quickAdditionsCtx.isValid}
        icon={<Icon.CirclePlus />}
        mt={24}
      >
        Add Quick Addition
      </TextIconButton>
    </EntriesEditorWrapper>
  );
};
