import moment from 'moment/moment';

import { SearchedPatient } from 'fetchers/PatientsFetcher';

import { isAllDigits, isValidLocalePhoneNumber } from 'utils/ValidationUtils';

import {
  NEW_PATIENT_ID,
  SUPPORTED_DOB_DATE_QUERY_FORMATS
} from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.constants';
import { SearchBy } from 'components/UIkit/atoms/AdvancedSearchBar/AdvancedSearchBar.types';

export const getSearchBy = (searchTerm: string): SearchBy => {
  const trimmedSearchTerm = searchTerm?.trim();

  if (!Boolean(trimmedSearchTerm)) {
    return 'EMPTY';
  }

  if (isValidLocalePhoneNumber(trimmedSearchTerm)) {
    return 'PHONE';
  }

  if (isAllDigits(trimmedSearchTerm)) {
    return 'MRN/PHONE';
  }

  const date = moment(trimmedSearchTerm, SUPPORTED_DOB_DATE_QUERY_FORMATS, true);

  if (date.isValid()) {
    return 'DOB';
  }

  return 'NAME';
};

export const getNewEmptyPatient = (firstName?: string, lastName?: string): SearchedPatient => ({
  firstName: firstName || '',
  lastName: lastName || '',
  dob: '',
  mrn: '',
  patientId: NEW_PATIENT_ID,
  emrPatientId: '',
  userId: NEW_PATIENT_ID,
  phone: ''
});
