import { FC, useState } from 'react';

import { styled } from '@mui/material';

import { Box } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { trackTaskStatusMenuAnalyticsEvent } from 'analytics/events/task-status-menu';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import Ticket, { TicketStatus, ticketStatusToString } from 'models/Ticket';

import { useTicketResolve } from 'hooks/useTicketResolve';

import Icon from 'components/Icons/Icon';
import { useTicketActionCallbacks } from 'components/Ticket/TicketsContainers/useTicketActionCallbacks';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { DividerPlacement, ITooltipOption, Tooltip, TooltipSelect } from 'components/Tooltip';
import { Text } from 'components/UIkit/atoms/Text';

interface Props {
  ticket: Ticket;
  ticketIndex: number;
  ticketSectionCurrentPage: number;
}

/*
  we have 3 cases:
    1. The task is not resolved, not closed and assigned to another doctor - we display the status with a dot after it
    2. The task is resolved or closed - we display the status
    3. The first 2 cases are false - we display the tooltip select
 */

export const TaskStatus: FC<Props> = observer(
  ({ ticket, ticketIndex, ticketSectionCurrentPage = 0 }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { userStore, tasksStore } = useStores();
    const { onStatusChange } = useTicketActionCallbacks();
    const ticketResolve = useTicketResolve();
    const { openResolveAndCommentModal } = useTicketOverviewContext();

    const isAssignedToOtherDoctor =
      ticket.mainAssign && userStore.currentClinicianId !== ticket.mainAssign.doctor.id;

    //dot should be displayed only if doctor name is to be displayed (as part of TicketReassignAction)
    const showDot = isAssignedToOtherDoctor && !ticket.isClosedOrResolved;

    const switchTaskStatus = async (newStatus: TicketStatus) => {
      setIsMenuOpen(false);
      await tasksStore.bulkStatusChange([ticket], newStatus);
      onStatusChange && onStatusChange(ticket);
    };

    const resolve = async () => {
      setIsMenuOpen(false);
      await ticketResolve({ ticketIds: [ticket.id] });
    };

    const resolveAndComment = () => {
      setIsMenuOpen(false);
      openResolveAndCommentModal([ticket.id]);
    };

    const onTaskStatusDropdownClick = () => {
      trackTaskStatusMenuAnalyticsEvent({
        action: AnalyticEventAction.Click,
        ticket_id: ticket.id,
        item_index: ticketIndex + 1,
        page_number: ticketSectionCurrentPage + 1
      });
      setIsMenuOpen((prevState) => !prevState);
    };

    const options: ITooltipOption[] = [
      {
        text: ticketStatusToString[TicketStatus.OPEN],
        onClick: () => {
          trackTaskStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            ticket_id: ticket.id,
            value: 'To Do',
            item_index: ticketIndex + 1,
            page_number: ticketSectionCurrentPage + 1
          });
          switchTaskStatus(TicketStatus.OPEN);
        },
        isSelected: ticket.status === TicketStatus.OPEN
      },
      {
        text: ticketStatusToString[TicketStatus.IN_PROGRESS],
        onClick: () => {
          trackTaskStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            ticket_id: ticket.id,
            value: 'In Progress',
            item_index: ticketIndex + 1,
            page_number: ticketSectionCurrentPage + 1
          });
          switchTaskStatus(TicketStatus.IN_PROGRESS);
        },
        isSelected: ticket.status === TicketStatus.IN_PROGRESS,
        withDivider: true,
        dividerPlacement: DividerPlacement.Bottom
      },
      {
        text: ticketStatusToString[TicketStatus.CLOSED],
        onClick: () => {
          trackTaskStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            ticket_id: ticket.id,
            value: 'Closed',
            item_index: ticketIndex + 1,
            page_number: ticketSectionCurrentPage + 1
          });
          switchTaskStatus(TicketStatus.CLOSED);
        }
      },
      {
        text: ticketStatusToString[TicketStatus.RESOLVED],
        onClick: () => {
          trackTaskStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            ticket_id: ticket.id,
            value: 'Resolved',
            item_index: ticketIndex + 1,
            page_number: ticketSectionCurrentPage + 1
          });
          resolve();
        }
      },
      {
        text: 'Resolve & Comment',
        onClick: () => {
          trackTaskStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Select,
            ticket_id: ticket.id,
            value: 'Resolve and Comment',
            item_index: ticketIndex + 1,
            page_number: ticketSectionCurrentPage + 1
          });
          resolveAndComment();
        }
      }
    ];

    if (showDot) {
      return (
        <Text variant="body1" mr={4}>
          {ticket.parsedStatus} •
        </Text>
      );
    }

    if (ticket.isClosedOrResolved) {
      return (
        <Text variant="body1" mr={20}>
          {ticket.parsedStatus}
        </Text>
      );
    }

    return (
      <Tooltip
        label={
          <StyledContainer onClick={onTaskStatusDropdownClick} mr={20}>
            <Text mr={8}>{ticket.parsedStatus}</Text>
            <Icon.Chevron up={isMenuOpen} />
          </StyledContainer>
        }
        controller={{
          visible: isMenuOpen,
          onClickOutside: () => setIsMenuOpen(false)
        }}
      >
        <TooltipSelect options={options} />
      </Tooltip>
    );
  }
);

const StyledContainer = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
